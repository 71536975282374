import { mountPoint } from 'utils/generic/route-manipulation';

export const SPORTSBOOK_VIEW_TYPES: Record<number, string> = {
  0: 'live',
  1: 'prematch',
  2: 'esport'
};

export const IS_SEPARATED_WIDGETS =
  !!window.partnerConfigs?.pageParams &&
  window.partnerConfigs.pageParams.pageType !== 'pageBlank';

export const IS_SEPARATED_ESPORT_WIDGETS =
  window.partnerConfigs?.pageParams?.pageType === 'pageESport';

export const SEPARATED_WIDGETS_ROUTE = IS_SEPARATED_WIDGETS
  ? `${mountPoint(
      decodeURI(window.location.pathname)
    )}/:sport?/:region?/:competition?/:game?`
  : undefined;

export const GAME_TYPE_LIVE = 1;

export const DESKTOP_RIGHT_WRAPPER_Z_INDEX = 14;

export const SPORTS_TYPES_LIVE_PREMATCH = {
  E_SPORT: 0,
  GENERAL: 2,
  UFC: 5
};
