import { CommandNames } from 'utils/constants/swarm/swarm-command-names';
import RidGenerator from 'utils/swarm/rid-generator';
import { WebsocketEnhanced } from 'interfaces/spring-websocket-interfaces';
import { connectingWS } from 'hooks/useSpringConnect';

export const getBoostedGames = (callback: Function): void => {
  const command = {
    command: CommandNames.GET_BOOSTED_SELECTIONS,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.sendCommand(command, '', callback);
  });
};
