import {
  cloneElement,
  CSSProperties,
  FC,
  isValidElement,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react';
import cc from 'classcat';
import { UtilsDOM } from 'utils/bom-dom-manipulation';
import { ConstantsIcons } from 'utils/constants/icons';
import { createTestId } from 'utils/helpers/create-test-id';
import './index.less';

export interface ISvgInlineProps {
  url: string;
  customUrl?: string;
  fallbackUrl?: string; // in case the main url fails. If not provided, the component will show an error state
  fallbackElement?: Element | JSX.Element; // in case the main url fails. This has priority over fallbackUrl
  onError?: () => void;
  onLoad?: () => void;
  loadingElement?: ReactNode;
  className?: string;
  svgStyle?: CSSProperties;
  attributes?: Record<string, string>;
}

const { ALREADY_LOADED_ICON_URLS } = ConstantsIcons;

export const SvgInline: FC<ISvgInlineProps> = ({
  url,
  fallbackUrl = '',
  fallbackElement: _fallbackElement,
  onError,
  onLoad,
  loadingElement,
  svgStyle,
  attributes,
  className,
  ...rest
}) => {
  const [svgContent, setSvgContent] = useState<SVGElement | null>(
    ALREADY_LOADED_ICON_URLS.get(url) ||
      ALREADY_LOADED_ICON_URLS.get(fallbackUrl) ||
      null
  );

  const [fallbackElement, setFallbackElement] = useState<
    Element | JSX.Element | null
  >(null);

  const [isLoaded, setIsLoaded] = useState(
    ALREADY_LOADED_ICON_URLS.has(url) ||
      ALREADY_LOADED_ICON_URLS.has(fallbackUrl) ||
      false
  );

  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    const iconFetcher = async (_url: string, secondAttempt?: boolean) => {
      if (fallbackUrl.includes('undefined')) {
        return;
      }

      try {
        const alreadyLoadedIcon = ALREADY_LOADED_ICON_URLS.get(_url);

        if (alreadyLoadedIcon) {
          setSvgContent(alreadyLoadedIcon);
          setIsLoaded(true);
          onLoad?.();

          return;
        }

        const res = await fetch(_url);
        const svgString = await res.text();
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');

        if (svgElement) {
          setSvgContent(svgElement);
          setIsLoaded(true);
          ALREADY_LOADED_ICON_URLS.set(_url, svgElement);
          onLoad?.();
        } else {
          throw new Error('Invalid SVG');
        }
      } catch {
        onError?.();

        if (secondAttempt) {
          setIsErrored(true);
        }

        if (_fallbackElement) {
          setFallbackElement(_fallbackElement);

          return;
        }

        if (
          !secondAttempt &&
          fallbackUrl &&
          !fallbackUrl.includes('undefined')
        ) {
          await iconFetcher(fallbackUrl, true);
        }
      }
    };

    void iconFetcher(url);
  }, [url, fallbackUrl]);

  const memoizedFallbackElement = useMemo(() => {
    if (isValidElement(fallbackElement)) {
      return cloneElement(fallbackElement, { ...attributes, ...rest });
    }

    return fallbackElement;
  }, [rest, attributes, fallbackElement]);

  return (
    <>
      {svgContent ? (
        <svg
          {...UtilsDOM.createAttributesObjectFromSource(svgContent)}
          {...attributes}
          {...rest}
          data-src={isErrored ? fallbackUrl : url}
          className={cc(['x-svgInline', className || ''])}
          style={svgStyle}
          dangerouslySetInnerHTML={{ __html: svgContent.innerHTML }}
          {...createTestId('x-svgInline')}
        />
      ) : null}
      {!isLoaded && !isErrored && memoizedFallbackElement}
      {!isLoaded && !isErrored && !memoizedFallbackElement && loadingElement}
    </>
  );
};
