// FIXME wrong types specified here
import { TSortingTypes } from 'interfaces/generic';

type ArrType = Array<{ [key: string]: any; order?: number }>;

export const sortByOrder = <T extends ArrType>(
  arr: T,
  key?: string,
  type?: TSortingTypes
): T => {
  const k = key || 'order';
  const t = type || 'asc';

  return arr.sort((a, b) => {
    if ((a?.[k] ?? 0) === (b?.[k] ?? 0)) {
      return 0;
    }

    if (t === 'desc') {
      return (a?.[k] ?? 0) < (b?.[k] ?? 1) ? 1 : -1;
    } else {
      return (a?.[k] ?? 0) > (b?.[k] ?? 1) ? 1 : -1;
    }
  });
};

export const sortMarketsByMainOrder = <T extends ArrType>(arr: T): T => {
  return arr.sort(
    (a, b) =>
      (a.main_order || Number.POSITIVE_INFINITY) -
      (b.main_order || Number.POSITIVE_INFINITY)
  );
};
