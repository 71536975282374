import { ReactElement } from 'react';
import { Col, Row } from 'antd';
import { EIconSize } from 'utils/constants/app/ui';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { Skeleton } from 'newcomponents/UI/Skeleton';

type Props = {
  expanded?: boolean;
  regionIconSize?: EIconSize;
};

export const RegionViewSkeleton = ({
  expanded,
  regionIconSize = EIconSize._24
}: Props): ReactElement => {
  return (
    <div
      className="regionViewSkeleton"
      style={{ background: 'var(--v3-black-0)' }}
    >
      <Row align="middle">
        <Col>
          <Skeleton.Avatar size={regionIconSize} active style={{ margin: 8 }} />
        </Col>
        <Col>
          <Skeleton
            title={{ style: { margin: 'unset', height: 16, width: 150 } }}
            active
            paragraph={false}
          />
        </Col>
      </Row>
      {expanded &&
        Array.from({ length: 7 }, (v, i) => i).map(key => (
          <Row key={key}>
            <Col>
              <Skeleton
                title={{
                  style: {
                    margin: SpringConfigs.IS_RTL
                      ? '14px 14px 14px 40px'
                      : '14px 0 14px 40px',
                    height: 12,
                    width: 150
                  }
                }}
                active
                paragraph={false}
              />
            </Col>
          </Row>
        ))}
    </div>
  );
};
