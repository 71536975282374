import {
  CSSProperties,
  memo,
  ReactElement,
  SyntheticEvent,
  useMemo,
  useState
} from 'react';
import cc from 'classcat';
import dayjs from 'dayjs';
import { EIconSize } from 'utils/constants/app/ui';
import { ConstantsIcons } from 'utils/constants/icons';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { createTestId } from 'utils/helpers/create-test-id';
import { UtilsIcons } from 'utils/icons';
import { isMobile } from 'utils/is-mobile';
import { IconLibName } from 'interfaces/generic';
import { SvgInline } from 'newcomponents/Shared/generic/SvgInline';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import './index.less';

export interface IGlobalIconProps {
  children?: ReactElement | null;
  lib?: IconLibName | string;
  name?: string;
  theme?: string;
  size?: number;
  style?: CSSProperties;
  className?: string;
  emWidth?: number | 'auto';
  emHeight?: number | string;
  color?: string;
  position?: 'relative' | 'absolute';
  url?: string | undefined;
  skeleton?: {
    detachPassedClassName?: boolean;
    shape?: 'square' | 'circle';
    doNotDraw?: boolean;
    useEmptyPlaceholder?: boolean;
  };
  prefix?: {
    showAfterIconIsRendered?: boolean;
  };
  isBlank?: boolean;
  alt?: Element | JSX.Element;
  useWrapper?: boolean;
  useStyleOnWrapper?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  onMouseEnter?: (e: SyntheticEvent) => void;
  // this setting has created for selected sport icons to not listen sporing configs default configs
  ignoreGlobalConfigs?: boolean;
}

const iconStyle = SpringConfigs.SPORTSBOOK_ICON_STYLE;
const flagStyle = SpringConfigs.FLAG_STYLE;
const customIcons = SpringConfigs.CUSTOM_ICONS;
const customIconsURL = SpringConfigs.CUSTOM_ICONS_URL;

const constructIconPath = UtilsIcons.createUrl;
const constructCustomIconPath = UtilsIcons.createCustomUrl;

const checkIconShouldHavePrefix = (name?: string) => {
  return name?.toLowerCase() === 'todayevents';
};

const normalizeIconName = (name?: string): [string | undefined, boolean] => {
  if (!name) {
    return [name, false];
  }

  if (ConstantsIcons.MAPPINGS.names.has(name)) {
    return [ConstantsIcons.MAPPINGS.names.get(name), true];
  }

  return [name, false];
};

export const GlobalIcon = memo(
  ({
    lib = 'sports',
    theme,
    name: _name,
    size = EIconSize._24,
    emWidth = 1,
    emHeight = 1,
    color,
    position = 'relative',
    style: _style,
    children,
    url,
    className,
    alt,
    isBlank: _isBlank,
    skeleton,
    prefix,
    useWrapper,
    useStyleOnWrapper,
    ignoreGlobalConfigs,
    ...rest
  }: IGlobalIconProps) => {
    const [name, hasBeenNormalized] = normalizeIconName(_name);
    const [iconLoaded, setIconLoaded] = useState(false);
    const isBlank = _isBlank || (!name && !url);
    const iconShouldHavePrefix = checkIconShouldHavePrefix(name);
    const shouldRenderPrefix =
      prefix?.showAfterIconIsRendered && iconLoaded && iconShouldHavePrefix;

    // In case of there is a click event, we should change the cursor to pointer
    const style = {
      ..._style,
      ...(rest.onClick && { cursor: 'pointer' })
    };

    const shouldHaveWrapper =
      !!children || iconShouldHavePrefix || hasBeenNormalized || useWrapper;

    const iconTheme = useMemo(() => {
      switch (lib) {
        case 'sports':
          return (ignoreGlobalConfigs ? theme : iconStyle) ?? iconStyle;
        case 'flags':
          return (ignoreGlobalConfigs ? theme : flagStyle) ?? flagStyle;
        case 'casino':
          return theme ? theme : 'colored';
        case 'generic':
          return theme ? theme : 'colored';
        case 'account':
          return theme ? theme : 'default';

        default:
          return theme ? theme : 'default';
      }
    }, [lib, theme]);

    const iconPath = useMemo(() => {
      return customIcons && (lib === 'sports' || lib === 'casino')
        ? constructCustomIconPath({
            lib,
            name,
            customIconsURL
          })
        : constructIconPath({ lib, theme: iconTheme, name });
    }, [lib, iconTheme, name]);

    const fallbackIconPath = useMemo(() => {
      const iconPathData = {
        lib: lib,
        theme: 'colored',
        name: 'generic'
      };

      switch (lib) {
        case 'sports':
          iconPathData.theme = iconTheme;
          iconPathData.name = 'generic';
          break;
        case 'flags':
          iconPathData.theme = iconTheme;
          iconPathData.name = 'generic';
          break;
        case 'casino':
          iconPathData.theme = 'colored';
          iconPathData.name = 'generic';
          break;
        case 'generic':
        case 'account':
          iconPathData.theme = iconTheme;
          iconPathData.lib = 'generic';
          iconPathData.name = 'generic';
          break;
        default:
          iconPathData.lib = 'generic';
          iconPathData.theme = 'colored';
          iconPathData.name = 'generic';
          break;
      }

      return constructIconPath(iconPathData);
    }, [lib, iconTheme]);

    const skeletonStyle = useMemo(() => {
      if (skeleton?.shape) {
        return skeleton.shape;
      }

      switch (lib) {
        case 'flags':
          return flagStyle === 'default' ? 'square' : 'circle';
        case 'providers':
          return 'square';
        default:
          return 'circle';
      }
    }, [lib, skeleton]);

    if (isBlank) {
      return (
        <span
          {...createTestId('x-icon-blank')}
          className={cc(['v3-icon', className || ''])}
          style={{
            height: `${size}px`,
            width: `${size}px`,
            display: 'inline-block',
            position: position
          }}
        />
      );
    }

    if (url) {
      return (
        <span className={cc(['v3-icon', className || ''])} style={style}>
          <img
            src={url}
            width={`${size}px`}
            height={`${size}px`}
            alt="global-icon"
            loading="lazy"
            style={{ fontSize: size, color, position }}
            {...rest}
            {...createTestId('x-icon')}
          />
          {children}
        </span>
      );
    }

    if (shouldHaveWrapper) {
      return (
        <span
          {...createTestId('x-icon-wrapper')}
          style={{
            ...(useStyleOnWrapper && style),
            fontSize: size,
            ...(!!color && { color }),
            position
          }}
          {...rest}
          className={cc(['v3-icon', className || ''])}
        >
          {shouldRenderPrefix && (
            <span
              className={`current-date ${
                !isMobile() ? 'current-date-desktop' : 'current-date-mobile'
              } ${iconStyle === 'colored' ? 'text-color-fixed' : 'text-color'}`}
            >
              {dayjs().format('DD')}
            </span>
          )}
          <SvgInline
            key={iconPath || fallbackIconPath}
            url={iconPath}
            fallbackUrl={fallbackIconPath}
            fallbackElement={alt}
            attributes={{
              width: typeof emWidth == 'number' ? `${emWidth}em` : '100%',
              height: typeof emHeight == 'number' ? `${emHeight}em` : '100%',
              fill: 'currentColor',
              ...createTestId('x-icon')
            }}
            {...(!!prefix?.showAfterIconIsRendered && {
              onLoad: () => setIconLoaded(true)
            })}
            svgStyle={!useStyleOnWrapper ? style : undefined}
            loadingElement={
              skeleton?.doNotDraw ? null : skeleton?.useEmptyPlaceholder ? (
                <div
                  {...createTestId('x-icon-skeleton-placeholder')}
                  style={{ width: `${size}px`, height: `${size}px` }}
                />
              ) : (
                <Skeleton.Avatar
                  {...createTestId('x-icon-skeleton')}
                  size={size}
                  active
                  style={!useStyleOnWrapper ? style : undefined}
                  className={cc([
                    'GlobalIcon__skeleton',
                    className && {
                      [className]: !skeleton?.detachPassedClassName
                    }
                  ])}
                  shape={skeletonStyle}
                />
              )
            }
          />
          {children}
        </span>
      );
    }

    return (
      <SvgInline
        key={iconPath || fallbackIconPath}
        url={iconPath}
        fallbackUrl={fallbackIconPath}
        fallbackElement={alt}
        className={cc(['v3-icon', className || ''])}
        {...rest}
        attributes={{
          width: typeof emWidth == 'number' ? `${emWidth}em` : emWidth,
          height: typeof emHeight == 'number' ? `${emHeight}em` : emHeight,
          fill: 'currentColor',
          ...createTestId('x-icon')
        }}
        svgStyle={{
          fontSize: size,
          minWidth: typeof emWidth == 'number' ? `${emWidth}em` : emWidth,
          color,
          position,
          ...style
        }}
        {...(!!prefix?.showAfterIconIsRendered && {
          onLoad: () => setIconLoaded(true)
        })}
        loadingElement={
          skeleton?.doNotDraw ? null : skeleton?.useEmptyPlaceholder ? (
            <div
              {...createTestId('x-icon-skeleton-placeholder')}
              style={{ width: `${size}px`, height: `${size}px` }}
            />
          ) : (
            <Skeleton.Avatar
              {...createTestId('x-icon-skeleton')}
              size={size}
              active
              style={style}
              wrapperStyle={{ position }}
              className={cc([
                'GlobalIcon__skeleton',
                className && {
                  [className]: !skeleton?.detachPassedClassName
                }
              ])}
              shape={skeletonStyle}
            />
          )
        }
      />
    );
  }
);
