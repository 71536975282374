import { useContext } from 'react';
import { TSportsbookConfigs } from 'interfaces/sportsbook-configs';
import {
  defaultConfigs,
  SportsbookConfigsContext
} from 'newelements/Sportsbook/Contexts';
import { useWidgetConfigs } from 'newelements/Sportsbook/Widgets/hooks/useWidgetConfigs';

function useSportsbookConfigs(): TSportsbookConfigs {
  const separatedWidgetConfigs = useWidgetConfigs();
  const mainConfigs = useContext(SportsbookConfigsContext);

  let sportsbookConfigs: TSportsbookConfigs = mainConfigs || defaultConfigs;

  if (separatedWidgetConfigs?.pageType !== undefined) {
    sportsbookConfigs = separatedWidgetConfigs as TSportsbookConfigs;
  }

  return sportsbookConfigs;
}

export { useSportsbookConfigs };
