import styled from 'styled-components';
import { EContainerIconSize } from 'utils/constants/app/ui';
import type { IEventActionProps } from './index';

export const Container = styled.span<{
  margin?: IEventActionProps['margin'];
  size?: 'sm' | 'md';
  iconContainerSize?:
    | EContainerIconSize.SMALL
    | EContainerIconSize.MIDDLE
    | EContainerIconSize.LARGE
    | 0;
  active?: boolean;
  ghost?: boolean;
  eventElement?: boolean;
}>`
  border-radius: 6px;
  ${props =>
    !props.ghost &&
    `
      background-color: var(--v3-black-4);
  `}
  ${props =>
    props.active
      ? `
          color: var(--v3-primary-color) !important;
        `
      : ` 
          color: var(--v3-black-45) !important;
  `}
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 4px;
  cursor: pointer;
  flex-shrink: 0;
  ${props =>
    `width: ${
      props.iconContainerSize === EContainerIconSize.SMALL
        ? '20px'
        : props.iconContainerSize === 0
        ? 0
        : props.iconContainerSize === EContainerIconSize.LARGE
        ? '32px'
        : '24px'
    };
     height: ${
       props.iconContainerSize === EContainerIconSize.SMALL
         ? '20px'
         : props.iconContainerSize === 0
         ? 0
         : props.iconContainerSize === EContainerIconSize.LARGE
         ? '32px'
         : '24px'
     };
     
     font-size: ${props.size === 'sm' ? '12px' : '16px'};
     
  `}
`;
