export const copyAttributes = (source: HTMLElement, target: HTMLElement) => {
  Array.from(source.attributes).forEach(attr => {
    target.setAttribute(attr.name, attr.value);
  });
};

export const createAttributesObjectFromSource = (
  source: HTMLElement | SVGElement
) => {
  return Array.from(source.attributes).reduce((acc, attr) => {
    acc[attr.name] = attr.value;

    return acc;
  }, {} as Record<string, string>);
};
