export const YOUR_PREFERENCES = 'yourPreferences';
export const ADD_COMPETITIONS_TOP_MATCHES = 'addCompetitionsTopMatches';
export const ADD_GAME_CARDS = 'addGameCards';
export const ADD_MARKET_CARDS = 'addMarketCards';
export const ADD_SPORTS = 'addSports';
export const ADD_COMPETITIONS = 'addCompetitions';
export const MY_CASINO = 'myCasino';
export const MY_CASINO_PROVIDER_ITEM = 'myCasinoProviderItem';
export const MY_CASINO_GAME_CARD = 'myCasinoGameCard';

export enum TOUR_STEPS_TYPES {
  yourPreferences = YOUR_PREFERENCES,
  addCompetitionsTopMatches = ADD_COMPETITIONS_TOP_MATCHES,
  addGameCards = ADD_GAME_CARDS,
  addMarketCards = ADD_MARKET_CARDS,
  addSports = ADD_SPORTS,
  addCompetitions = ADD_COMPETITIONS,
  myCasino = MY_CASINO,
  myCasinoProviderItem = MY_CASINO_PROVIDER_ITEM,
  myCasinoGameCard = MY_CASINO_GAME_CARD
}
