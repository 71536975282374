import { Dispatch } from 'redux';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import {
  PostGridHiddenParamsType,
  TIdinData,
  TLocateData
} from 'interfaces/account-settings';
import { TField } from 'interfaces/authentication';
import {
  AToZData,
  DataConfig,
  EFavoriteTabsTypes,
  THomeworkData
} from 'interfaces/generic';
import { TTourDataSteps, TTourSteps } from 'interfaces/tour';
import { TAfterSignInActions } from 'services/afterSignIn';
import { AppDataActionTypes } from 'store/action-types/app-data';
import { PersonalizationGenericSettings } from 'store/reducers/app-data';

export type ActionType = {
  type: string;
  payload?: any;
};

export const setColCount = (payload: number): ActionType => {
  return {
    type: AppDataActionTypes.SET_COL_COUNT,
    payload
  };
};

export const setShowToTopNav = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_SHOW_TO_TAP_NAV,
    payload
  };
};

export const setRecaptchaDetails = (payload: {
  recaptchaEnabled: boolean;
  recaptchaVersion: number;
  siteKey?: string;
}): ActionType => {
  return {
    type: AppDataActionTypes.SET_RECAPTCHA_DETAILS,
    payload
  };
};

export const setTurnstileDetails = (payload: {
  turnstileEnabled: boolean;
  turnstileSiteKey: string;
  turnstileVerifyActions: string[];
}): ActionType => {
  return {
    type: AppDataActionTypes.SET_TURNSTILE_DETAILS,
    payload
  };
};

export const setDataConfigs = (
  moduleId: string,
  configs: DataConfig | {}
): ActionType => {
  return {
    type: AppDataActionTypes.SET_DATA_CONFIGS,
    payload: { moduleId, configs }
  };
};

export const setRounding = (payload: number): ActionType => {
  return {
    type: AppDataActionTypes.SET_ROUNDING,
    payload
  };
};

export const setRoundingLoading = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_ROUNDING_LOADING,
    payload
  };
};

export const setShowAdditionalInfo = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_SHOW_ADDITIONAL_INFO,
    payload
  };
};

export const setOpenedModals = (payload: Set<string>): ActionType => {
  return {
    type: AppDataActionTypes.SET_OPENED_MODALS,
    payload
  };
};

export const setAtoZMenuData = (payload: Record<string, any>): ActionType => {
  return {
    type: AppDataActionTypes.SET_ATOZMENU_DATA,
    payload
  };
};

export const setSportsConfigs = (
  payload: Record<string, string>
): ActionType => {
  return {
    type: AppDataActionTypes.SET_SPORTSCONFIGS_DATA,
    payload
  };
};

export const setMenuJsonData = (payload: AToZData[]): ActionType => {
  return {
    type: AppDataActionTypes.SET_MENU_JSON_DATA,
    payload
  };
};

export const setSectionsData = (payload: any[]): ActionType => {
  return {
    type: AppDataActionTypes.SET_SECTIONS_DATA,
    payload
  };
};

export const setPostGridHiddenParams = (
  payload: PostGridHiddenParamsType
): ActionType => {
  return {
    type: AppDataActionTypes.SET_POSTGRID_HIDDEN_PARAMS,
    payload
  };
};

export const updatePostGridHiddenParams = (
  payload: PostGridHiddenParamsType
): ActionType => {
  return {
    type: AppDataActionTypes.UPDATE_POSTGRID_HIDDEN_PARAMS,
    payload
  };
};

export const resetPostGridHiddenParams = (): ActionType => {
  return {
    type: AppDataActionTypes.RESET_POSTGRID_HIDDEN_PARAMS
  };
};

export const setRegField = (payload: TField[]): ActionType => {
  if (SpringConfigs.POSTGRID_API_KEY && SpringConfigs.CANADIAN_ACCOUNT_TYPE) {
    let curField;
    const postGridHiddenFieldsTypes = ['country', 'province', 'city', 'zip'];

    postGridHiddenFieldsTypes.map(formType => {
      curField = (payload as TField[]).find(
        field => field.formType === formType
      );
      (curField as TField).editForm.showOnRegFields = '0';
    });
  }

  const addressFieldIndex = payload?.findIndex(
    field => field?.formType === 'address'
  );

  if (addressFieldIndex > -1) {
    const addressField = payload[addressFieldIndex];

    if (Number(addressField?.editForm.showAditionalAddress)) {
      const additionalAddressField = {
        type: 'text',
        name: 'additional_address',
        formType: 'additional_address',
        editForm: {
          customValidation:
            addressField?.editForm.aditionalAddressCustomValidation,
          customValidationCode:
            addressField?.editForm.aditionalAddressCustomValidationCode,
          disabled: addressField?.editForm.aditionalAddressDisabled,
          required: addressField?.editForm.aditionalAddressRequired,
          showOnEditProfile: addressField?.editForm.showOnEditProfile,
          showOnRegFields: addressField?.editForm.showOnRegFields
        } as TField['editForm']
      } as TField;

      payload.splice(addressFieldIndex + 1, 0, additionalAddressField);
    }
  }

  return {
    type: AppDataActionTypes.SET_REG_FIELD,
    payload
  };
};

export const setCashoutModalOpen = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_CASHOUT_MODAL_OPEN,
    payload
  };
};

export const setDepositModalOpen = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_DEPOSIT_MODAL_OPEN,
    payload
  };
};

export const setDepositPaymentStatus = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_DEPOSIT_PAYMENT_STATUS,
    payload
  };
};

export const closeDepositModal = () => {
  return (dispatch: Dispatch): void => {
    dispatch(setDepositModalOpen(false));
    dispatch(setDepositPaymentStatus(false));
  };
};

export const setFirebaseToken = (payload: string): ActionType => {
  return {
    type: AppDataActionTypes.SET_FIREBASE_TOKEN,
    payload
  };
};

export const setOneSignalSubId = (payload: string): ActionType => {
  return {
    type: AppDataActionTypes.SET_ONE_SIGNAL_SUB_ID,
    payload
  };
};

export const setSeonSessionData = (payload: string): ActionType => {
  return {
    type: AppDataActionTypes.SET_SEON_SESSION_DATA,
    payload
  };
};

export const setGeoLocationShakeData = (payload: string | null): ActionType => {
  return {
    type: AppDataActionTypes.SET_GEOLOCATION_SHAKE_DATA,
    payload
  };
};

export const setHomeworkData = (payload: THomeworkData): ActionType => {
  return {
    type: AppDataActionTypes.SET_HOMEWORK_DATA,
    payload
  };
};

export const setHeaderState = (payload: {
  isVisible?: boolean;
  height?: number;
}): ActionType => {
  return {
    type: AppDataActionTypes.SET_HEADER_STATE,
    payload
  };
};

export const setRecaptchaKey = (payload: string): ActionType => {
  return {
    type: AppDataActionTypes.SET_RECAPTCHA_KEY,
    payload
  };
};

export const setBetShops = (payload: []): ActionType => {
  return {
    type: AppDataActionTypes.SET_BET_SHOPS,
    payload
  };
};

export const setSportsBookType = (payload: string): ActionType => {
  return {
    type: AppDataActionTypes.SET_SPORTSBOOK_TYPE,
    payload
  };
};

export const setIsPaymentsLoading = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_IS_PAYMENTS_LOADING,
    payload
  };
};

export const setCryptoCurrencies = (
  payload: {
    Currency: string;
    Balance: number;
  }[]
): ActionType => {
  return {
    type: AppDataActionTypes.SET_CRYPTO_CURRENCIES,
    payload
  };
};

export const setSID = (payload: string): ActionType => {
  return {
    type: AppDataActionTypes.SET_SID,
    payload
  };
};

export const setRemainingSessionDurationModal = (payload: {
  isOpen: boolean;
  type: string | null;
}): ActionType => {
  return {
    type: AppDataActionTypes.SET_REMAINING_SESSION_DURATION_MODAL,
    payload
  };
};

export const setFavoriteOnHeaderOpen = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_FAVORITE_ON_HEADER_OPEN,
    payload
  };
};

export const setFavoriteSelectedTab = (
  payload: EFavoriteTabsTypes
): ActionType => {
  return {
    type: AppDataActionTypes.SET_FAVORITE_ON_HEADER_SELECTED_TAB,
    payload
  };
};

export const setIdinData = (payload: TIdinData): ActionType => {
  return {
    type: AppDataActionTypes.SET_IDIN_DATA,
    payload
  };
};

export const setLocateData = (payload: TLocateData): ActionType => {
  return {
    type: AppDataActionTypes.SET_LOCATE_DATA,
    payload
  };
};

export const setIsDarkMode = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_IS_DARK_MODE,
    payload
  };
};

export const setHigherZIndex = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_HIGHER_Z_INDEX,
    payload
  };
};

export const setInitSportsbookIframe = (): ActionType => {
  return {
    type: AppDataActionTypes.SET_INIT_SPORTSBOOK_IFRAME
  };
};

export const setForgotPasswordOpen = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_FORGOT_PASSWORD_OPEN,
    payload
  };
};

export const setForgotUsernameOpen = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_FORGOT_USERNAME_OPEN,
    payload
  };
};

export const setTourModalVisible = (payload: boolean): ActionType => {
  return {
    type: AppDataActionTypes.SET_TOUR_MODAL_VISIBLE,
    payload: payload
  };
};

export const setTourStepsInitialData = (
  payload: TTourDataSteps
): ActionType => {
  return {
    type: AppDataActionTypes.SET_TOUR_STEPS_INITIAL_DATA,
    payload
  };
};

export const setTourSteps = (payload: TTourSteps): ActionType => {
  return {
    type: AppDataActionTypes.SET_TOUR_STEPS,
    payload: payload
  };
};

export const setAfterSignInActions = (
  payload: TAfterSignInActions
): ActionType => {
  return {
    type: AppDataActionTypes.SET_AFTER_SIGN_IN_ACTIONS,
    payload: payload
  };
};

export const setPersonalizedGenericSettings = (
  payload: PersonalizationGenericSettings
) => {
  return {
    type: AppDataActionTypes.SET_PERSONALIZATION_GENERIC_SETTINGS,
    payload
  };
};

export const setGeoComplyErrorPopupAvailable = (payload: boolean) => {
  return {
    type: AppDataActionTypes.SET_GEO_COMPLY_ERROR_POPUP_AVAILABLE,
    payload
  };
};

export const setGeoComplyErrorPopupText = (payload: string | null) => {
  return {
    type: AppDataActionTypes.SET_GEO_COMPLY_ERROR_POPUP_TEXT,
    payload
  };
};
