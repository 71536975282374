import { ReactElement } from 'react';
import { EIconSize } from 'utils/constants/app/ui';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import '../../Desktop/index.less';

const MENU_UNDERLINE_SKELETON_COUNT = 15;

export const MenuUnderlineSkeletonDesktop = (): ReactElement => {
  return (
    <div className="menuWrapperDesktop">
      <div className="menuWrapperDesktop__menu">
        {[...Array(MENU_UNDERLINE_SKELETON_COUNT).keys()].map(key => (
          <div className="menuItemDesktop" key={key}>
            <Skeleton.Avatar size={EIconSize._32} active />
            <Skeleton
              title={{ style: { height: 9, width: 70, margin: '8px auto 0' } }}
              paragraph={false}
              active
            />
          </div>
        ))}
      </div>
    </div>
  );
};
