import { EIconSize } from 'utils/constants/app/ui';
import { createArray } from 'utils/helpers/create-fake-array';
import { isMobile } from 'utils/is-mobile';
import {
  ESportsListLayout,
  ISportListConfigs
} from 'components/Shared/SportsListContent';
import { SportListSkeleton } from 'components/Shared/SportsListContent/SportListSkeleton';
import { SportCategoriesUI } from 'newcomponents/Shared/sportsbook/SportCategoriesUI';
import { MenuUnderlineSkeleton } from 'newcomponents/UI/Menu/layouts/underline/skeleton';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import './index.less';

const SPORT_LIST_SKELETON_COUNT = 20;

export const SportsListSkeleton = (configs: ISportListConfigs) => {
  if (configs?.layoutSportsList === ESportsListLayout.Horizontal) {
    const getEnabledCategoriesSkeleton = () => {
      if (!isMobile()) {
        return null;
      }

      const categoriesSettings = {
        olympicCompetitionEnabled: configs?.olympicCompetitionEnabled,
        boostedOddsEnabled: configs?.boostedOddsEnabled,
        multiplesEnabled: configs?.multiplesEnabled,
        outRightEnabled: configs?.outRightEnabled,
        todayEventsEnabled: configs?.todayEventsEnabled,
        topLeagueEnabled: configs?.topLeagueEnabled,
        topMatchesEnabled: configs?.topMatchesEnabled,
        BBSportsList: configs?.BBSportsList,
        couponBuilderSportsList: configs?.couponBuilderSportsList
      };

      const enabledCategories = Object.keys(categoriesSettings).filter(
        config =>
          !!categoriesSettings[config as keyof typeof categoriesSettings]
      );

      if (enabledCategories.length) {
        return enabledCategories.map(category => (
          <div key={category} className="categoryItem">
            <Skeleton.Avatar size={EIconSize._16} />
            <Skeleton.Button style={{ height: '16px' }} />
          </div>
        ));
      }

      return null;
    };

    return (
      <div className="sportsListHorizontal">
        <SportCategoriesUI menuItems={getEnabledCategoriesSkeleton()} />
        <MenuUnderlineSkeleton />
      </div>
    );
  } else {
    return (
      <div className="sportsListVerticalSkeleton">
        {createArray(SPORT_LIST_SKELETON_COUNT).map(key => (
          <SportListSkeleton key={key} />
        ))}
      </div>
    );
  }
};
