import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CUSTOM_CATEGORIES } from 'utils/constants/casino/custom-categories';
import { mountPoint } from 'utils/generic/route-manipulation';
import { addQuery, saveCurrentLocation } from 'components/QueryRoute';
import { useSearchParams } from 'hooks/useSearchParams';

export const useOpenLoginPopup = (): Function => {
  const history = useHistory();
  const { mode } = useSearchParams();

  return useMemo(() => {
    return () => {
      let externalRoute = undefined;

      if (mode === 'real') {
        externalRoute = `${mountPoint(window.location.pathname, false, true)}/${
          CUSTOM_CATEGORIES.all.id
        }`;
      }

      saveCurrentLocation('login', externalRoute);
      history.push(addQuery({ accounts: '*', login: '*' }));
    };
  }, []);
};
