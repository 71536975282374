import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setScrollToCompetitionId, setScrollToGameId } from 'store/actions';

type TScrollTo = (
  ref: null | HTMLElement,
  type?: 'game' | 'competition',
  top?: number,
  avoidTimeout?: boolean,
  scrollOptions?: boolean | ScrollIntoViewOptions | undefined
) => void;

export const useGameScrollIntoView = (): TScrollTo => {
  const dispatch = useDispatch();

  return useCallback<TScrollTo>(
    (
      refElement,
      type = 'game',
      top = 0,
      avoidTimeout = false,
      scrollOptions?
    ) => {
      const action = () => {
        refElement && refElement.scrollIntoView(scrollOptions);

        if (window.scrollY + window.innerHeight < document.body.scrollHeight) {
          window.scrollBy(0, -top);
        }

        switch (type) {
          case 'game':
            dispatch(setScrollToGameId(undefined));
            break;
          case 'competition':
            dispatch(setScrollToCompetitionId(undefined));
            break;
          default:
            break;
        }
      };

      if (avoidTimeout) {
        action();
      } else {
        setTimeout(action);
      }
    },
    []
  );
};
