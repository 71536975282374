import { ReactElement } from 'react';
import cc from 'classcat';
import { EIconSize } from 'utils/constants/app/ui';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { createArray } from 'utils/helpers/create-fake-array';
import { Sport } from 'interfaces/sportsbook-data-levels';
import { GlobalIcon } from 'components/GlobalIcon';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import './index.less';

type TSportItemProps = {
  sport: Sport;
  isActive: boolean;
  onClick: (arg: Sport) => void;
};

export const SportItem = ({
  sport,
  isActive,
  onClick
}: TSportItemProps): ReactElement => {
  return (
    <div
      className={cc(['sportItem', { 'sportItem--active': isActive }])}
      onClick={() => onClick(sport)}
    >
      <span className="sportItem__iconName">
        <GlobalIcon size={EIconSize._24} lib="sports" name={sport.alias} />
        {sport.name}
      </span>
      <GlobalIcon
        size={EIconSize._12}
        lib="generic"
        name={SpringConfigs.IS_RTL ? 'caretleft' : 'caretRight'}
        theme="default"
      />
    </div>
  );
};

const SPORT_ITEM_SKELETON_COUNT = 5;

type TSportItemSkeletonProps = {
  skeletonCount?: number;
};

export const SportItemSkeleton = ({
  skeletonCount
}: TSportItemSkeletonProps): ReactElement => {
  return (
    <>
      {createArray(skeletonCount || SPORT_ITEM_SKELETON_COUNT).map(key => (
        <div className="sportItem" key={key}>
          <span className="sportItem__iconName">
            <GlobalIcon size={EIconSize._24} lib="sports" />
            <Skeleton
              title={{ style: { height: 14, width: 80, margin: '0' } }}
              paragraph={false}
              active
            />
          </span>
          <GlobalIcon
            size={EIconSize._12}
            lib="generic"
            name={SpringConfigs.IS_RTL ? 'caretleft' : 'caretRight'}
            theme="default"
          />
        </div>
      ))}
    </>
  );
};
