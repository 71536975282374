import { ReactElement, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cc from 'classcat';
import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { EIconSize } from 'utils/constants/app/ui';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { FavoriteCompetitionInfo } from 'interfaces/sportsbook-data-levels';
import { GlobalIcon } from 'components/GlobalIcon';
import { useOpenLoginPopup } from 'hooks/account/useOpenLoginPopup';
import { toggleFavorite } from 'store/actions/fav-data';
import { getFavoriteActiveStateById } from 'store/selectors/fav-data';
import { getIsLoggedIn } from 'store/selectors/user-data';
import './index.less';

if (SpringConfigs.IS_RTL) {
  import('./rtl.less');
}

type TProps = {
  iconSize?: number;
  favItemIsRemoved?: boolean;
  competitionInfo: {
    noAction?: boolean;
  } & FavoriteCompetitionInfo;
};

export const CompetitionsFavorite = ({
  competitionInfo,
  iconSize = EIconSize._16
}: TProps): ReactElement | null => {
  const dispatch = useDispatch();
  const openLoginPopup = useOpenLoginPopup();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const active = useSelector(
    getFavoriteActiveStateById(competitionInfo.id, 'competitions')
  );

  const handleFavoriteClick = (e: SyntheticEvent) => {
    if (competitionInfo.noAction) {
      return;
    }

    e.stopPropagation();

    if (SpringConfigs.USER_PERSONALIZATION_ENABLED && !isLoggedIn) {
      LocalStorage.setItem(
        storageKeyName('account', 'UNSIGNED_FAV_COMPETITION'),
        JSON.stringify(competitionInfo)
      );
      openLoginPopup();
    } else {
      dispatch(
        toggleFavorite({ groupKey: 'competitions', entity: competitionInfo })
      );
    }
  };

  return (
    <span
      className="Competitions__FavoriteIconWrapper"
      onClick={handleFavoriteClick}
    >
      <GlobalIcon
        lib="generic"
        name="favorite"
        theme="default"
        size={iconSize}
        className={cc([
          'Competitions__FavoriteIconWrapper__FavoriteIcon',
          { Competitions__FavoriteIconWrapper__FavoriteIcon__active: active }
        ])}
      />
    </span>
  );
};
