import { JSXElementConstructor, ReactElement } from 'react';
import {
  Game,
  IMenuGroupWithSports,
  ISportMenuGroup,
  Sport as SportType
} from 'interfaces/sportsbook-data-levels';
import { MarketTypeSwarm as TMarketTypeSwarm } from './bet-data';
import { ResponseGeneric, TSortingTypes } from './generic';

export enum ELayoutTypes {
  classic = 'classic',
  usa = 'usa'
}

export enum EPageTypes {
  prematch = 'prematch',
  live = 'live',
  today = 'today',
  team = 'team'
}

type TGamesDetail = {
  id: number;
  alias: string;
  name: string;
};

export type SportsBookIframePostMsg = {
  data: {
    action: string;
    numOfInsights: number;
    market: any;
    event: number[];
    game: (string | number)[];
  };
};

type TGamesDetails = {
  sport: TGamesDetail;
  region: TGamesDetail;
  competition: Omit<TGamesDetail, 'alias'>;
  game?: {
    id: number;
  };
};

export type TEvent = {
  name: string;
  id: number;
  price: number;
  order: number;
  type_1: string;
};

type TMarket = {
  name: string;
  order: number;
  type: string;
  id: number;
  express_id: number;
  col_count: number;
  event: {
    [key: string]: TEvent;
  };
};

type TGameInfo = {
  current_game_state: string;
  current_game_time: string;
  score1: string;
  score2: string;
  pass_team: string;
  shirt1_color: string;
  shirt2_color: string;
  short1_color: string;
  short2_color: string;
  field: number;
  add_info?: string;
};

export type TGame = {
  id: number;
  team1_name: string;
  team2_name: string;
  team1_id: number;
  team2_id: number;
  start_ts: number;
  markets_count?: number;
  is_blocked: number;
  sportcast_id: number;
  is_stat_available: boolean;
  type?: number;
  text_info?: string;
  game_number?: number;
  add_info_name?: string;
  tv_info?: string;
  info?: TGameInfo;
  hasVideoStream?: boolean;
  market: {
    [key: string]: TMarket;
  };
  stats: Record<
    string,
    {
      team1_value: any; // can be any type
      team2_value: any; // can be any type
      add_info: null | string;
    }
  >;
};

export type TGamesGroups = {
  name: string;
  details: TGamesDetails;
  games: Game[];
}[];

export interface TGameListProps {
  layoutType: ELayoutTypes;
  gameIds: number[] | null;
  showHeader?: boolean;
  showMarketFilter?: boolean;
}

export interface TLiveGameListProps extends TGameListProps {
  sport?: string;
  sportGroupData?: IMenuGroupWithSports;
}

export type TMarketTypeResponse = Omit<ResponseGeneric, 'details'> & {
  details: TMarketTypeSwarm[];
};

export type TMarketType = {
  label: string;
  value: string;
  selectionTypesNames?: string[];
};

export type TSportListGroupingCB = (data: ISportMenuGroup[]) => void;

export type TSortingOption = {
  name: 'default' | 'aToZ' | 'zToA' | 'time';
  key: '' | 'team1_name' | 'start_ts' | 'name' | 'order';
  alwaysDisabled?: boolean;
  type: TSortingTypes;
};

export type TMarketCountResponse = {
  game: {
    [key: string]: {
      id: number;
      market: number;
    };
  };
};

export type TFavoriteDataResponse = { sport?: Record<string, SportType> };

export enum TTabDataKey {
  SPORTSBOOK = 'sportsbook',
  CALENDAR = 'calendar',
  RESULTS = 'results',
  TOURNAMENTS = 'tournaments',
  BETHISTORY = 'betHistory',
  STATISTICS = 'statistics',
  MULTIVIEW = 'multiview',
  OVERVIEW = 'overview'
}

export type TTabData = {
  key: TTabDataKey;
  route: string;
  value: string;
  component?: () => ReactElement<any, string | JSXElementConstructor<any>>;
  props?: { [k: string]: any };
};

export enum EAdvancedEventsLayouts {
  live = 'live',
  popular = 'popular',
  upcoming = 'upcoming'
}

export type TGameMainInfoType = Pick<
  Game,
  | 'sportName'
  | 'sportId'
  | 'sport_alias'
  | 'regionAlias'
  | 'region_alias'
  | 'regionName'
  | 'competitionName'
  | 'competitionId'
  | 'id'
  | 'team1_id'
  | 'team2_id'
  | 'team1_name'
  | 'team2_name'
  | 'info'
  | 'stats'
  | 'start_ts'
  | 'type'
  | 'is_blocked'
  | 'tv_type'
  | 'video_id'
  | 'video_id2'
  | 'is_feed_available'
  | 'text_info'
  | 'game_number'
  | 'is_live'
  | 'is_stat_available'
  | '_parent_id'
>;

export type TGameMarketsInfoType = Pick<Game, 'market'>['market'];

export type TAdvancedSelectedMarkets = {
  first: string;
  second?: string;
  third?: string;
};

export type TSelectedMarketTypeNames = {
  first?: string[];
  second?: string[];
  third?: string[];
};

export type TAdvancedSelectedRangeFilter = {
  title: string;
  ranges?: {
    '@gt': number;
    '@lt': number;
  };
};

export enum EAdvancedCouponsLayouts {
  todayMatches = 'todayMatches',
  tomorrowMatches = 'tomorrowMatches',
  upcomingMatches = 'upcomingMatches'
}
