import { ReactElement } from 'react';
import { EIconSize } from 'utils/constants/app/ui';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import '../../Mobile/index.less';

const MENU_UNDERLINE_SKELETON_MOBILE_COUNT = 10;

export const MenuUnderlineSkeletonMobile = ({
  isAtoZ
}: {
  isAtoZ?: boolean;
}): ReactElement => {
  return (
    <div className="menuWrapperMobile">
      <div className="menuWrapperMobile__menu">
        {[...Array(MENU_UNDERLINE_SKELETON_MOBILE_COUNT).keys()].map(key => (
          <div className="menuItemMobile" key={key}>
            {isAtoZ ? (
              <div className="skeletonWrapper"></div>
            ) : (
              <Skeleton.Avatar size={EIconSize._24} active />
            )}
            <Skeleton
              title={{ style: { height: 9, width: 50, margin: '5px 10px' } }}
              paragraph={false}
              active
            />
          </div>
        ))}
      </div>
    </div>
  );
};
