import axios from 'axios';
import dayjs from 'dayjs';
import i18n from 'i18next';
import {
  dataspotFunnelAction,
  EDataspotAction
} from 'utils/analytics/dataspot';
import { getCookie } from 'utils/bom-dom-manipulation/cookies';
import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import SessionStorage from 'utils/bom-dom-manipulation/session-storage';
import { ExclusionTypes } from 'utils/constants/account/exclusion-types';
import { SECONDS_PER_MINUTE } from 'utils/constants/dateInfo/time-numbers';
import { EAccount } from 'utils/constants/store/query-keys';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { CommandNames } from 'utils/constants/swarm/swarm-command-names';
import { SwarmSuccessMessages } from 'utils/constants/swarm/swarm-success-messages';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { appendCustomCode } from 'utils/other/customCode';
import { checkTypeIsNotSportbook } from 'utils/sportsbook/check-type-is-not-sportbook';
import RidGenerator from 'utils/swarm/rid-generator';
import type { TDocumentToUpload } from 'interfaces/accoint-documents';
import {
  ChangePassword,
  DateParams,
  DepositLimits,
  DepositParams,
  DepositResponse,
  DepositResponseError,
  LimitsResponse,
  PartnerBonusesForRequestResponse,
  PaymentDeleteWalletResponse,
  PaymentResponse,
  PaymentResponseError,
  PaymentStatusResponse,
  PaymentV2Response,
  PaymentV2ResponseError,
  PaymentWalletResponse,
  Period,
  SessionInfoResponse,
  SportActivityResponse,
  SwarmResponse,
  TCashbackConvertDataProps,
  TClientBonusesResponse,
  TClientBonusRequestResponse,
  TLimitsError,
  TLimitsHistoryResponse,
  TUpdateClientInfo,
  UpdateNotificationParams,
  UpdateProfileParams,
  WithdrawParams,
  WithdrawResponse,
  WithdrawResponseError
} from 'interfaces/account-settings';
import {
  BonusesResponseType,
  UpdateUserPasswordResponse
} from 'interfaces/authentication';
import { AccountParamsType, AppendCustomCodes } from 'interfaces/generic';
import {
  CommandObj,
  WebsocketEnhanced
} from 'interfaces/spring-websocket-interfaces';
import { authCommand } from './authentication';
import { connectingWS } from 'hooks/useSpringConnect';
import { queryClient } from 'stores/query/query-client';

type TUserMessages = {
  type: 0 | 1;
  from_date?: number;
  to_date?: number;
};

export const getGenericAccountCommand = <T>(
  commandName: string,
  callback: (data: T) => void,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames[commandName],
    rid: RidGenerator.gForCommand()
  };

  authCommand(
    command,
    (data: T) => {
      callback(data);
    },
    null,
    () => {
      callbackError?.();
    }
  );
};

export const changePassword = (
  fields: ChangePassword,
  callback: Function | null
): void => {
  const command = {
    command: CommandNames.UPDATE_USER_PASSWORD,
    params: fields,
    successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, (data: UpdateUserPasswordResponse) => {
    const auth_data_x = JSON.parse(
      LocalStorage.getItem(storageKeyName('account', 'AUTH_DATA'))
    );

    if (data.auth_token) {
      auth_data_x.auth_token = data.auth_token;
      LocalStorage.setItem(
        storageKeyName('account', 'AUTH_DATA'),
        JSON.stringify(auth_data_x)
      );
    }

    callback && callback(data);
  });
};

export const updateRealityCheck = (
  activeTime: number | string,
  callback: Function | null
  // userId: string | number
): void => {
  const command = {
    command: CommandNames.UPDATE_USER_ACTIVE_TIME,
    params: {
      active_time: activeTime
    },
    successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, () => {
    // ATTENTION: active_time not used in project. Understand why it is still here
    // SessionStorage.setItem('active_time', `${userId}_${activeTime}`);
    SessionStorage.setItem(
      storageKeyName('account', 'ACTIVE_TIME_IN_CASINO'),
      activeTime as string
    );
    callback && callback();
  });
};

export const getBonusData = (
  sportsbook: boolean,
  callback: (data: BonusesResponseType) => void
): void => {
  const command = {
    command: CommandNames.GET_BONUS_DETAILS,
    params: { free_bonuses: sportsbook },
    rid: RidGenerator.gForCommand()
  };

  if (!sportsbook) {
    dataspotFunnelAction(EDataspotAction.CasinoBonus);
  }

  checkTypeIsNotSportbook(command.command, sportsbook) &&
    authCommand(command, (data: BonusesResponseType) => {
      callback(data);
    });
};

export const getNewMessages = (callback: Function): void => {
  const command = {
    command: CommandNames.GET_MESSAGES,
    params: { source: 'messages', subscribe: true, what: { messages: [] } },
    rid: RidGenerator.gForSubscribe()
  };

  authCommand(command, callback, callback);
};

export const getAvailableBalance = (
  twoWalletType: string,
  callback: Function
): void => {
  const command = {
    command: CommandNames.GET_CLIENT_WITHDRAWABLE_BALANCE,
    params: {
      product: twoWalletType.charAt(0).toUpperCase() + twoWalletType.slice(1)
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback);
};

type TUpdateTimeoutParams = {
  password?: string;
  confirmation_code?: string;
};

export const updateTimeout = (
  periodObj: {
    type: string;
    amount: number;
  } | null,
  exclusionType: number,
  callback: Function | null,
  params?: TUpdateTimeoutParams
): void => {
  if (periodObj) {
    const command = {
      command: CommandNames.SET_CLIENT_SELF_EXCLUSION,
      params: {
        exc_type: exclusionType,
        [periodObj.type]: periodObj.amount,
        ...(params || {})
      },
      successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
      rid: RidGenerator.gForCommand()
    };

    authCommand(command, callback);
  }
};

export const sportActivity = (
  fromDate: number,
  toDate: number,
  callback: (data: SportActivityResponse) => void
): void => {
  authCommand(
    {
      command: CommandNames.GET_CLIENT_SPORT_ACTIVITY,
      params: {
        from_date: fromDate,
        to_date: toDate
      },
      rid: RidGenerator.gForCommand()
    },
    callback
  );
};

export const getSessionInfo = (
  callback: (data: SessionInfoResponse) => void,
  callbackError: Function
): void => {
  authCommand(
    {
      command: CommandNames.GET_CLIENT_CURRENT_SESSION_INFO,
      params: {},
      rid: RidGenerator.gForCommand()
    },
    callback,
    null,
    callbackError
  );
};

export const blockUser = (callback: Function): void => {
  const command = {
    command: CommandNames.SET_CLIENT_SELF_EXCLUSION,
    params: {
      days: 1,
      exc_type: ExclusionTypes.TIMEOUT,
      reason: '11'
    },
    successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback);
};

type TSetSelfExclusionParams = {
  password?: string;
  confirmation_code?: string;
};

export const setSelfExclusion = (
  data: Period,
  exclusionType: number,
  callback: () => void,
  params?: TSetSelfExclusionParams
): void => {
  const command = {
    command: CommandNames.SET_CLIENT_SELF_EXCLUSION,
    params: {
      exc_type: exclusionType,
      [data.type]: data.amount,
      ...(data.reason && { reason: data.reason }),
      ...(params || {})
    },
    successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, () => {
    callback();

    const accountParams: AccountParamsType = queryClient.getQueryData([
      EAccount['AccountParams']
    ]);

    if (
      accountParams &&
      +accountParams[AppendCustomCodes.APPEND_CUSTOM_SELF_EXC]
    ) {
      appendCustomCode(
        accountParams[AppendCustomCodes.CUSTOM_CODE_SELF_EXC],
        data.user || {}
      );
    }
  });
};

export const closeAccount = (
  currentReason: string,
  callback: Function,
  callbackError: Function,
  password?: string
): void => {
  const params: {
    years?: number;
    exc_type?: number;
    password?: string;
    reason_text?: string;
  } = {
    exc_type: ExclusionTypes.ACCOUNT_CLOSURE,
    ...(!SpringConfigs.ACCOUNT_CLOSURE && {
      years: 1000
    }),
    ...(currentReason && { reason_text: currentReason })
  };

  if (password) {
    params.password = password;
  }

  const command = {
    command: CommandNames.SET_CLIENT_SELF_EXCLUSION,
    params: params,
    successMessage: i18n.t(
      `account.${SwarmSuccessMessages.ACCOUNT_SETTING_SET_ACCOUNT_CLOSURE}`
    ),
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const updateLoginLimit = (
  seconds: number | Array<number>,
  callback?: Function,
  showSuccessMessage = true
): void => {
  const command = {
    command: CommandNames.SET_SESSION_DURATION,
    params: {
      session_duration:
        seconds.toString() !== '0' ? +seconds / SECONDS_PER_MINUTE : null
    },
    ...(showSuccessMessage && {
      successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`)
    }),
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, () => {
    callback?.();
  });
};

export const getSessionHistory = (
  fromDate: Date,
  toDate: Date,
  callback: Function
): void => {
  authCommand(
    {
      command: CommandNames.GET_CLIENT_LOGIN_HISTORY,
      params: {
        from_date: dayjs(fromDate).startOf('day').unix(),
        to_date: dayjs(toDate).endOf('day').unix()
      },
      rid: RidGenerator.gForCommand()
    },
    callback
  );
};

export const getDocuments = (callback: Function): void => {
  authCommand(
    {
      command: CommandNames.GET_IMAGES,
      params: {},
      rid: RidGenerator.gForCommand()
    },
    callback
  );
};

export const uploadDocuments = (
  image_objects: TDocumentToUpload[],
  callback: (res: {
    details: { Status: string; Name: string }[];
    result: number;
    result_text: string | number;
  }) => void,
  callbackError: (err: { data: { result: number } }) => void
): void => {
  dataspotFunnelAction(EDataspotAction.UploadDocument);
  authCommand(
    {
      params: {
        image_objects
      },
      command: CommandNames.UPLOAD_IMAGES_V2,
      rid: RidGenerator.gForCommand()
    },
    callback,
    null,
    callbackError
  );
};

export const uploadDocumentsMultiple = (
  params: Record<string, string | number | undefined>[],
  callback: Function,
  callbackError: Function
): void => {
  authCommand(
    {
      command: CommandNames.UPLOAD_IMAGES,
      params: { image_objects: params },
      successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
      rid: RidGenerator.gForCommand()
    },
    callback,
    null,
    callbackError
  );
};

export const getLegigameDocuments = (
  callback: Function,
  callbackError: Function
): void => {
  authCommand(
    {
      command: CommandNames.GET_CLIENT_LEGIGAME_UPLOADED_IMAGES,
      params: {},
      rid: RidGenerator.gForCommand()
    },
    callback,
    null,
    callbackError
  );
};

export const uploadLegigameDocuments = (
  params: object,
  callback: Function,
  callbackError: Function
): void => {
  authCommand(
    {
      command: CommandNames.ADD_OR_UPLOAD_LEGIGAME_CLIENT_DOCS,
      params,
      successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
      rid: RidGenerator.gForCommand()
    },
    callback,
    null,
    callbackError
  );
};

export const updateNotifications = (
  params: UpdateNotificationParams,
  callback: Function | null
): void => {
  authCommand(
    {
      command: CommandNames.UPDATE_USER,
      params: {
        user_info: { ...params }
      },
      successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
      rid: RidGenerator.gForCommand()
    },
    callback
  );
};

export const updateProfile = (
  params: UpdateProfileParams,
  callback: Function | null,
  callbackError?: Function | null
): void => {
  authCommand(
    {
      command: CommandNames.UPDATE_USER,
      params: {
        user_info: { ...params }
      },
      successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
      rid: RidGenerator.gForCommand()
    },
    callback,
    null,
    callbackError
  );
};

export const updateClientInfo = (params: TUpdateClientInfo): void => {
  authCommand(
    {
      command: CommandNames.UPDATE_CLIENT_INFO,
      params,
      rid: RidGenerator.gForCommand()
    },
    () => {}
  );
};

export const getBetHistory = (
  params: DateParams,
  callback: Function,
  callbackError?: Function
): void => {
  authCommand(
    {
      command: CommandNames.BET_HISTORY,
      params: {
        where: { ...params }
      },
      rid: RidGenerator.gForCommand()
    },
    callback,
    null,
    callbackError
  );
};

export const getBetHistoryCount = (callback: (count: number) => void): void => {
  const plainBetHistoryCounts = new Promise<number>(resolve => {
    getBetHistory(
      {
        outcome: 0,
        only_counts: true
      },
      (data: null | { bets: { count: number } }) => {
        if (data === null) {
          resolve(0);
        } else {
          resolve(data.bets.count);
        }
      },
      () => {
        resolve(0);
      }
    );
  });

  const underReviewBetHistoryCounts = new Promise<number>(resolve => {
    resolve(0);
    /* ATTENTION: must be uncommented for getting counter offer counts as well (must be implemented in backend) */
    // getBetHistory(
    //   {
    //     outcome: -5,
    //     only_counts: true
    //   },
    //   (data: null | { bets: { count: number } }) => {
    //     if (data === null) {
    //       resolve(0);
    //     } else {
    //       resolve(data.bets.count);
    //     }
    //   },
    //   () => {
    //     resolve(0);
    //   }
    // );
  });

  Promise.all([plainBetHistoryCounts, underReviewBetHistoryCounts]).then(
    ([plainCount, underReviewCount]) => {
      callback(plainCount + underReviewCount);
    }
  );
};

type balanceParams = {
  from_date: number;
  to_date: number;
  type: string;
  activeElement: 'all' | 'sportsbook' | 'casino';
  time_shift: number;
  currency_id?: string;
  product?: string;
};

export const getBalanceHistory = (
  params: balanceParams,
  casino: boolean,
  callback: Function,
  errCallback?: Function
): void => {
  const paramsObj: {
    command: string;
    params: {
      where: balanceParams;
      product?: string;
    };
    rid: string;
  } = {
    command: CommandNames.BALANCE_HISTORY,
    params: {
      where: { ...params }
    },
    rid: RidGenerator.gForCommand()
  };

  if (casino) {
    paramsObj.params.product = 'Casino';
  }

  authCommand(paramsObj, callback, errCallback);
};

export const getNetBalanceHistory = (
  params: {
    from_date: number;
    to_date: number;
  },
  callback: Function,
  errCallback?: Function
): void => {
  const paramsObj = {
    command: CommandNames.GET_CLIENT_NET_BALANCE,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(paramsObj, callback, errCallback);
};

export const cashout = (
  commandObj: CommandObj,
  callback: Function,
  callbackError: Function
): void => {
  commandObj.rid = RidGenerator.gForCommand();
  authCommand(commandObj, callback, null, callbackError);
};

export const getAutoCashoutRule = (
  betId: number | string,
  callback: Function,
  callbackError: Function
): void => {
  const commandObj: CommandObj = {
    command: CommandNames.GET_BET_AUTO_CASHOUT,
    params: {
      bet_id: betId
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(commandObj, callback, null, callbackError);
};

export const cancelAutoCashoutRule = (
  betId: number | string,
  callback: Function,
  callbackError: Function
): void => {
  const commandObj: CommandObj = {
    command: CommandNames.CANCEL_BET_AUTO_CASHOUT,
    params: {
      bet_id: betId
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(commandObj, callback, null, callbackError);
};

/***  ---  PAYMENTS  ---  ***/

export const getPayments = (callback: Function): void => {
  const command = {
    command: CommandNames.PAYMENT_SERVICES_V2,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, (data: PaymentV2Response) => {
    const isErrorType = (
      data: PaymentV2Response
    ): data is PaymentV2ResponseError => {
      return !!(data as PaymentV2ResponseError)?.message;
    };

    if (isErrorType(data)) {
      callback({
        deposit: {},
        withdraw: {}
      });
    } else {
      getPaymentsDetailed(
        (fullPayments: PaymentResponse) => {
          const filteredData: PaymentResponse = {
            deposit: {},
            withdraw: {}
          };

          const customPayment: PaymentResponse | undefined =
            queryClient.getQueryData([EAccount['CustomPayment']]);

          let type: keyof PaymentResponse;

          for (type in fullPayments) {
            const allKeys = Object.keys(fullPayments[type] || {});

            if (allKeys.length > 0 && data?.[type]?.length > 0) {
              allKeys.forEach((itemKey: string) => {
                const paymentId = fullPayments[type][itemKey].PaymentSystem_id;

                if (data[type].includes(Number(paymentId))) {
                  if (
                    customPayment &&
                    customPayment?.[type] &&
                    customPayment[type][paymentId]
                  ) {
                    if (!customPayment[type][paymentId].status) {
                      return;
                    } else {
                      fullPayments[type][itemKey].order =
                        customPayment[type][paymentId].order;
                      const translations =
                        customPayment[type][paymentId].translations;

                      if (
                        translations &&
                        translations[SpringConfigs.CURRENT_LANGUAGE]
                      ) {
                        fullPayments[type][itemKey].translations =
                          translations[SpringConfigs.CURRENT_LANGUAGE];

                        if (
                          translations[SpringConfigs.CURRENT_LANGUAGE].infotext
                        ) {
                          fullPayments[type][itemKey].infotext =
                            translations[
                              SpringConfigs.CURRENT_LANGUAGE
                            ].infotext;
                        }
                      }

                      if (customPayment[type][paymentId].icon) {
                        const url = `${SpringConfigs.USER_FILES_URL}/media/thumb/${customPayment[type][paymentId].icon}?v=${SpringConfigs.VERSION}`;
                        fullPayments[type][itemKey].PaymentSystemIconUrl = url;
                      }
                    }
                  }

                  filteredData[type][itemKey] = fullPayments[type][itemKey];
                }
              });
            }
          }

          callback(filteredData);
        },
        () => {
          callback({
            deposit: {},
            withdraw: {}
          });
        }
      );
    }
  });
};

const getPaymentsDetailed = (callback: Function, callbackError: Function) => {
  const command = {
    command: CommandNames.PAYMENT_SERVICES,
    params: {
      detailed: true
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(
    command,
    (data: PaymentResponse) => {
      callback(data);
    },
    null,
    callbackError
  );
};

export const deposit = (
  params: DepositParams,
  callback: Function,
  callbackError: Function | null,
  v2?: boolean
): void => {
  const command = {
    command: v2 ? CommandNames.DEPOSIT_V2 : CommandNames.DEPOSIT,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(
    command,
    (data: DepositResponse) => {
      callback(data.details || data);
    },
    null,
    (
      data: Omit<SwarmResponse, 'data'> & {
        data: DepositResponseError;
      }
    ) => {
      callbackError?.(data.data);
    }
  );
};

export const withdraw = (
  params: WithdrawParams,
  callback: Function,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames.WITHDRAW,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(
    command,
    (data: WithdrawResponse) => {
      callback(data);
    },
    null,
    (
      data: Omit<SwarmResponse, 'data'> & {
        data: WithdrawResponseError;
      }
    ) => {
      callbackError(data.data);
    }
  );
};

export const getPaymentStatus = (
  isDeposit: boolean,
  callback: Function,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames[isDeposit ? 'GET_DEPOSITS' : 'GET_WITHDRAWALS'],
    rid: RidGenerator.gForCommand(),
    params: {}
  };

  authCommand(
    command,
    (data: PaymentStatusResponse) => {
      callback(data);
    },
    null,
    () => {
      callbackError();
    }
  );
};

export const getDepositLimits = (
  callback: (data: DepositLimits) => void,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.USER_LIMITS,
    rid: RidGenerator.gForCommand(),
    params: {
      type: 'deposit'
    }
  };

  authCommand(
    command,
    (data: DepositLimits) => {
      callback(data);
    },
    null,
    () => {
      callbackError?.();
    }
  );
};

export const setDepositLimits = (
  limits: { deposit_limit: number; period_type: number; period: number }[],
  callback?: (data: LimitsResponse) => void,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.SET_USER_LIMITS,
    rid: RidGenerator.gForCommand(),
    successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
    params: {
      type: 'deposit',
      limits
    }
  };

  authCommand(
    command,
    (data: LimitsResponse) => {
      callback?.(data);
    },
    null,
    ({ data }: { data: TLimitsError }) => {
      callbackError?.(data);
    }
  );
};

export const setSportsbookLimits = (
  limits: {
    sport_max_daily_bet?: number;
    sport_max_single_bet?: number;
    sport_max_monthly_bet?: number;
    sport_max_weekly_bet?: number;
  },
  callback?: (data: LimitsResponse) => void,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.SET_CLIENT_SPORT_BET_LIMIT,
    rid: RidGenerator.gForCommand(),
    successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
    params: limits
  };

  authCommand(
    command,
    (data: LimitsResponse) => {
      callback?.(data);
    },
    null,
    ({ data }: { data: TLimitsError }) => {
      callbackError?.(data);
    }
  );
};

export const setLossLimits = (
  commandName: keyof typeof CommandNames,
  limits: {
    daily_loss: number;
    weekly_loss: number;
    monthly_loss: number;
  },
  callback?: (data: LimitsResponse) => void,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames[commandName],
    rid: RidGenerator.gForCommand(),
    successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`),
    params: limits
  };

  authCommand(
    command,
    (data: LimitsResponse) => {
      callback?.(data);
    },
    null,
    ({ data }: { data: TLimitsError }) => {
      callbackError?.(data);
    }
  );
};

export const setCasinoLimits = (
  limits: {
    casino_max_daily_bet?: number;
    casino_max_single_bet?: number;
    casino_max_weekly_bet?: number;
    casino_max_monthly_bet?: number;
  },
  callback?: (data: LimitsResponse) => void,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.SET_CLIENT_CASINO_BET_LIMIT,
    rid: RidGenerator.gForCommand(),
    params: limits
  };

  authCommand(
    command,
    (data: LimitsResponse) => {
      callback?.(data);
    },
    null,
    () => {
      callbackError?.();
    }
  );
};

export const addTruNarrativeDocs = (
  data: {
    country: string;
    imageData: string;
    document: number;
    imageName: string;
  },
  callback: Function,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames.ADD_TRU_NARRATIVE_CLIENT_DOCS,
    params: {
      doc_country: data.country,
      doc_tag: 'FRONT',
      image_info: {
        image_data: data.imageData,
        image_type: data.document,
        name: data.imageName
      }
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const getAccountJson = async (jsonUrl: string): Promise<any> => {
  if (jsonUrl) {
    const url = getAccountJsonCountryExceptionUrl(jsonUrl);
    let params: any = [];
    await fetch(url)
      .then(data => data.json())
      .then(resp => {
        params = resp;
      });

    return params;
  } else {
    throw new Error('no URL provided');
  }
};

const getAccountJsonCountryExceptionUrl = (jsonUrl: string): string => {
  const countryCode = getCookie('country') as string;

  let url = jsonUrl;

  if (
    countryCode &&
    SpringConfigs.ACCOUNT_PARAMS_COUNTRY_EXCEPTIONS &&
    SpringConfigs.ACCOUNT_PARAMS_COUNTRY_EXCEPTIONS.includes(countryCode)
  ) {
    const pathPrefix = jsonUrl.split('.json')[0];

    if (pathPrefix) {
      url = `${pathPrefix}_${countryCode}.json`;
    }
  }

  return url;
};

export const getAccountJsonQuery = async (JsonUrl: string) => {
  const { data } = await axios.get(JsonUrl);

  return data;
};

export const setSessionDurationLimit = (
  data: Record<string, number>,
  callback?: Function,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.SET_SESSION_DURATION_LIMIT,
    params: {
      daily_duration: data.DailyDuration * SECONDS_PER_MINUTE,
      weekly_duration: data.WeeklyDuration * SECONDS_PER_MINUTE,
      monthly_duration: data.MonthlyDuration * SECONDS_PER_MINUTE
    },
    rid: RidGenerator.gForCommand(),
    successMessage: i18n.t(
      `account.${SwarmSuccessMessages.ACCOUNT_SETTING_SET_SESSION_LIMIT}`
    )
  };

  authCommand(
    command,
    () => callback?.(),
    null,
    () => callbackError?.()
  );
};

export const userMessages = (
  where: TUserMessages = {
    type: 1
  },
  callback: Function,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.USER_MESSAGES,
    params: { where },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const readUserMessage = (
  id: number,
  callback: Function,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.READ_USER_MESSAGE,
    params: {
      message_id: id
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const sendUserMessage = (
  message: { body: string; subject: string },
  callback: Function,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.ADD_USER_MESSAGE,
    params: {
      body: message.body,
      subject: message.subject
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const cancelWithdraw = (id: number, callback: Function): void => {
  const command = {
    command: CommandNames.WITHDRAW_CANCEL,
    params: { id },
    rid: RidGenerator.gForCommand(),
    successMessage: i18n.t(`account.${SwarmSuccessMessages.SUCCESS_MESSAGE}`)
  };

  authCommand(command, callback, null);
};

export const getWithdrawRestriction = (callback: Function): void => {
  const command = {
    command: CommandNames.GET_CLIENT_RESTRICTIONS,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback);
};

export const getAutoidentLink = (callback: Function): void => {
  const command = {
    command: CommandNames.GET_TRUNARRATIVE_AUTOIDENT_LINK,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback);
};

export const getPlayerOptIns = (
  callback: Function,
  callbackError?: Function
): void => {
  const command = {
    command: CommandNames.GET_PLAYER_OPT_INS,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const acceptTermsConditions = (
  callback: Function,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames.ACCEPT_TERMS_CONDITIONS,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const notificationSubscriptionCommand = (
  hash: string,
  userId: string,
  isSubscribeToSMS: boolean,
  isSubscribeToemail: boolean,
  callback: Function = () => {},
  callbackError: Function = () => {}
): void => {
  const command = {
    command: CommandNames.UPDATE_CLIENT_NOTIFICATIONS,
    params: {
      hash: hash,
      user_id: userId,
      is_subscribe_to_sms: isSubscribeToSMS,
      is_subscribe_to_email: isSubscribeToemail
    },
    rid: RidGenerator.gForCommand()
  };

  connectingWS.then((socket: WebsocketEnhanced) =>
    socket.sendCommand(command, '', callback, null, callbackError)
  );
};

export const getClientPreCalculation = (
  callback: Function,
  errorCallback: Function
): void => {
  const command = {
    command: CommandNames.GET_CLIENT_PRE_CALCULATIONS,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, () => {}, errorCallback);
};

export const getSalaryOccupation = (
  callback: Function,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames.GET_SALARY_OCCUPATION,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const getPaymentWallets = (
  callback: (data: PaymentWalletResponse) => void,
  callbackError: (data: PaymentResponseError) => void
): void => {
  const command = {
    command: CommandNames.GET_PAYMENT_WALLETS,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const deletePlayerPaymentWallet = (
  id: number | null,
  callback: (data: PaymentDeleteWalletResponse) => void,
  callbackError: (data: PaymentResponseError) => void
): void => {
  const command = {
    command: CommandNames.DELETE_PLAYER_PAYMENT_WALLET,
    params: {
      id: id
    },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const updateTermsAndConditionsVersion = (
  version: number | string,
  callback: Function | null
): void => {
  const command = {
    command: CommandNames.TERMS_AND_CONDITIONS,
    params: { version },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, () => {
    callback && callback();
  });
};

export const getPartnerBonusesForRequest = (
  callback: (data: PartnerBonusesForRequestResponse) => void,
  callbackError: (data: PartnerBonusesForRequestResponse) => void
): void => {
  const command = {
    command: CommandNames.GET_PARTNER_BONUSES_FOR_REQUEST,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const createClientBonusRequest = (
  params: { partner_bonus_id: number; client_note: string },
  callback: (data: TClientBonusRequestResponse) => void,
  callbackError: (data: TClientBonusRequestResponse) => void
): void => {
  const command = {
    command: CommandNames.CREATE_CLIENT_BONUS_REQUEST,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const getClientBonusRequests = (
  params: { from_date: number; to_date: number },
  callback: (data: TClientBonusesResponse) => void,
  callbackError: (data: TClientBonusesResponse) => void
): void => {
  const command = {
    command: CommandNames.GET_CLIENT_BONUS_REQUESTS,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const getLimitsHistory = (
  params: { from_date: number; to_date: number },
  callback: (data: TLimitsHistoryResponse) => void,
  callbackError: (data: TLimitsHistoryResponse) => void
): void => {
  const command = {
    command: CommandNames.GET_CLIENT_LIMIT_CHANGE_HISTORY,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const remainLoggedIn = (
  callback: (data: object) => void,
  callbackError: (data: object) => void
): void => {
  const command = {
    command: CommandNames.REMAIN_LOGGED_IN,
    params: {},
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const getBetShops = (
  callback: (data: any) => void,
  callbackError: (data: object) => void
): void => {
  const command = {
    command: CommandNames.GET_BET_SHOPS,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const getPartnerDocumentType = (callback: Function | null): void => {
  const command = {
    command: CommandNames.GET_PARTNER_DOCUMENT_TYPES,
    params: { filter_id: 1 },
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback);
};

export const getFreeSpinBonuses = (
  params: { max_rows: number; acceptance_type: number },
  callback: Function | null
): void => {
  const command = {
    command: CommandNames.GET_FREE_SPIN_BONUSES,
    params,
    rid: RidGenerator.gForCommand()
  };

  dataspotFunnelAction(EDataspotAction.CasinoFreeSpins);
  authCommand(command, callback);
};

export const getBonusDefinitionGames = (
  params: { bonus_id: number },
  callback: Function | null
): void => {
  const command = {
    command: CommandNames.GET_BONUS_DEFINITON_GAMES,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback);
};

export const verifyBankCardAccount = (
  params: { verification_type: number; redirect_url: string },
  callback: Function | null,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames.VERIFY_CARD_ACCOUNT,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const loginWithNemId = (
  params: {
    login: string;
    access_token: string;
    redirect_url: string;
    nonce: string;
  },
  callback: Function | null,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames.LOGIN_WITH_NEM_ID,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const getClientSelfExclusionSchedules = (
  params: {
    schedules: Array<{ schedule_type: number }>;
  },
  callback: Function | null,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames.GET_CLIENT_SELF_EXCLUSION_SCHEDULES,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const setClientSelfExclusionSchedules = (
  params: {
    days?: number;
    months?: number;
    years?: number;
    exc_type: number;
    schedules: Array<{
      schedule_type: number;
      week_day_from: number;
      week_day_to: number;
      time_from?: string;
      time_to?: string;
    }>;
  },
  callback: Function | null,
  callbackError: Function
): void => {
  const command = {
    command: CommandNames.SET_CLIENT_SELF_EXCLUSION,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};

export const convertFreeBetToMoney = (
  params: {
    client_bonus_id: number;
  },
  callback: (data: TCashbackConvertDataProps) => void,
  callbackError: (data: TCashbackConvertDataProps) => void
) => {
  const command = {
    command: CommandNames.CONVERT_FREE_BET_TO_MONEY,
    params,
    rid: RidGenerator.gForCommand()
  };

  authCommand(command, callback, null, callbackError);
};
