import { RootState } from 'store/reducers';

type GetSelectorRootTypeOverload<K extends keyof RootState> = {
  <T extends keyof RootState[K], Y extends keyof NonNullable<RootState[K][T]>>(
    property: T,
    nestedProperty: Y
  ): (state: RootState) => NonNullable<RootState[K][T]>[Y];
  <T extends keyof RootState[K]>(property: T): (
    state: RootState
  ) => RootState[K][T];
};

export const getSelectorRoot = <K extends keyof RootState>(
  reducerName: K
): GetSelectorRootTypeOverload<K> => {
  const nestedFunction: GetSelectorRootTypeOverload<K> =
    <
      T extends keyof RootState[K],
      Y extends keyof NonNullable<RootState[K][T]>
    >(
      property: T,
      nestedProperty?: Y
    ) =>
    (
      state: RootState
    ): NonNullable<RootState[K][T]>[Y] | RootState[K][T] | undefined => {
      // @ts-ignore @Todo FIX_MY_TYPE add type here @todo-redux
      const stateProperty = state[reducerName][property];

      if (nestedProperty) {
        if (
          stateProperty == null ||
          stateProperty === undefined ||
          typeof stateProperty !== 'object'
        ) {
          return undefined;
        }

        return (stateProperty as NonNullable<RootState[K][T]>)[nestedProperty];
      } else {
        return stateProperty;
      }
    };

  return nestedFunction;
};
