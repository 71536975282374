import { ActionType, TShowNoItemWrapper, ValueOf } from 'interfaces/generic';
import { TMatchStatistic } from 'interfaces/match-statistic';
import {
  TAdvancedSelectedMarkets,
  TAdvancedSelectedRangeFilter,
  TGameMainInfoType,
  TGameMarketsInfoType,
  TSortingOption
} from 'interfaces/sportsbook';
import {
  EUcompetitionsFiltersType,
  IMenuGroupWithSports,
  Region,
  SelectedSport,
  Sport,
  SportOnly,
  TEUOddFilter,
  TUserSportOrder
} from 'interfaces/sportsbook-data-levels';
import { NewSportsbookActionTypes } from 'store/action-types/new-sportsbook';

export const setSportsData = (
  data: SportOnly[] | null | undefined
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SPORTS_DATA,
    payload: data
  };
};

export const setSportsGroupingData = (
  data: Partial<Record<'live' | 'prematch', IMenuGroupWithSports[] | null>>
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SPORTS_GROUPING_DATA,
    payload: data
  };
};

export const toggleGroupItem = (id: number, type: string): ActionType => {
  return {
    type: NewSportsbookActionTypes.TOGGLE_GROUP_ITEM,
    payload: { id, type }
  };
};

export const setSportRegionCompData = (
  data: Record<string, Sport> | null,
  replaceData?: boolean
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SPORT_REGION_COMP_DATA,
    payload: { data, replaceData }
  };
};

export const setSelectedCompetitions = (data: {
  data: Array<number> | null;
  key: string;
}): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SELECTED_COMPETITIONS,
    payload: data
  };
};

export const setGamesByCompetitions = (data: Sport | null): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_GAMES_BY_COMPETITIONS_DATA,
    payload: data
  };
};

export const setEUcompetitionsFilters = (
  data: Record<string, ValueOf<EUcompetitionsFiltersType>>
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_EU_COMPETITIONS_FILTERS,
    payload: data
  };
};

export const setEUOddFilter = (data: TEUOddFilter): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_EU_ODD_FILTER,
    payload: data
  };
};

export const setSwarmResponse = (
  payload: Record<string, Record<string, Sport>> | null
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SWARM_RESPONSE,
    payload
  };
};

export const setRegionCompData = (
  data: Record<string, Record<string, Region> | null> | null,
  replaceData?: boolean
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_REGION_COMP_DATA,
    payload: { data, replaceData }
  };
};

export const setSingleGameData = (payload: {
  id: string;
  data: { sport: Record<string, Sport> };
}): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SINGLE_GAME_DATA,
    payload
  };
};

export const setGames = (
  data: Record<string, Record<string, Sport>> | null
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_GAMES,
    payload: data
  };
};

export const setMarketsCount = (
  data: Record<string, Record<string, number>>
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_MARKETS_COUNT,
    payload: data
  };
};

export const setHasSportStreamingGame = (data: boolean): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_HAS_SPORT_STREAMING_GAME,
    payload: data
  };
};

export const setShowStreamingGamesOnly = (data: boolean): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SHOW_STREAMING_GAMES_ONLY,
    payload: data
  };
};

export const setMyBetsOpen = (payload: boolean): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_MY_BETS_OPEN,
    payload
  };
};

export const setUserSportOrder = (
  data: Record<string, TUserSportOrder> | null
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_USER_SPORT_ORDER,
    payload: data
  };
};

export const setGamesSortingOption = (data: TSortingOption): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_GAMES_SORTING_OPTION,
    payload: data
  };
};

export const setMatchStatistics = (
  data: Record<string, TMatchStatistic>
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_MATCH_STATISTICS,
    payload: data
  };
};

export const setShowNoItemWrapper = (
  payload: TShowNoItemWrapper
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SHOW_NO_ITEM_WRAPPER,
    payload
  };
};

export const setSportsbookSelectedSport = (
  payload: SelectedSport | null
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SPORTSBOOK_SELECTED_SPORT,
    payload
  };
};

export const setNavigationWrapperHeightChanged = (): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_NAVIGATION_WRAPPER_HEIGHT_CHANGED
  };
};

export const setAdvancedTempCompetitionIds = (
  payload: string[]
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_TEMP_COMPETITION_IDS,
    payload
  };
};

export const setAdvancedSportsForMarketsTypes = (
  payload: string
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_SPORTS_FOR_MARKETS_TYPES,
    payload
  };
};

export const resetAdvancedSportsForMarketsTypes = (): ActionType => {
  return {
    type: NewSportsbookActionTypes.RESET_ADVANCED_SPORTS_FOR_MARKETS_TYPES
  };
};

export const setAdvancedEventsLayoutGameIds = ({
  data,
  key
}: {
  data: Record<string, number[]>;
  key: string;
}): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_EVENTS_LAYOUT_GAME_IDS,
    payload: { data, key }
  };
};

export const setAdvancedGamesMainInfo = ({
  data,
  key
}: {
  data: Record<string, TGameMainInfoType>;
  key: string;
}): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_GAMES_MAIN_INFO,
    payload: { data, key }
  };
};

export const setAdvancedGamesMarketsInfo = ({
  data,
  key
}: {
  data: Record<string, { market: TGameMarketsInfoType }>;
  key: string;
}): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_GAMES_MARKETS_INFO,
    payload: { data, key }
  };
};

export const setAdvancedSelectedMarkets = ({
  data,
  key
}: {
  data: TAdvancedSelectedMarkets;
  key: string;
}): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_SELECTED_MARKETS,
    payload: { data, key }
  };
};

export const setAdvancedEventsLayoutSports = ({
  data,
  key
}: {
  data: Sport[];
  key: string;
}): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_EVENTS_LAYOUT_SPORTS,
    payload: { data, key }
  };
};

export const setAdvancedSelectedRangeFilter = (
  payload: TAdvancedSelectedRangeFilter
): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_SELECTED_RANGE_FILTER,
    payload
  };
};

export const setAdvancedLiveSports = (payload: Record<string, Sport>) => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_LIVE_SPORTS,
    payload
  };
};

export const setAdvancedLiveGames = (
  payload: Record<string, Record<string, Region>>
) => {
  return {
    type: NewSportsbookActionTypes.SET_ADVANCED_LIVE_GAMES,
    payload
  };
};

export const setSportsSortingOption = (data: TSortingOption): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_SPORTS_SORTING_OPTION,
    payload: data
  };
};

export const setRegionsSortingOption = (data: TSortingOption): ActionType => {
  return {
    type: NewSportsbookActionTypes.SET_REGIONS_SORTING_OPTION,
    payload: data
  };
};
