import { useMemo } from 'react';
import { SPORTSBOOK_VIEW_TYPES } from 'utils/constants/sportsbook/generic';
import { ALL_SPORTS_TYPES } from 'utils/constants/sportsbook/sportsbook-data-hierarchy';
import {
  ESportsbookLayoutTypes,
  TSportsbookConfigs
} from 'interfaces/sportsbook-configs';

const pageTypes: Record<string, number> = {
  pageLive: 0,
  pagePrematch: 1,
  pageESport: 2,
  pagePrematchEuropean: 1,
  pageLiveEuropean: 0
};

const layoutTypeByPageLayout: Record<string, ESportsbookLayoutTypes> = {
  pagePrematchEuropean: ESportsbookLayoutTypes.EUROPEAN,
  pageLiveEuropean: ESportsbookLayoutTypes.EUROPEAN,
  pageLive: ESportsbookLayoutTypes.CLASSIC,
  pagePrematch: ESportsbookLayoutTypes.CLASSIC
};

export const useWidgetConfigs = (): Partial<
  Omit<TSportsbookConfigs, 'fit' | 'category'>
> => {
  return useMemo(() => {
    const pageParams = window?.partnerConfigs?.pageParams;
    const pageType = pageParams?.pageType
      ? pageTypes[pageParams?.pageType]
      : undefined;

    if (pageParams && pageType !== undefined) {
      return {
        pageType,
        spbLayoutType:
          pageParams.pageLayout || layoutTypeByPageLayout[pageParams.pageType],
        sportType: JSON.stringify(pageParams.sportType || ALL_SPORTS_TYPES),
        type: SPORTSBOOK_VIEW_TYPES[pageType],
        favoriteTeam: pageParams.favoriteTeam ?? false,
        changeFavoriteTeam: pageParams.changeFavoriteTeam ?? false,
        fixedScroll: pageParams.fixedScroll ?? false,
        jackpotForSportsbook: pageParams.jackpotForSportsbook ?? false
      };
    }

    return {};
  }, []);
};
