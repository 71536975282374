import { createSelector } from 'reselect';
import { getSelectorRoot } from 'utils/selectors';
import { EBoostType } from 'store/reducers/sport-data';

const getSportData = getSelectorRoot('sportData');

export const getMultipleSelections = getSportData('multipleSelections');
export const getCashedSport = getSportData('cashedSport');
export const getCashedGame = getSportData('cashedGame');
export const getStreamData = getSportData('streamData');
export const getStreamChannels = getSportData('streamChannels');
export const getMarketTypes = getSportData('marketTypes');
export const getMarketType = getSportData('marketType');
export const getBoostedOdds = getSportData('boostedOdds');
export const getBoostedOddsCalled = getSportData('boostedOddsCalled');
export const getHasTopLeagueGames = getSportData('hasTopLeagueGames');
export const getStreamDataLoading = getSportData('streamDataLoading');
export const getTimeFiltersData = getSportData('timeFilterOption');
export const getTimeFiltersDataObj = getSportData('timeFilterOptionObj');
export const getScrollToGameId = getSportData('scrollToGameId');
export const getScrollToCompetitionId = getSportData('scrollToCompetitionId');
export const getSportsListSportData = getSportData('sportsList');
export const getCalendarMarketTypes = getSportData('calendarMarketTypes');
export const getCalendarMarketType = getSportData('calendarMarketType');
export const getRegionMarkets = getSportData('regionViewAllMarkets');
export const getUserNotifications = getSportData('userNotifications');
export const getcalendarMarketInfoType = getSportData('calendarMarketInfoType');
export const getGamesCount = getSportData('gamesCount');
export const getGamesByCompetitions = getSportData('gamesByCompetitions');
export const getTournamentSportIds = getSportData('tournamentSportIds');
export const getCoupons = getSportData('coupons');
export const getClickFromFavorites = getSportData('clickFromFavorites');

export const getMultipleSelectionsCompIds = createSelector(
  [getMultipleSelections],
  multipleSelections => {
    let compIds: Set<number> | null = null;

    if (multipleSelections) {
      const selectionsData = Object.values(multipleSelections).map(
        item => item.Selections
      );

      compIds = new Set();

      selectionsData.forEach(selection =>
        selection.forEach(item => compIds?.add(item.CompetitionId))
      );
    }

    return compIds;
  }
);

export const getBoostedOddsIds = createSelector(
  [getBoostedOdds],
  boostedOddsData =>
    boostedOddsData &&
    Object.keys(boostedOddsData).map(gameId => Number(gameId))
);

export const getPrematchBoostedOddsIds = createSelector(
  [getBoostedOdds],
  boostedOddsData =>
    boostedOddsData &&
    Object.keys(boostedOddsData)
      .map(gameId => Number(gameId))
      .filter(gameId =>
        Object.values(boostedOddsData[gameId]).some(({ type }) =>
          [EBoostType.ALL, EBoostType.PREMATCH].includes(type)
        )
      )
);
