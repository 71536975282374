export type Namespace =
  | 'betslip'
  | 'account'
  | 'sportsbook'
  | 'app'
  | 'casino'
  | 'esports'
  | 'events'
  | 'popularBets'
  | 'stories'
  | 'customPopup';

export type Key =
  | 'MUST_OPEN'
  | 'PREVIOUS_URL'
  | 'AUTH_DATA'
  | 'ACCEPT_BET'
  | 'BALANCE_VISIBILITY'
  | 'BETSLIP_TYPE'
  | 'EDIT_BET_ACTIVE'
  | 'ACTIVE_TIME_IN_CASINO'
  | 'FAVORITES'
  | 'RACING_DATE_AND_SPORT_FILTER_ACTIVE_TAB'
  | 'RACING_RESULTS_DATE_FILTER_ACTIVE_TAB'
  | 'FREEZE_START_TIME'
  | 'REALITY_CHECK_START_TIME'
  | 'LOGIN_LIMIT_START_TIME'
  | 'ODDS_FORMAT'
  | 'FAV_GROUP_IDS'
  | 'TYPE'
  | 'PAYMENT_DATA'
  | 'LOYALTY_POINTS_AVAILABLE'
  | 'JWT'
  | 'STREAM_ACTIVE_TAB_MODE'
  | 'BOOK_BET_ID'
  | 'FIRST_DEPOSIT'
  | 'THEME'
  | 'GAMES_FETCHED'
  | 'ACTIVE_TIME_FILTER'
  | 'DEPOSIT_LIMIT_REMINDER'
  | 'HOMEWORK'
  | 'EUROPEAN_SELECTED_LEAGUES'
  | 'ADD_TO_BETSLIP_DATA'
  | 'SELECTED_LEAGUES'
  | 'EU_SPORT_ALIAS'
  | 'GAME_SINGLE_VIEW_URL'
  | 'PARENT_ACCOUNT_CURRENCY'
  | 'DONT_SHOW_SWITCH_ACCOUNT_CURRENCY'
  | 'FROM_STATISTICS'
  | 'SESSION_VISIBILITY_REFRESH'
  | 'ADDITIONAL_INFO_POPUP'
  | 'REDIRECTED_FROM_FAVORITES'
  | 'DE_REALITY_CHECK'
  | 'PHONE_VERIFICATION_TIME'
  | 'EMAIL_VERIFICATION_TIME'
  | 'BETSLIP_SUSPENDED_EVENTS'
  | 'EU_LIVE_FAVORITES'
  | 'IDIN_TRANSACTION_ID'
  | 'IDIN_ENTRANCE_CODE'
  | 'IS_LOGOUT'
  | 'SWITCH_MULTI_ACCOUNT'
  | 'MULTIVIEW_BOARD'
  | 'CUSTOM_CODE_REG_AFTER_REFRESH'
  | 'CUSTOM_CODE_LOGIN_AFTER_REFRESH'
  | 'STAKE_AND_EARN_TAB'
  | 'LAYOUT_TYPE'
  | 'LOGIN_LIMIT_POPUP_START_TIME'
  | 'POPUP_OPEN'
  | 'HIDE_ZERO_BALANCES'
  | 'DISPLAY_CRYPTO_IN_FIAT'
  | 'SELECTED_CURRENCY_FOR_DISPLAY'
  | 'DEPOSIT_AMOUNT'
  | 'UNSIGNED_FAV_COMPETITION'
  | 'UNSIGNED_FAV_SPORT'
  | 'UNSIGNED_FAV_CASINO_GAME'
  | 'UNSIGNED_FAV_CASINO_PROVIDER'
  | 'UNSIGNED_FAV_MARKET'
  | 'UNSIGNED_FAV_SPORT_GAME'
  | 'OPEN_CASINO_GAME_WHEN_REFRESH'
  | 'OPEN_CHECK_EMAIL_MODAL'
  | 'SELECTED_GAME_URL'
  | 'REALITY_CHECK_ONE_HOUR_CHECK_TIMESTAMP'
  | 'SHOW_ADDITIONAL_INFO'
  | 'VIEWED_STORY_IDS'
  | 'SEARCH_SELCTED_SPORT';

export const PREFIX = 'x';
export const NAMESPACES: Record<Namespace, string> = {
  betslip: 'BETSLIP',
  account: 'ACCOUNT',
  sportsbook: 'SPORTSBOOK',
  app: 'APP',
  casino: 'CASINO',
  events: 'EVENTS',
  esports: 'ESPORTS',
  popularBets: 'POPULAR_BETS',
  stories: 'STORIES',
  customPopup: 'CUSTOM_POPUP'
};

export const KEY_NAMES: Record<Namespace, Record<Key | string, string>> = {
  app: {
    PREVIOUS_URL: 'previous_url',
    THEME: 'theme'
  },
  account: {
    AUTH_DATA: 'auth_data',
    BALANCE_VISIBILITY: 'balance_visibility',
    ACTIVE_TIME_IN_CASINO: 'active_time_in_casino',
    FREEZE_START_TIME: 'freeze_start_time',
    REALITY_CHECK_START_TIME: 'reality_check_start_time',
    LOGIN_LIMIT_START_TIME: 'login_limit_start_time',
    LOYALTY_POINTS_AVAILABLE: 'loyalty_points_available',
    PAYMENT_DATA: 'payment_data',
    JWT: 'jwt',
    FIRST_DEPOSIT: 'first_deposit',
    DEPOSIT_LIMIT_REMINDER: 'deposit_limit_reminder',
    PARENT_ACCOUNT_CURRENCY: 'parent_account_currency',
    DONT_SHOW_SWITCH_ACCOUNT_CURRENCY: 'dont_show_switch_account_currency',
    SESSION_VISIBILITY_REFRESH: 'session_visibility_refresh',
    CUSTOM_CODE_REG_AFTER_REFRESH: 'custom_code_reg_after_refresh',
    CUSTOM_CODE_LOGIN_AFTER_REFRESH: 'custom_code_login_after_refresh',
    DE_REALITY_CHECK: 'de_reality_check',
    ADDITIONAL_INFO_POPUP: 'additional_info_popup',
    PHONE_VERIFICATION_TIME: 'phone_verification_time',
    EMAIL_VERIFICATION_TIME: 'email_verification_time',
    IDIN_TRANSACTION_ID: 'idin_entrance_code',
    IDIN_ENTRANCE_CODE: 'idin_transaction_id',
    SWITCH_MULTI_ACCOUNT: 'switch_multi_account',
    STAKE_AND_EARN_TAB: 'stake_and_earn_tab',
    LOGIN_LIMIT_POPUP_START_TIME: 'login_limit_popup_start_time',
    POPUP_OPEN: 'popup_open',
    HIDE_ZERO_BALANCES: 'hide_zero_balances',
    DISPLAY_CRYPTO_IN_FIAT: 'display_crypto_in_fiat',
    SELECTED_CURRENCY_FOR_DISPLAY: 'selected_currency_for_display',
    DEPOSIT_AMOUNT: 'deposit_amount',
    UNSIGNED_FAV_COMPETITION: 'unsigned_fav_competition',
    UNSIGNED_FAV_SPORT: 'unsigned_fav_sport',
    UNSIGNED_FAV_CASINO_GAME: 'unsigned_fav_casino_game',
    UNSIGNED_FAV_MARKET: 'unsigned_fav_market',
    UNSIGNED_FAV_SPORT_GAME: 'unsigned_fav_sport_game',
    UNSIGNED_FAV_CASINO_PROVIDER: 'unsigned_fav_casino_provider',
    OPEN_CHECK_EMAIL_MODAL: 'open_check_email_modal',
    REALITY_CHECK_ONE_HOUR_CHECK_TIMESTAMP:
      'reality_check_one_hour_check_timestamp'
  },
  betslip: {
    MUST_OPEN: 'must_open',
    // BETSLIP_DATA: 'betslip_data',
    ACCEPT_BET: 'accept_bet',
    BETSLIP_TYPE: 'betslip_type',
    EDIT_BET_ACTIVE: 'edit_bet_active',
    ODDS_FORMAT: 'odds_format',
    TYPE: 'type',
    BOOK_BET_ID: 'BOOK_BET_ID',
    ADD_TO_BETSLIP_DATA: 'ADD_TO_BETSLIP_DATA',
    BETSLIP_SUSPENDED_EVENTS: 'suspended_events'
  },
  casino: {
    FAVORITES: 'favorites',
    GAMES_FETCHED: 'games_fetched',
    SELECTED_GAME_URL: 'selected_game_url',
    OPEN_CASINO_GAME_WHEN_REFRESH: 'open_casino_game_when_refresh'
  },
  sportsbook: {
    RACING_DATE_AND_SPORT_FILTER_ACTIVE_TAB:
      'racing_date_and_sport_filter_active_tab',
    RACING_RESULTS_DATE_FILTER_ACTIVE_TAB:
      'racing_results_date_filter_active_tab',
    FAVORITES: 'favorites',
    FAV_GROUP_IDS: 'fav_group_ids',
    STREAM_ACTIVE_TAB_MODE: 'stream_active_tab_mode',
    HOMEWORK: 'homework',
    EUROPEAN_SELECTED_LEAGUES: 'european_selected_leagues',
    EU_SPORT_ALIAS: 'eu_sport_alias',
    GAME_SINGLE_VIEW_URL: 'game_single_view_url',
    FROM_STATISTICS: 'from_statistics',
    EU_LIVE_FAVORITES: 'eu_live_favorites',
    MULTIVIEW_BOARD: 'multiview_board',
    LAYOUT_TYPE: 'layout_type',
    SEARCH_SELCTED_SPORT: 'search_selected_sport'
  },
  esports: {
    RACING_DATE_AND_SPORT_FILTER_ACTIVE_TAB:
      'racing_date_and_sport_filter_active_tab',
    REDIRECTED_FROM_FAVORITES: 'redirected_from_favorites',
    STREAM_ACTIVE_TAB_MODE: 'stream_active_tab_mode'
  },
  events: {
    ACTIVE_TIME_FILTER: 'active_time_filter'
  },
  popularBets: {
    SELECTED_LEAGUES: 'selected_leagues'
  },
  stories: {
    VIEWED_STORY_IDS: 'viewed_story_ids'
  },
  customPopup: {
    SHOW_ADDITIONAL_INFO: 'showAdditionalInfo'
  }
};
