import { FC } from 'react';
import { AuthSectionSkeleton } from 'elements/Accounts/_partials/Skeleton/AuthSectionSkeleton';
import { SportsListSkeleton } from 'elements/SportsList/HorizontalLayout/FallbackSkeleton';

export const ELEMENT_NAMES_FOR_CONTAINER_HEIGHT = [
  'MobileSportsbook',
  'VirtualSport',
  'DesktopSportsbook',
  'Esports',
  'TvGames',
  'RacingSport',
  'CasinoTournaments',
  'Calendar',
  'SportsList',
  'Results',
  'SkillGames',
  'PascalGaming',
  'PopularBets',
  'SportsbookLeftSidebar',
  'EuropeanPrematchLeft',
  'EsportLeftSideBar',
  'BetslipWidget'
];

export const ELEMENT_HEIGHT: Record<string, string> = {
  CasinoProviders: '44px',
  SearchElement: '40px',
  SportsbookTabSwitcher: '64'
};

export const ELEMENTS_WITH_SKELETON_FALLBACK: Record<string, FC<any>> = {
  Accounts: AuthSectionSkeleton,
  SportsList: SportsListSkeleton
};
