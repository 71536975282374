// event type in order to (listen to) or (fire) // Here we can use any type for payload
// eslint-disable-next-line @typescript-eslint/no-explicit-any

import { Subject } from 'rxjs';

export type TUseEventBusKey = string | number | symbol;

export type TUseEventBusHandler<PayloadType> = (data: {
  id?: string | symbol;
  payload: PayloadType;
}) => void;

export type TUseEventbusProps<
  KeyType extends TUseEventBusKey,
  PayloadType = any
> = Record<KeyType, TUseEventBusHandler<PayloadType>>;

export type EventbusEvent<PayloadType = any> = {
  // naming convention for key is: [scope].[action]
  // ex. CasinoEvent.CasinoGamesRendered
  key: keyof TUseEventbusProps<
    string | number | symbol,
    TUseEventBusHandler<PayloadType>
  >;
  id?: string | symbol;
  payload?: PayloadType;
};

// event bus Subject Observable

type TCleanEventBus = () => void;
type TFireEvent = (event: EventbusEvent) => void;
type TFireEvents = (events: EventbusEvent[]) => void;
type TInitEventBus = <KeyType extends TUseEventBusKey, PayloadType>(
  events?: TUseEventbusProps<KeyType, PayloadType>
) => TCleanEventBus | void;

const createEventBusInitializer = (
  subject: Subject<EventbusEvent>
): [
  initEventBus: TInitEventBus,
  fireEvet: TFireEvent,
  fireEvents: TFireEvents
] => {
  const fireEvent: TFireEvent = event => {
    subject.next(event);
  };

  const fireEvents: TFireEvents = events => {
    events.forEach(event => {
      subject.next(event);
    });
  };

  const initEventBus: TInitEventBus = events => {
    if (!events || !Object.values(events).length) {
      return;
    }

    // subscribe to the Subject Observable
    const subscription = subject.subscribe(({ key, id, payload }) => {
      if (key in events) {
        events[key as keyof typeof events]?.({ id, payload });
      }
    });

    // we should unsubscribe after component's unmount to remove extra subscribes
    return () => {
      subscription.unsubscribe();
    };
  };

  return [initEventBus, fireEvent, fireEvents];
};

export const EVENT_BUS_LOCAL_STORE: Record<
  string,
  {
    deps: any[];
    clean: () => void;
  }
> = {};

// @ts-ignore
window.EVENT_BUS_LOCAL_STORE = EVENT_BUS_LOCAL_STORE;

export const eventbusSubject = new Subject<EventbusEvent>();
// export const eventbusSubject = new Subject<EventbusEvent>();

const [initEventBus, fireEvent, fireEvents] =
  createEventBusInitializer(eventbusSubject);

export { initEventBus, fireEvent, fireEvents, createEventBusInitializer };
