// prettier-ignore
export const NewSportsbookActionTypes = {
  SET_GAMES_BY_COMPETITIONS_DATA: 'SET_GAMES_BY_COMPETITIONS_DATA',
  SET_SHOW_STREAMING_GAMES_ONLY: 'SET_SHOW_STREAMING_GAMES_ONLY',
  SET_HAS_SPORT_STREAMING_GAME: 'SET_HAS_SPORT_STREAMING_GAME',
  SET_EU_COMPETITIONS_FILTERS: 'SET_EU_COMPETITIONS_FILTERS',
  SET_EU_ODD_FILTER: 'SET_EU_ODD_FILTER',
  SET_SPORT_REGION_COMP_DATA: 'SET_SPORT_REGION_COMP_DATA',
  SET_SELECTED_COMPETITIONS: 'SET_SELECTED_COMPETITIONS',
  SET_SPORTS_GROUPING_DATA: 'SET_SPORTS_GROUPING_DATA',
  SET_REGION_COMP_DATA: 'SET_REGION_COMP_DATA',
  SET_SINGLE_GAME_DATA: 'SET_SINGLE_GAME_DATA',
  SET_USER_SPORT_ORDER: 'SET_USER_SPORT_ORDER',
  SET_SWARM_RESPONSE: 'SET_SWARM_RESPONSE',
  TOGGLE_GROUP_ITEM: 'TOGGLE_GROUP_ITEM',
  SET_MARKETS_COUNT: 'SET_MARKETS_COUNT',
  SET_MY_BETS_OPEN: 'SET_MY_BETS_OPEN',
  SET_SPORTS_DATA: 'SET_SPORTS_DATA',
  SET_GAMES: 'SET_GAMES',
  SET_GAMES_SORTING_OPTION: 'SET_GAMES_SORTING_OPTION',
  SET_MATCH_STATISTICS: 'SET_MATCH_STATISTICS',
  SET_NAVIGATION_WRAPPER_HEIGHT_CHANGED:
    'SET_NAVIGATION_WRAPPER_HEIGHT_CHANGED',
  SET_ADVANCED_EVENTS_LAYOUT_GAME_IDS: 'SET_ADVANCED_EVENTS_LAYOUT_GAME_IDS',
  SET_ADVANCED_SELECTED_RANGE_FILTER: 'SET_ADVANCED_SELECTED_RANGE_FILTER',
  SET_ADVANCED_EVENTS_LAYOUT_SPORTS: 'SET_ADVANCED_EVENTS_LAYOUT_SPORTS',
  SET_ADVANCED_TEMP_COMPETITION_IDS: 'SET_ADVANCED_TEMP_COMPETITION_IDS',
  SET_ADVANCED_SPORTS_FOR_MARKETS_TYPES:
    'SET_ADVANCED_SPORTS_FOR_MARKETS_TYPES',
  RESET_ADVANCED_SPORTS_FOR_MARKETS_TYPES:
    'RESET_ADVANCED_SPORTS_FOR_MARKETS_TYPES',
  SET_ADVANCED_GAMES_MARKETS_INFO: 'SET_ADVANCED_GAMES_MARKETS_INFO',
  SET_ADVANCED_SELECTED_MARKETS: 'SET_ADVANCED_SELECTED_MARKETS',
  SET_ADVANCED_GAMES_MAIN_INFO: 'SET_ADVANCED_GAMES_MAIN_INFO',
  SET_ADVANCED_LIVE_SPORTS: 'SET_ADVANCED_LIVE_SPORTS',
  SET_ADVANCED_LIVE_GAMES: 'SET_ADVANCED_LIVE_GAMES',
  SET_SHOW_NO_ITEM_WRAPPER: 'SET_SHOW_NO_ITEM_WRAPPER',
  SET_SPORTSBOOK_SELECTED_SPORT: 'SET_SPORTSBOOK_SELECTED_SPORT',
  SET_SPORTS_SORTING_OPTION: 'SET_SPORT_SORTING_OPTION',
  SET_REGIONS_SORTING_OPTION: 'SET_REGION_SORTING_OPTION'
};
