import { CLIENT_PRODUCT_STATE_CATEGORIES } from 'utils/constants/account/user-status';
import { CATEGORIES_WITH_SPORT } from 'utils/constants/sportsbook/sportsbook-data-hierarchy';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { isMobile } from 'utils/is-mobile';
import { fromDasherizedToAllCapitalized } from 'utils/string-manipulation/dasherized-to-all-capitalized';
import { splitByFirstOccurrence } from 'utils/string-manipulation/split-by-first-occurence';
import { ENotRealSports } from 'interfaces/not-real-sports';
import store from 'store';

export const getPageAliasFromUrl = (url: string): string => {
  url = decodeURI(url);
  const hashIndex = url.indexOf('?');

  if (hashIndex > -1) {
    url = url.slice(0, hashIndex);
  }

  const sportsbookIndex = url.indexOf(
    `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}/`
  );

  if (sportsbookIndex > -1) {
    url = url.slice(0, sportsbookIndex);
  }

  const casinoIndex = url.indexOf(`/${SpringConfigs.CASINO_MOUNT_PATH}/`);

  if (casinoIndex > -1) {
    url = url.slice(0, casinoIndex);
  }

  return url.replace(/\/$/, '');
};

export const isAreaRedirection = (
  url: string,
  stopPostMessage?: boolean
): {
  hasRedirect: boolean;
  destinationPageArea?: keyof typeof CLIENT_PRODUCT_STATE_CATEGORIES;
} => {
  //for remove duplicated double slashes
  url = decodeURI(url);

  url = url.replace(/([^:]\/)\/+/g, '$1');

  const isLoggedIn: boolean = store.getState().userData.isLoggedIn;

  if (
    !isLoggedIn ||
    !SpringConfigs.SHOW_GAMBLING_AREAS ||
    !SpringConfigs.GAMBLING_AREAS
  ) {
    return { hasRedirect: false };
  }

  if (
    SpringConfigs.SHOW_GAMBLING_AREAS &&
    SpringConfigs.GAMBLING_AREAS &&
    !SpringConfigs.MOCKED_DATA
  ) {
    const areas = SpringConfigs.GAMBLING_AREAS;
    const currentPageArea = getCurrentPageArea();
    let destinationPageArea = null;

    Object.keys(areas).forEach(area => {
      areas[area].forEach(page => {
        if (getPageAliasFromUrl(url) === getPageAliasFromUrl(page)) {
          destinationPageArea = area;
        }
      });
    });

    if (
      currentPageArea &&
      destinationPageArea &&
      destinationPageArea !== getPageAliasFromUrl(currentPageArea)
    ) {
      !stopPostMessage &&
        window.postMessage(
          { action: 'gambling_area_changed', destinationUrl: url },
          '*'
        );

      return { hasRedirect: true, destinationPageArea };
    } else {
      return { hasRedirect: false };
    }
  }

  return { hasRedirect: true };
};

export const getMountPath = (path: string, initial = false): string => {
  const regex = /\/:.*/i;

  return `${path.replace(regex, '').replace(/\/$/, '')}${
    initial ? `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}` : ''
  }`;
};

/**
 * @description
 * This function is used to get the current page area
 * @returns {string | [boolean, string]} - returns the current page area or a tuple with a boolean and the current page area
 * @param {string} path - the current path
 * @param {boolean | undefined} returnShouldRedirect - if true, it will return a tuple with a boolean and the current page area
 * @param {boolean | undefined} isCasino - if true, it will return the current page area for the casino
 * @param {boolean | undefined} noCasinoMountPath - if true, it will return the current page area for the casino without the month path
 * **/
export function mountPoint<B extends boolean>(
  path: string,
  returnShouldRedirect?: B,
  isCasino?: boolean,
  noCasinoMountPath?: boolean
): B extends true ? [boolean, string] : string;

export function mountPoint(
  path: string,
  returnShouldRedirect = false,
  isCasino = false,
  noCasinoMountPath = false
): [boolean, string] | string {
  const mountPrefix = isCasino
    ? !noCasinoMountPath
      ? SpringConfigs.CASINO_MOUNT_PATH
      : ''
    : `${SpringConfigs.SPORTSBOOK_MOUNT_PATH}`;

  const regex = new RegExp(`/${mountPrefix}(?![-a-zA-Z0-9]).*$`);
  const match = path.match(regex);

  if (match?.length) {
    const indexToCut = path.lastIndexOf(<string>match.pop());
    const mountPath = path.slice(0, indexToCut);
    const finalPath = `${mountPath}/${mountPrefix}`.replace(/(\/)\/+/g, '$1');

    return returnShouldRedirect ? [false, finalPath] : finalPath;
  }

  let finalPath = `${path}/${mountPrefix}`.replace(/(\/)\/+/g, '$1');

  if (isCasino) {
    finalPath = finalPath.replace(/\/$/, '');
  }

  return returnShouldRedirect
    ? [!match, `${finalPath}${isCasino ? window.location.search : ''}`]
    : finalPath;
}

export const sbPathFromObj = (
  sbObj: Partial<Record<'sport' | 'region' | 'competition' | 'game', string>>
): string => {
  return `${sbObj.sport ? `/${sbObj.sport}` : ''}${
    sbObj.region ? `/${sbObj.region}` : ''
  }${sbObj.competition ? `/${sbObj.competition}` : ''}${
    sbObj.game ? `/${sbObj.game}` : ''
  }`;
};

export const generateSbPathWithParams = (mainRoute: string): string => {
  const categoriesMatch = CATEGORIES_WITH_SPORT.join('|');
  const reg = new RegExp(
    `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}/(${categoriesMatch})`,
    'gi'
  );

  const isCategoryPresent = window.getPathname().search(reg) > -1;

  let returnPath: string;

  if (isCategoryPresent) {
    if (
      window.getPathname().includes(ENotRealSports.team) &&
      mainRoute.includes(':competition') &&
      isMobile()
    ) {
      mainRoute = mainRoute.replace(':competition', ':competition?');
    }

    returnPath = `${mountPoint(window.getPathname())}/:category${mainRoute}`;
  } else {
    returnPath = `${mountPoint(window.getPathname())}${mainRoute}`;
  }

  return returnPath;
};

export const reactRenderingRoute = (path: string): string => {
  const regex = new RegExp(`/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}.*$`);
  const match = path.match(regex);

  if (match?.length) {
    const indexToCut = path.lastIndexOf(<string>match.pop());

    return path.slice(indexToCut);
  }

  return path;
};

export const shouldRedirect = (path: string): boolean => {
  const pathWithoutParams = path.replace(/\?.*$/, '').replace(/\/$/, '');
  const urlWithoutParams = decodeURIComponent(
    window.location.href.replace(/\?.*$/, '').replace(/\/$/, '')
  );

  const mountPathRegex = new RegExp(
    `/(${SpringConfigs.CASINO_MOUNT_PATH}|${SpringConfigs.SPORTSBOOK_MOUNT_PATH})(/.+|)`
  );

  const [cleanPath] = pathWithoutParams.split(mountPathRegex);
  const [cleanUrl] = urlWithoutParams.split(mountPathRegex);

  return cleanPath !== (cleanUrl || '');
};

export const pageUrl = (url: string): string => {
  return url.slice(window.origin.length);
};

export const getCasinoParams = (): Record<
  | 'gameId'
  | 'gameExternalId'
  | 'gameName'
  | 'providerName'
  | 'categoryId'
  | 'gameCategoryId',
  string
> => {
  const isCasinoPath = window.location.pathname.includes(
    `/${SpringConfigs.CASINO_MOUNT_PATH}/`
  );

  const casinoParams: Record<string, string> = {};

  if (isCasinoPath) {
    const casinoMountPathLastIndex = window.location.pathname.lastIndexOf(
      SpringConfigs.CASINO_MOUNT_PATH
    );

    const casinoRelativePath =
      casinoMountPathLastIndex === -1
        ? ''
        : window.location.pathname.slice(
            casinoMountPathLastIndex +
              SpringConfigs.CASINO_MOUNT_PATH?.length +
              1
          );

    const [categoryData, gameCategoryData, providerData, gameData] =
      casinoRelativePath.split('/');

    if (gameData) {
      const [_gameId, externalIdWithName] = splitByFirstOccurrence(
        gameData,
        '-'
      );

      const [_externalId, _gameNameRaw] = splitByFirstOccurrence(
        externalIdWithName,
        '-'
      );

      casinoParams.gameId = _gameId;
      casinoParams.gameExternalId = _externalId;
      casinoParams.gameName = fromDasherizedToAllCapitalized(_gameNameRaw);
    }

    if (providerData) {
      casinoParams.providerName = fromDasherizedToAllCapitalized(providerData);
    }

    if (categoryData) {
      casinoParams.categoryId = categoryData;
    }

    if (gameCategoryData) {
      casinoParams.gameCategoryId = gameCategoryData;
    }
  } else {
    const queryParams = new URLSearchParams(window.location.search);

    casinoParams.gameExternalId = (queryParams.get('gameId') || '').toString();
  }

  return casinoParams;
};

export const sbRedirect = (
  url: string,
  changePathname = false,
  changeHref = false,
  lang?: string,
  target?: string
): void => {
  if (changePathname) {
    const destinationUrl = `${window.location.origin}${
      lang ? `/${lang}` : ''
    }/${url}`;

    const urlObj = new URL(destinationUrl);

    urlObj.search = '';

    if (!isAreaRedirection(destinationUrl).hasRedirect) {
      if (target === '_blank') {
        window.open(urlObj.toString(), target);
      } else {
        window.location.href = urlObj.toString();
      }
    }
  }

  if (changeHref) {
    if (!isAreaRedirection(url).hasRedirect) {
      if (target === '_blank') {
        window.open(url, target);
      } else {
        window.location.href = url;
      }
    }
  }
};

export const getCurrentPageArea = (): null | string => {
  let pageArea = null;

  if (
    SpringConfigs.SHOW_GAMBLING_AREAS &&
    SpringConfigs.GAMBLING_AREAS &&
    !SpringConfigs.MOCKED_DATA
  ) {
    const areas = SpringConfigs.GAMBLING_AREAS;
    Object.keys(areas).forEach(area => {
      areas[area].forEach(page => {
        if (
          getPageAliasFromUrl(window.location.href) ===
          getPageAliasFromUrl(page)
        ) {
          pageArea = area;
        }
      });
    });
  }

  return pageArea;
};

export const getWithLangMountPath = (
  path: string,
  lang?: string,
  initial = false
): string => {
  const regex = /\/:.*/i;

  let url = `${path.replace(regex, '').replace(/\/$/, '')}${
    initial ? `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}` : ''
  }`;

  if (lang) {
    url = `${url.slice(0, url.lastIndexOf('/'))}/${lang}${url.slice(
      url.lastIndexOf('/')
    )}`;
  }

  return url;
};
