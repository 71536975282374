import { ReactElement } from 'react';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { isMobile } from 'utils/is-mobile';
import { ESportsbookPageTypes } from 'interfaces/sportsbook-configs';
import { MenuUnderlineSkeletonDesktop } from './Desktop';
import { MenuUnderlineSkeletonMobile } from './Mobile';
import { useSportsbookConfigs } from 'hooks/sportsbook/useSportsbookConfigs';

export const MenuUnderlineSkeleton = (): ReactElement => {
  const configs = useSportsbookConfigs();
  const isLive = configs.pageType === ESportsbookPageTypes.live;

  return isMobile() ? (
    <MenuUnderlineSkeletonMobile />
  ) : !+SpringConfigs.LEFT_SIDE_BAR_SPORTS ||
    !+SpringConfigs.LEFT_SIDE_BAR ||
    isLive ||
    +configs.pageType === 2 ? (
    <MenuUnderlineSkeletonDesktop />
  ) : (
    <span></span>
  );
};
