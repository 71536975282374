import { RouteProps, useRouteMatch } from 'react-router-dom';
import { RouteMatchType } from 'interfaces/generic';

export const useSbRouteMatch = <
  Params extends { [K in keyof Params]?: string }
>(
  path?: string | string[] | RouteProps
): RouteMatchType => {
  return (useRouteMatch<Params>(path as NonNullable<typeof path>) || {
    url: '',
    path: '',
    params: {},
    isExact: false
  }) as RouteMatchType;
};
