import { createSelector } from 'reselect';
import { getSelectorRoot } from 'utils/selectors';

const getSportsbookStore = getSelectorRoot('newSportsbook');

export const getSportsData = getSportsbookStore('sportsData');
export const getSportGroupingsData = getSportsbookStore('sportsGroupingData');
export const getSportRegionCompData = getSportsbookStore('sportRegionCompData');
export const getRegionCompData = getSportsbookStore('regionCompData');
export const getSelectedCompetitions = getSportsbookStore(
  'selectedCompetitions'
);
export const getGamesByCompetitions = getSportsbookStore('gamesByCompetitions');
export const getEUcompetitionsFilters = getSportsbookStore(
  'euCompetitionsFilters'
);
export const getEUOddFilter = getSportsbookStore('euOddFilter');

export const getSwarmResponse = getSportsbookStore('swarmResponse');
export const getSingleGameData = getSportsbookStore('gamesSingleViewData');

export const getGames = getSportsbookStore('games');
export const getMarketsCount = getSportsbookStore('marketsCount');
export const getMyBetsOpen = getSportsbookStore('myBetsOpen');
export const getHasSportStreamingGame = getSportsbookStore(
  'hasSportStreamingGame'
);
export const getShowStreamingGamesOnly = getSportsbookStore(
  'showStreamingGamesOnly'
);
export const getUserSportOrder = getSportsbookStore('userSportOrder');

export const getGamesSortingOption = getSportsbookStore('gamesSortingOption');
export const getMatchStatistics = getSportsbookStore('matchStatistics');
export const getShowNoItemWrapper = getSportsbookStore('showNoItemWrapper');
export const getSportsbookSelectedSport = getSportsbookStore('selectedSport');
export const getNavigationWrapperHeightChanged = getSportsbookStore(
  'navigationWrapperHeightChangedTrigger'
);

export const getAdvancedTempCompetitionIds = getSportsbookStore(
  'advancedTempCompetitionIds'
);

export const getAdvancedSportsForMarketsTypes = getSportsbookStore(
  'advancedSportsForMarketsTypes'
);

export const getAdvancedEventsLayoutGameIds = getSportsbookStore(
  'advancedEventsLayoutGameIds'
);

export const getAdvancedGamesMainInfo = getSportsbookStore(
  'advancedGamesMainInfo'
);

export const getAdvancedGamesMarketsInfo = getSportsbookStore(
  'advancedGamesMarketsInfo'
);

export const getAdvancedSelectedMarkets = getSportsbookStore(
  'advancedSelectedMarkets'
);

export const getAdvancedEventsLayoutSports = getSportsbookStore(
  'advancedEventsLayoutSports'
);

export const getAdvancedSelectedRangeFilter = getSportsbookStore(
  'advancedSelectedRangeFilter'
);

export const getAdvancedLiveSports = getSportsbookStore('advancedLiveSports');
export const getAdvancedLiveGames = getSportsbookStore('advancedLiveGames');

export const getSportIdByAlias = (sportAlias: string) =>
  createSelector(
    [getSportsData],
    sportData =>
      sportData?.find(sport => sport.alias === sportAlias)?.id as number
  );
export const getSportsSortingOption = getSportsbookStore('sportsSortingOption');
export const getRegionsSortingOption = getSportsbookStore(
  'regionsSortingOption'
);
