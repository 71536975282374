import { useLocation } from 'react-router-dom';
import qs from 'qs';

export const useSearchParams = (
  search?: string
): Record<string, string | undefined> => {
  const history = useLocation();

  const searchParams = qs.parse(search || history.search, {
    ignoreQueryPrefix: true
  });

  return searchParams as Record<string, string | undefined>;
};
