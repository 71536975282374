import { Locale } from 'antd/lib/locale-provider';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { LOCALE_LANG_EXCEPTIONS } from 'utils/constants/languages/language-format';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { EDateDayMonthOrder } from 'interfaces/generic';

declare module 'dayjs' {
  interface Dayjs {
    format(template?: string, skipGlobalSetting?: boolean): string;
    // this method skips day/month ordering from spring configs
    formatWithoutGlobalConfigs(template?: string): string;
  }
}
const TMP_UNIQUE_STR = '^$@@$^';

export const configureDayjs = () => {
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(dayOfYear);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(localizedFormat);
};

export const LocaleCalendar: {
  datePickerLocale?: Locale;
} = {};

export const importDayJsLang = () => {
  const lang = document.documentElement.lang.slice(0, 2);
  import(`dayjs/locale/${lang}.js`).then(() => {
    dayjs.locale(lang);
  });

  const currentLanguage = window.currentLanguageObject?.language?.split('-');
  let formatedLang;

  if (currentLanguage) {
    formatedLang =
      LOCALE_LANG_EXCEPTIONS[currentLanguage[0]] ||
      `${currentLanguage[0]}_${currentLanguage[
        currentLanguage.length === 2 ? 1 : 0
      ].toUpperCase()}`;
  } else {
    formatedLang = 'en_US';
  }

  dayjs.extend((option, dayjsClass) => {
    const oldFormat = dayjsClass.prototype.format;
    dayjsClass.prototype.formatWithoutGlobalConfigs = oldFormat;

    dayjsClass.prototype.format = function (
      formatString,
      skipGlobalSetting = false
    ) {
      return skipGlobalSetting
        ? oldFormat.call(this, formatString)
        : oldFormat.call(this, normalizeDayJSFormat(formatString));
    };
  });

  import(`antd/lib/locale/${formatedLang}.js`).then(datePickerLocale => {
    LocaleCalendar.datePickerLocale = datePickerLocale.default;
  });
};

export const normalizeDayJSFormat = (format?: string) => {
  if (!format) {
    return;
  }

  const dayMatch = format.match(/D{1,2}/);
  const monthMatch = format.match(/M{1,4}/);

  const isNoMatches = !dayMatch || !monthMatch;
  const isAlreadyOrderKeep =
    (SpringConfigs.DATE_DAY_MONTH_ORDER === EDateDayMonthOrder.dayFirst &&
      (dayMatch?.index || 0) < (monthMatch?.index || 1)) ||
    (SpringConfigs.DATE_DAY_MONTH_ORDER === EDateDayMonthOrder.monthFirst &&
      (monthMatch?.index || 0) < (dayMatch?.index || 1));

  if (isNoMatches || isAlreadyOrderKeep) {
    return format;
  }

  return format
    .replace(dayMatch[0], TMP_UNIQUE_STR)
    .replace(monthMatch[0], dayMatch[0])
    .replace(TMP_UNIQUE_STR, monthMatch[0]);
};
