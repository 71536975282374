import { ReactElement } from 'react';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import './index.less';

const SPORTSBOOK_EVENT_COLLAPSE_SKELETON_COUNT = 20;

export const MobileSportsbookEventCollapseSkeleton = ({
  count
}: {
  count?: number;
}): ReactElement => {
  return (
    <>
      {[...Array(count || SPORTSBOOK_EVENT_COLLAPSE_SKELETON_COUNT).keys()].map(
        item => (
          <div key={item} className="MobileSportsbookEventCollapseSkeleton">
            <Skeleton
              avatar={{ shape: 'circle', size: 24 }}
              title={{
                style: { margin: 0, height: 20 }
              }}
              paragraph={false}
              active
            />
          </div>
        )
      )}
    </>
  );
};
