import { PMU_HORSE_RACING } from 'utils/constants/sportsbook/racing-sport';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { CommandNames } from 'utils/constants/swarm/swarm-command-names';
import RidGenerator from 'utils/swarm/rid-generator';
import { MarketTypeResponce } from 'interfaces/bet-data';
import { CountSetter } from 'interfaces/generic';
import { TSportListGroupingCB } from 'interfaces/sportsbook';
import { ISportMenuGroup } from 'interfaces/sportsbook-data-levels';
import { executeCommand } from 'services/get-data-level';
import { sendCommand, swarmCommand } from 'services/get-swarm-data';

export const getSportsbookGamesCount = (
  isLive: boolean,
  countSetter: CountSetter
): string => {
  const rid = RidGenerator.gForSubscribe();
  const relations = {
    game: '@count'
  };

  const where = {
    game: {
      type: isLive
        ? 1
        : {
            '@in': [0, 2]
          }
    },
    sport: {
      type: {
        '@ne': 1
      },
      id: {
        '@nin': [PMU_HORSE_RACING]
      }
    }
  };

  executeCommand(
    rid,
    relations,
    where,
    (data: { game: number }) => {
      countSetter(data.game);
    },
    true,
    (data: { game: number }) => {
      countSetter(data.game);
    }
  );

  return rid;
};

export const getESportsGamesCount = (
  isLive: boolean,
  countSetter: CountSetter,
  currentSport: string,
  isAll?: boolean
): string => {
  const rid = RidGenerator.gForSubscribe();
  const relations = {
    game: '@count'
  };

  const where = {
    game: isAll
      ? {
          type: {
            '@in': [0, 1, 2]
          }
        }
      : isLive
      ? { type: { '@in': [1] } }
      : {
          '@or': [
            { type: { '@in': [0, 2] } },
            { visible_in_prematch: 1, type: 1 }
          ]
        },
    sport:
      currentSport === 'AllESports'
        ? {
            type: 0
          }
        : {
            type: 0,
            alias: currentSport
          }
  };

  executeCommand(
    rid,
    relations,
    where,
    (data: { game: number }) => {
      countSetter(data.game);
    },
    true,
    (data: { game: number }) => {
      countSetter(data.game);
    }
  );

  return rid;
};

export const getRacingGamesCount = (
  sportId: number[],
  countSetter: CountSetter
): string | null => {
  if (SpringConfigs.MOCKED_DATA) {
    return null;
  }

  const rid = RidGenerator.gForSubscribe();
  const relations = {
    game: '@count'
  };

  const where = {
    sport: {
      id: {
        '@in': sportId
      }
    }
  };

  executeCommand(
    rid,
    relations,
    where,
    (data: { game: number }) => {
      countSetter(data.game);
    },
    true,
    (data: { game: number }) => {
      countSetter(data.game);
    }
  );

  return rid;
};

type CompetitionGameIdsType = {
  competition: {
    key: {
      id: number;
      game: {
        key: {
          id: number;
        };
      };
    };
  };
};

export const getCompetitionGameIds = (
  data: { type: string; competition: string | null; sport: string },
  callback: (data: CompetitionGameIdsType) => void
): string => {
  const rid = RidGenerator.gForSubscribe();

  const where: {
    sport: { alias: string };
    game: { type?: number | { '@in': number[] } };
    competition?: { id: number };
  } = {
    sport: { alias: data.sport },
    game: {}
  };

  if (data.type == 'all') {
    where.game.type = { '@in': [0, 1, 2] };
  } else if (data.type == 'live') {
    where.game.type = 1;
  } else {
    where.game.type = {
      '@in': [0, 2]
    };
  }

  if (data.competition) {
    where.competition = {
      id: Number(data.competition)
    };
  }

  executeCommand(
    rid,
    { game: ['id'], competition: ['id', 'game'], region: ['alias'] },
    where,
    (data: CompetitionGameIdsType) => {
      callback(data);
    },
    false
  );

  return rid;
};

export const getTopLeagueGamesCount = (
  callback: (data: { game: number }) => void
): string => {
  const rid = RidGenerator.gForSubscribe();

  const where = {
    competition: { favorite: true },
    game: {
      '@or': [{ type: { '@in': [0, 2] } }, { visible_in_prematch: 1, type: 1 }]
    }
  };

  executeCommand(rid, { game: '@count' }, where, (data: { game: number }) => {
    callback(data);
  });

  return rid;
};

export const getPredefinedMultiples = (
  callback: Function,
  errorCallback: Function
): void => {
  const command = {
    command: CommandNames.GET_PREDEFINED_MULTIPLES,
    params: { subscribe: false },
    rid: RidGenerator.gForCommand()
  };

  sendCommand(command, callback, null, errorCallback);
};

export const callMarketTypes = (
  params: { sport_alias: string },
  checker: boolean,
  callback: Function
): void => {
  if (!checker && params.sport_alias) {
    swarmCommand(
      CommandNames.GET_MARKET_TYPE,
      params,
      (data: MarketTypeResponce) => {
        if (!data.details && params.sport_alias) {
          return;
        }

        const marketData = data.details
          .sort((a, b) => a.Order - b.Order)
          .map(item => {
            return {
              label: item.MarketTypeName.replace(/\{sw\}|\{pw\}|\{s\}/g, ''),
              value: item.BasaltKind
            };
          });

        callback(marketData);
      }
    );
  }
};

export const getSportListGrouping = (
  successCB: TSportListGroupingCB,
  errorCB: TSportListGroupingCB
): void => {
  swarmCommand(
    CommandNames.GET_PARTNER_SPORT_GROUPS,
    {},
    (data: { details: ISportMenuGroup[] }) => {
      successCB(data.details);
    },
    null,
    () => {
      errorCB([]);
    }
  );
};
