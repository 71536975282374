import {
  CSSProperties,
  FC,
  forwardRef,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from 'react';
import cc from 'classcat';
import { EIconSize } from 'utils/constants/app/ui';
import { Extra, Header, Icon, Title, Wrapper } from './style';
import { EventAction } from 'components/EventAction';
import { GlobalIcon } from 'components/GlobalIcon';
import './index.less';

type TEventCollapseProps = {
  title?: string | ReactElement;
  extra?: string | ReactElement;
  size?: 'default' | 'middle';
  key?: number | string;
  icon?: ReactNode;
  defaultActive?: boolean;
  hideWrapper?: boolean;
  noMargin?: boolean;
  noRadius?: boolean;
  style?: CSSProperties;
  setCollapsedCount?: (arg: (num: number) => number) => void;
  wrapperClassName?: string;
  headerClassName?: string;
  onChange?: Function;
  collapsedCount?: number;
  renderInDom?: boolean;
  dataTestId?: string;
} & PropsWithChildren;

export const EventCollapse: FC<TEventCollapseProps> = forwardRef<
  HTMLDivElement,
  TEventCollapseProps
>((props, forwardedRef) => {
  const [active, setActive] = useState(!!props.defaultActive);

  useEffect(() => {
    setActive(!!props.defaultActive);
  }, [props.defaultActive]);

  return props.hideWrapper ? (
    props.children
  ) : (
    <Wrapper
      data-testid={props.dataTestId}
      ref={forwardedRef}
      size={props.size}
      isActive={active}
      $noMargin={props.noMargin}
      $noRadius={props.noRadius}
      {...(active && { className: 'active' })}
      {...(props.style && { style: props.style })}
      className={cc([
        props.wrapperClassName || 'v3-event-collapse',
        {
          'eventCollapse--active': active
        }
      ])}
    >
      {(!!props.icon || !!props.title || !!props.extra) && (
        <Header
          isActive={active}
          onClick={() => {
            props.onChange?.(!active);
            setActive(prev => !prev);
            props.setCollapsedCount &&
              props.setCollapsedCount(prev => prev + 1);
          }}
          className={cc([
            'v3-event-collapse__header',
            `${props.headerClassName || ''}`,
            {
              [`${props.headerClassName}--active`]:
                active && props.headerClassName,
              'v3-event-collapse__header-active': active
            }
          ])}
        >
          {!!props.icon && (
            <Icon className="eventCollapse__icon">{props.icon}</Icon>
          )}
          <Title
            className="eventCollapse__title"
            data-testid="event-title"
            title={typeof props.title === 'string' ? props.title : ''}
          >
            {props.title}
          </Title>
          {props.extra && (
            <Extra className="extra__container">{props.extra}</Extra>
          )}
          <EventAction
            size="sm"
            className="eventCollapse__caretIcon"
            ghost
            icon={
              <GlobalIcon
                lib="generic"
                name="caretDownSmall"
                theme="default"
                size={EIconSize._24}
                className={cc([
                  'eventCollapse__caret',
                  {
                    'eventCollapse__caret-expanded': active
                  }
                ])}
              />
            }
          />
        </Header>
      )}
      {props.renderInDom ? (
        <div
          className={cc([
            'navigationCollapse__body',
            {
              'navigationCollapse__body--open': active
            }
          ])}
        >
          {props.children}
        </div>
      ) : (
        active && props.children
      )}
    </Wrapper>
  );
});
