import SpringConfigs from '../constants/swarm/spring-configs';

const isWidgets = process.env.REACT_APP_ENV === 'widgets';

const normalizeName = (name?: string) => {
  return (
    name &&
    name
      .replace(/ /g, '-')
      .replace(/[(]/g, '')
      .replace(/[)]/g, '')
      .trim()
      .toLowerCase()
  );
};

export const createUrl = ({
  lib,
  theme,
  name
}: {
  lib: string;
  theme: string;
  name?: string;
}) => {
  const normalizedName = normalizeName(name);

  //console.log(SpringConfigs.ICON_EXTENSION);

  return `${
    isWidgets
      ? `${
          window.partnerConfigs?.chunksPublicPath ||
          window.partnerConfigs?.mainHostName ||
          'https://static.springbuilder.site/widgets-x/'
        }`
      : '/'
  }icons/${lib.toLowerCase()}/${theme}/${normalizedName}.${
    SpringConfigs.ICON_EXTENSION || 'svg'
  }?v=${SpringConfigs.VERSION}`;
};

export const createCustomUrl = ({
  lib,
  name,
  customIconsURL
}: {
  lib: string;
  name?: string;
  customIconsURL?: string;
}) => {
  const normalizedName = normalizeName(name);

  return `${customIconsURL}/${lib.toLowerCase()}/${normalizedName}.${
    SpringConfigs.ICON_EXTENSION || 'svg'
  }?v=${SpringConfigs.VERSION}`;
};
