import { useEffect, useRef } from 'react';

type ReturnType = {
  mounted: { current: boolean };
  mountCheckWrapper: (arg0: () => void) => void;
};

export const useMounted = (): ReturnType => {
  const mounted = useRef(false);

  const mountCheckWrapper = (func: () => void) => {
    if (!mounted.current) {
      return;
    }

    func();
  };

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    mounted,
    mountCheckWrapper
  };
};
