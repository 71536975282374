import { FC, SyntheticEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cc from 'classcat';
import localStorage from 'utils/bom-dom-manipulation/local-storage';
import { sortByOrder } from 'utils/collection-manipulation/array-sort-by';
import { EIconSize } from 'utils/constants/app/ui';
import {
  competitions,
  gameSportcastIdWhere,
  regions
} from 'utils/constants/sportsbook/data-fields';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import {
  getMountPath,
  mountPoint,
  pageUrl,
  shouldRedirect
} from 'utils/generic/route-manipulation';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { handleRedirectToSportsBook } from 'utils/sportsbook/handleRedirectToSportsBook';
import RidGenerator from 'utils/swarm/rid-generator';
import { ENotRealSports } from 'interfaces/not-real-sports';
import { Region, Sport } from 'interfaces/sportsbook-data-levels';
import { executeCommand } from 'services/get-data-level';
import { CompetitionsList } from './CompetitionsList';
import { SportListSkeleton } from './SportListSkeleton';
import { EventCollapse } from 'components/EventCollapse';
import { GlobalIcon } from 'components/GlobalIcon';
import { NavigationCollapse } from 'components/NavigationCollapse';
import { WrapperHandler } from 'components/WrapperHandler';
import { useMounted } from 'hooks/useMounted';
import { useSbRouteMatch } from 'newhooks/generic/useSbRouteMatch';
import {
  setClickFromFavorites,
  setCompetitionMetaData,
  setSportMetaData
} from 'store/actions';
import { setSportsListData } from 'store/actions/sport-data';
import {
  getClickFromFavorites,
  getScrollToCompetitionId,
  getSportsListSportData
} from 'store/selectors/sport-data';
import { MobileSportsbookEventCollapseSkeleton } from '../MobileSportsbookEventCollapseSkeleton';

type Props = {
  sport: Sport;
  compactView?: boolean;
  clickHandler?: (
    e: SyntheticEvent,
    regionAlias: string,
    competitionId: number,
    sportId: number,
    sportAlias: string
  ) => void;
  openFromSideBarPopup?: boolean;
};

const SPORT_LIST_SKELETON_COUNT = 5;

export const SportRegions: FC<Props> = ({
  sport,
  compactView,
  clickHandler,
  openFromSideBarPopup
}) => {
  const dispatch = useDispatch();
  const regionsData = useSelector(getSportsListSportData)?.[`${sport.alias}`];
  const scrollToCompetitionId = useSelector(getScrollToCompetitionId);
  const clickFromFavorites = useSelector(getClickFromFavorites);
  const history = useHistory();
  const routeParams = useSbRouteMatch(
    `${mountPoint(window.getPathname())}/:sport?/:region?/:competition?/:game?`
  );

  const { mounted } = useMounted();

  const isBetBuilder = routeParams?.params?.sport === ENotRealSports.betBuilder;

  useEffect(() => {
    dispatch(setSportMetaData({ name: sport.name }));

    return () => {
      dispatch(setCompetitionMetaData(undefined));
    };
  }, [sport.name]);

  useEffect(() => {
    if (
      !regionsData &&
      !SpringConfigs.MOCKED_DATA &&
      !SpringConfigs.RENDER_COMPETITIONS_IN_DOM
    ) {
      executeCommand(
        RidGenerator.gForCommand(),
        {
          region: regions.region,
          competition: competitions.competition
        },
        {
          sport: { id: sport.id },
          game: {
            '@or': [
              { type: { '@in': [0, 2] } },
              { visible_in_prematch: 1, type: 1 }
            ],
            ...(isBetBuilder ? { sportcast_id: gameSportcastIdWhere } : {})
          }
        },
        (data: { data: { region: Record<string, Region> } }) => {
          if (data?.data?.region && mounted.current) {
            dispatch(
              setSportsListData(
                sortByOrder(Object.values(data.data.region)),
                sport.alias
              )
            );
          }
        }
      );
    }
  }, [isBetBuilder]);

  const handleClick = useCallback(
    (e: SyntheticEvent, compId: number, regionAlias: string) => {
      if (clickHandler) {
        clickHandler(e, regionAlias, compId, Number(sport.id), sport.alias);
      } else {
        if (openFromSideBarPopup) {
          if (clickFromFavorites) {
            dispatch(setClickFromFavorites(false));
          }
        }

        if (sport.alias) {
          localStorage.setItem(
            storageKeyName('sportsbook', 'EU_SPORT_ALIAS'),
            sport.alias
          );
        }

        const pathname = getMountPath(
          routeParams?.path?.replace(/:sport\?.*$/, sport.alias) || ''
        );

        if (
          openFromSideBarPopup &&
          location.pathname.includes(
            `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}/`
          ) &&
          pathname &&
          document.querySelector('.prematch__type-page')
        ) {
          history.push(`${pathname}/${regionAlias}/${compId}`);
        } else if (
          !openFromSideBarPopup ||
          (openFromSideBarPopup &&
            shouldRedirect(SpringConfigs.PAGE_URLS.prematch || '/'))
        ) {
          handleRedirectToSportsBook(
            'prematch',
            regionAlias,
            compId,
            undefined,
            sport.alias,
            sport.type === 0
          );
        } else {
          history.push(
            `${pageUrl(SpringConfigs.PAGE_URLS['prematch'] || '')}/${
              SpringConfigs.SPORTSBOOK_MOUNT_PATH
            }/${sport.alias}/${regionAlias}/${compId}`
          );
        }
      }
    },
    [sport]
  );

  return (
    <div className={cc([{ prematch__sportsList__regions: !!compactView }])}>
      {regionsData?.length ? (
        regionsData.map((region, key) => (
          <WrapperHandler
            key={key}
            condition={!!compactView}
            positiveWrapper={children => (
              <EventCollapse
                key={region.id}
                title={region.name}
                icon={
                  <GlobalIcon
                    lib="flags"
                    name={region.alias || ''}
                    size={EIconSize._24}
                  />
                }
                wrapperClassName="prematch__sportsList__region__wrapper"
                headerClassName="prematch__sportsList__region__header"
                dataTestId="region"
                defaultActive={
                  !!scrollToCompetitionId &&
                  !!region.competition?.[scrollToCompetitionId?.id]
                }
                renderInDom={SpringConfigs.RENDER_COMPETITIONS_IN_DOM}
              >
                {children}
              </EventCollapse>
            )}
            negativeWrapper={children => (
              <NavigationCollapse
                key={region.id}
                title={region.name}
                icon={
                  <GlobalIcon
                    lib="flags"
                    name={region.alias || ''}
                    size={EIconSize._24}
                  />
                }
                wrapperClassName="sportsList__region"
                headerClassName="sportsList__region__header"
                renderInDom={SpringConfigs.RENDER_COMPETITIONS_IN_DOM}
              >
                {children}
              </NavigationCollapse>
            )}
          >
            <CompetitionsList
              competitions={sortByOrder(
                Object.values(region.competition || {})
              )}
              clickHandler={(e, compId: number) =>
                handleClick(e, compId, region.alias)
              }
              shouldShowAll={!compactView}
              regionAlias={region.alias}
              sportAlias={sport.alias}
            />
          </WrapperHandler>
        ))
      ) : compactView ? (
        <MobileSportsbookEventCollapseSkeleton count={15} />
      ) : (
        [...Array(SPORT_LIST_SKELETON_COUNT).keys()].map(key => (
          <SportListSkeleton key={key} lightBg />
        ))
      )}
    </div>
  );
};
