import { GROUP_ICONS_COUNT } from 'utils/constants/sportsbook/sport-list';
import {
  IMenuGroupWithSports,
  ISportMenuGroup,
  Sport,
  SportOnly
} from 'interfaces/sportsbook-data-levels';

export const modifyAndGetSportsAndGroupingsData = <
  ST extends Sport | SportOnly
>(
  sportsData: { [p: string]: ST } = {},
  groupingData: ISportMenuGroup[] = [],
  currentSport?: string
): [{ [p: string]: ST }, IMenuGroupWithSports[]] => {
  const groupsWithSports: IMenuGroupWithSports[] = groupingData.map(
    (group, i) => ({
      sports: [],
      gameCount: 0,
      isOpened: false,
      sportCouples: [],
      name: group.Name,
      id: group.GroupId,
      alias: group.Alias,
      sportIds: group.SportIds,
      icon: `group${(i % GROUP_ICONS_COUNT) + 1}`,
      order: group.Order || Infinity
    })
  );

  for (const group of groupsWithSports) {
    const actualSportIds = group.sportIds.filter(id => sportsData[id]);

    for (let i = 0; i < actualSportIds.length; i += 2) {
      const sportsCouple: (SportOnly | Sport)[] = [];

      const sportIdsCouple = [actualSportIds[i], actualSportIds[i + 1]];

      sportIdsCouple.map(id => {
        if (sportsData[id]) {
          group.gameCount += 1;
          sportsCouple.push(sportsData[id]);

          if (currentSport === sportsData[id].alias) {
            group.isOpened = true;
          }
        }
      });

      group.sports.push(...sportsCouple);
      group.sportCouples.push(sportsCouple);
    }
  }

  groupsWithSports.forEach(g => {
    g.sports.forEach(({ id }) => {
      delete sportsData[id as string];
    });
  });

  return [sportsData, groupsWithSports.filter(group => group.sports.length)];
};
