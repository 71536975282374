export enum ENotRealSports {
  topLeague = 'topLeague',
  boostedOdds = 'boostedOdds',
  todayEvents = 'todayEvents',
  topMatches = 'topMatches',
  multiples = 'multiples',
  outright = 'outright',
  AllESports = 'AllESports',
  all = 'all',
  favourites = 'favourites',
  team = 'team',
  myBets = 'myBets',
  betBuilder = 'betBuilder',
  coupon = 'coupon',
  olympicCompetition = 'olympicCompetition'
}
