import { ReactElement, ReactNode } from 'react';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import { offsetBadgeValue } from 'utils/constants/app/ui';

type Props = TooltipProps & {
  title: ReactNode;
  children: ReactElement;
  tooltipOnParent?: boolean;
};

export function WhiteTooltip({
  children,
  title,
  tooltipOnParent,
  ...restProps
}: Props): ReactElement {
  return (
    <Tooltip
      arrowPointAtCenter
      mouseEnterDelay={0.7}
      overlayClassName="v3-white-tooltip"
      color="var(--v3-black-0)"
      getPopupContainer={(triggerNode: HTMLElement): HTMLElement =>
        tooltipOnParent
          ? (triggerNode.parentNode as HTMLElement)
          : (document.body as HTMLElement)
      }
      title={
        <span
          style={{
            color: 'var(--v3-black-85)',
            whiteSpace: 'nowrap'
          }}
        >
          {title}
        </span>
      }
      align={{
        offset: [0, offsetBadgeValue.OFFSET_TYPE_VALUE_18]
      }}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
}
