import { ReactElement, ReactNode } from 'react';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

type Props = TooltipProps & {
  textColor?: string;
  title: ReactNode;
  children: ReactElement;
};

export const CustomTooltip = ({
  children,
  textColor,
  title,
  ...restProps
}: Props): ReactElement => {
  return (
    <Tooltip
      title={
        textColor ? <span style={{ color: textColor }}>{title}</span> : title
      }
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};
