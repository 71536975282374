import { FC } from 'react';
import { createArray } from 'utils/helpers/create-fake-array';
import { isMobile } from 'utils/is-mobile';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import './index.less';

type TProps = {
  hideFirstIcon?: boolean;
};

const TOP_LEAGUE_MOBILE_COUNT = 20;
const TOP_LEAGUE_COUNT = 10;

export const TopLeagueCompactLayoutSkeleton: FC<TProps> = ({
  hideFirstIcon
}) => {
  return (
    <div className="competitions-by-lists">
      <div className="competitions-by-lists__content">
        {createArray(
          isMobile() ? TOP_LEAGUE_MOBILE_COUNT : TOP_LEAGUE_COUNT
        ).map((_, i) => {
          return (
            <div className="competition-item-skeleton" key={i}>
              <div className="competition-item-skeleton__content">
                <div className="favoriteSportName">
                  {!hideFirstIcon ? (
                    <Skeleton
                      className="favorite"
                      active
                      avatar={{ size: 18 }}
                      title={false}
                      paragraph={false}
                    />
                  ) : null}

                  <Skeleton
                    className="sport"
                    active
                    avatar={{ size: 18 }}
                    title={false}
                    paragraph={false}
                  />
                  <Skeleton
                    className="name"
                    active
                    title={false}
                    paragraph={false}
                  />
                </div>
                <Skeleton
                  className="region"
                  active
                  avatar={{ size: 18 }}
                  title={false}
                  paragraph={false}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
