import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EIconSize } from 'utils/constants/app/ui';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { getMountPath, mountPoint } from 'utils/generic/route-manipulation';
import { ENotRealSports } from 'interfaces/not-real-sports';
import {
  ETopLeagueLayoutType,
  ETopMatchesLayoutType
} from 'interfaces/sportsbook-configs';
import { Sport } from 'interfaces/sportsbook-data-levels';
import { GlobalIcon } from 'components/GlobalIcon';
import { useBoostedOdds } from 'hooks/sportsbook/useBoostedOdds';
import { useConditionalTooltip } from 'newhooks/generic/useConditionalTooltip';
import { useSbRouteMatch } from 'newhooks/generic/useSbRouteMatch';
import { setClickFromFavorites } from 'store/actions';
import { getClickFromFavorites } from 'store/selectors/sport-data';
import './index.less';

type Props = {
  handleClickEventItem: (arg: string, sportType?: number) => void;
  compactView?: boolean;
  configs?: {
    boostedOddsEnabled?: boolean;
    topLeagueEnabled?: boolean;
    topMatchesEnabled?: boolean;
    olympicCompetitionEnabled?: boolean;
    outRightEnabled?: boolean;
    todayEventsEnabled?: boolean;
    multiplesEnabled?: boolean;
    BBSportsList?: boolean;
    sportType: string;
    couponBuilderSportsList?: boolean;
  };
  sportsData?: (
    | { alias?: string; id: number; name: string; type?: number }
    | Sport
  )[];
  open: boolean;
};

if (SpringConfigs.IS_RTL) {
  import('./rtl.less');
}

const getDefaultSelectedNotRealSport = () => {
  if (
    SpringConfigs.SPORT_MENU_DEFAULT_SELECTED === 'topLeagues' &&
    SpringConfigs.TOP_LEAGUES &&
    SpringConfigs.TOP_LEAGUES_LAYOUT === ETopLeagueLayoutType.expanded
  ) {
    return ENotRealSports.topLeague;
  }

  if (SpringConfigs.SPORT_MENU_DEFAULT_SELECTED === 'todayEvents') {
    return ENotRealSports.todayEvents;
  } else {
    return SpringConfigs.SPORT_MENU_DEFAULT_SELECTED;
  }
};

export const CryptoSideBarSportList: FC<Props> = ({
  handleClickEventItem,
  sportsData,
  configs,
  open
}) => {
  const history = useHistory();
  useBoostedOdds(!!configs?.boostedOddsEnabled);
  const { t } = useTranslation();
  const clickFromFavorites = useSelector(getClickFromFavorites);
  const dispatch = useDispatch();

  const routeParams = useSbRouteMatch(
    `${mountPoint(window.getPathname())}/:sport?`
  );

  const pathname = history.location.pathname;

  const prematchPrefix =
    SpringConfigs.PAGE_URLS.prematch?.replace(
      `${window.location.origin}/`,
      ''
    ) || 'prematch';

  const isPrematch = pathname.includes(prematchPrefix);

  const shouldSelectNotRealSportByDefault = (function () {
    const isSportsbookMountPath =
      `${window.location.origin}${window.getPathname()}` ===
      `${SpringConfigs.PAGE_URLS.prematch}/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}`;

    const isTopLeagues =
      SpringConfigs.SPORT_MENU_DEFAULT_SELECTED === 'topLeagues';

    const isTopMatches =
      SpringConfigs.SPORT_MENU_DEFAULT_SELECTED === 'topMatches';

    let result = false;

    if (SpringConfigs.SPORT_MENU_DEFAULT_SELECTED !== 'sportsTypes') {
      result = true;
    }

    if (
      isTopLeagues &&
      SpringConfigs.TOP_LEAGUES_LAYOUT === ETopLeagueLayoutType.compact
    ) {
      result = false;
    }

    if (
      isTopMatches &&
      SpringConfigs.TOP_MATCHES_LAYOUT === ETopMatchesLayoutType.compact
    ) {
      result = false;
    }

    if (!isSportsbookMountPath) {
      result = false;
    }

    return result;
  })();

  const activeRealSport =
    routeParams?.params?.sport ||
    (document.querySelector('.sportsbookWrapper') &&
      sportsData &&
      sportsData[0]?.alias) ||
    '';

  const activeSport = shouldSelectNotRealSportByDefault
    ? getDefaultSelectedNotRealSport()
    : activeRealSport;

  useEffect(() => {
    const pathname = getMountPath(
      routeParams?.path?.replace(/:sport\?.*$/, activeSport) || ''
    );

    if (!routeParams?.params?.sport && activeSport) {
      history.push(
        `${mountPoint(pathname)}/${activeSport}${history.location.search}`
      );
    }
  }, [activeSport]);

  const handleClickSideBarItem = useCallback(
    (selected: string, sportType?: number) => {
      if (`/${activeSport}` === selected && isPrematch) {
        return;
      }

      if (clickFromFavorites) {
        dispatch(setClickFromFavorites(false));
      }

      handleClickEventItem(selected, sportType);
    },
    [activeSport, handleClickEventItem, isPrematch]
  );

  const Tooltip = useConditionalTooltip(
    {
      placement: 'right',
      trigger: ['hover']
    },
    !open
  );

  return (
    <div className="sportsList">
      {configs?.topLeagueEnabled && (
        <Tooltip title={t(`sportsbook.${ENotRealSports.topLeague}`)}>
          <GlobalIcon
            lib="sports"
            name={ENotRealSports.topLeague}
            size={EIconSize._32}
            style={{ marginRight: 8 }}
            className={
              activeSport === ENotRealSports.topLeague && isPrematch
                ? 'leftSideBarSport--active'
                : ''
            }
            onClick={() => {
              handleClickSideBarItem(`/${ENotRealSports.topLeague}`);
            }}
          />
        </Tooltip>
      )}
      {configs?.outRightEnabled && (
        <Tooltip title={t(`sportsbook.${ENotRealSports.outright}`)}>
          <GlobalIcon
            lib="sports"
            name={ENotRealSports.outright}
            size={EIconSize._32}
            style={{ marginRight: 8 }}
            className={
              activeSport === ENotRealSports.outright && isPrematch
                ? 'leftSideBarSport--active'
                : ''
            }
            onClick={() => {
              handleClickSideBarItem(`/${ENotRealSports.outright}`);
            }}
          />
        </Tooltip>
      )}
      {configs?.todayEventsEnabled && (
        <Tooltip title={t(`sportsbook.${ENotRealSports.todayEvents}`)}>
          <GlobalIcon
            lib="sports"
            name={ENotRealSports.todayEvents}
            prefix={{ showAfterIconIsRendered: true }}
            size={EIconSize._32}
            className={
              activeSport === ENotRealSports.todayEvents && isPrematch
                ? 'leftSideBarSport--active'
                : ''
            }
            onClick={() => {
              handleClickSideBarItem(`/${ENotRealSports.todayEvents}`);
            }}
          />
        </Tooltip>
      )}

      {configs?.topMatchesEnabled && (
        <Tooltip title={t(`sportsbook.${ENotRealSports.topMatches}`)}>
          <GlobalIcon
            lib="sports"
            name={ENotRealSports.topMatches}
            size={EIconSize._32}
            style={{ marginRight: 8 }}
            className={
              activeSport === ENotRealSports.topMatches && isPrematch
                ? 'leftSideBarSport--active'
                : ''
            }
            onClick={() => {
              handleClickSideBarItem(`/${ENotRealSports.topMatches}`);
            }}
          />
        </Tooltip>
      )}

      {configs?.olympicCompetitionEnabled && (
        <Tooltip title={t(`sportsbook.${ENotRealSports.olympicCompetition}`)}>
          <GlobalIcon
            lib="sports"
            name={`${ENotRealSports.olympicCompetition}${
              SpringConfigs.OLYMPIC_ICON_OTHER ? 'other' : ''
            }`}
            size={EIconSize._32}
            style={{ marginRight: 8 }}
            className={
              activeSport === ENotRealSports.olympicCompetition && isPrematch
                ? 'leftSideBarSport--active'
                : ''
            }
            onClick={() => {
              handleClickSideBarItem(`/${ENotRealSports.olympicCompetition}`);
            }}
          />
        </Tooltip>
      )}

      {configs?.boostedOddsEnabled && (
        <Tooltip title={t(`sportsbook.${ENotRealSports.boostedOdds}`)}>
          <GlobalIcon
            lib="sports"
            name={ENotRealSports.boostedOdds}
            size={EIconSize._32}
            style={{ marginRight: 8 }}
            className={
              activeSport === ENotRealSports.boostedOdds && isPrematch
                ? 'leftSideBarSport--active'
                : ''
            }
            onClick={() => {
              handleClickSideBarItem(`/${ENotRealSports.boostedOdds}`);
            }}
          />
        </Tooltip>
      )}
      {configs?.multiplesEnabled && (
        <Tooltip title={t(`sportsbook.${ENotRealSports.multiples}`)}>
          <GlobalIcon
            lib="sports"
            name={ENotRealSports.multiples}
            size={EIconSize._32}
            style={{ marginRight: 8 }}
            className={
              activeSport === ENotRealSports.multiples && isPrematch
                ? 'leftSideBarSport--active'
                : ''
            }
            onClick={() => {
              handleClickSideBarItem(`/${ENotRealSports.multiples}`);
            }}
          />
        </Tooltip>
      )}

      {configs?.BBSportsList && (
        <Tooltip title={t(`sportsbook.${ENotRealSports.betBuilder}`)}>
          <GlobalIcon
            lib="sports"
            name={`${ENotRealSports.betBuilder}${
              SpringConfigs.IS_SGB_TYPE_FOR_BB ? 'sgp' : ''
            }`}
            size={EIconSize._32}
            style={{ marginRight: 8 }}
            className={
              activeSport === ENotRealSports.betBuilder && isPrematch
                ? 'leftSideBarSport--active'
                : ''
            }
            onClick={() => {
              handleClickSideBarItem(`/${ENotRealSports.betBuilder}`);
            }}
          />
        </Tooltip>
      )}

      {SpringConfigs.COUPON_BUILDER_SWITCHER &&
        configs?.couponBuilderSportsList && (
          <Tooltip title={t(`sportsbook.${ENotRealSports.coupon}`)}>
            <GlobalIcon
              lib="sports"
              name={ENotRealSports.coupon}
              size={EIconSize._32}
              useWrapper
              className={
                activeSport === ENotRealSports.coupon && isPrematch
                  ? 'leftSideBarSport--active'
                  : ''
              }
              onClick={() => {
                handleClickSideBarItem(`/${ENotRealSports.coupon}`);
              }}
            />
          </Tooltip>
        )}

      {sportsData ? (
        sportsData.map(sport => {
          return (
            <Tooltip key={sport.id} title={sport.name}>
              <GlobalIcon
                lib="sports"
                name={sport.alias || ''}
                size={EIconSize._24}
                className={
                  activeSport === sport.alias ? 'leftSideBarSport--active' : ''
                }
                onClick={() => {
                  handleClickSideBarItem(`/${sport.alias}`, sport?.type);
                }}
              />
            </Tooltip>
          );
        })
      ) : (
        <div></div>
      )}
    </div>
  );
};
