export enum ECasinoQueryKey {
  CasinoGames = 'CASINO_GAMES',
  CasinoCategories = 'CASINO_CATEGORIES',
  CasinoProviders = 'CASINO_PROVIDERS',
  CasinoCrashGames = 'CASINO_CRASH_GAMES'
}

export enum EAccount {
  AccountParams = 'ACCOUNT_PARAMS',
  GET_CLIENT_CLAIMABLE_DEPOSIT_BONUSES = 'GET_CLIENT_CLAIMABLE_DEPOSIT_BONUSES',
  GET_CLIENT_PRE_CALCULATIONS = 'GET_CLIENT_PRE_CALCULATIONS',
  CustomPayment = 'CUSTOM_PAYMENT'
}

export enum ESearchElement {
  CasinoSearch = 'CASINO_SEARCH',
  SportSearch = 'SPORT_SEARCH'
}

export enum EPersonalized {
  PersonalizedCasinoSearch = 'PERSONALIZED_CASINO_SEARCH',
  PersonalizedCasinoProviders = 'PERSONALIZED_CASINO_PROVIDERS'
}

export enum EJackpotQueryKeys {
  topWinners = 'TOP_WINNERS'
}

export enum ETopWinners {
  sport = 'SPORT_TOP_WINNERS'
}

export enum EStoryKeys {
  STORIES = 'STORIES'
}
