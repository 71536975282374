import { FC } from 'react';
import cc from 'classcat';
import { SkeletonAvatarSize } from 'utils/constants/app/ui';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import './index.less';

type Props = {
  lightBg?: boolean;
};

export const SportListSkeleton: FC<Props> = ({ lightBg }) => {
  return (
    <div
      className={cc([
        'sportsList__skeleton',
        {
          'sportsList__skeleton--lightBg': lightBg
        }
      ])}
    >
      <Skeleton
        avatar={{
          shape: 'circle',
          size: lightBg
            ? SkeletonAvatarSize.SIZE_24
            : SkeletonAvatarSize.SIZE_32
        }}
        title={{
          style: { margin: 0, height: lightBg ? '20px' : '24px' }
        }}
        paragraph={false}
        active
      />
    </div>
  );
};
