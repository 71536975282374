import { FC } from 'react';
import { useSelector } from 'react-redux';
import cc from 'classcat';
import { EIconSize } from 'utils/constants/app/ui';
import { Sport } from 'interfaces/sportsbook-data-levels';
import { EventAction } from 'components/EventAction';
import { GlobalIcon } from 'components/GlobalIcon';
import { getFavoriteActiveStateById } from 'store/selectors/fav-data';

type TSportsFavoriteButtonProps = {
  onClick: (sportId: number) => void;
  sportId: Sport['id'];
  className?: string;
};

export const SportsFavoriteButton: FC<TSportsFavoriteButtonProps> = ({
  sportId,
  onClick,
  className = ''
}) => {
  const isActive = useSelector(getFavoriteActiveStateById(sportId, 'sports'));

  const favoriteClickHandler = (e: UIEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(+sportId);
  };

  return (
    <EventAction
      size="md"
      ghost
      active={isActive}
      icon={
        <GlobalIcon
          lib="generic"
          name="favorite"
          theme="default"
          size={EIconSize._16}
        />
      }
      className={cc([
        'sportFavoriteButton',
        { active: isActive, [className]: !!className }
      ])}
      onClick={favoriteClickHandler}
    />
  );
};
