import { ComponentProps, ReactElement, useCallback } from 'react';
import { TooltipProps } from 'antd/lib/tooltip';
import { OptionalNullable } from 'interfaces/generic';
import { CustomTooltip } from 'components/Tooltip';

type TTooltipProps = {
  children: ReactElement;
} & ComponentProps<typeof CustomTooltip>;

type TUseConditionalTooltip = (
  staticProps: Omit<TooltipProps, 'overlay'> &
    OptionalNullable<TooltipProps['overlay']>,
  condition: boolean
) => (props: TTooltipProps) => ReactElement;

export const useConditionalTooltip: TUseConditionalTooltip = (
  staticProps: Omit<TooltipProps, 'overlay'> &
    OptionalNullable<TooltipProps['overlay']>,
  condition: boolean
) => {
  return useCallback(
    ({ children, ...dynamicProps }: TTooltipProps) => {
      return (
        condition ? (
          <CustomTooltip {...(staticProps as TooltipProps)} {...dynamicProps}>
            {children}
          </CustomTooltip>
        ) : (
          children
        )
      ) as ReactElement;
    },
    [condition]
  );
};
