export const CommandNames: Record<string, string> = {
  GET_CLIENT_SPORT_ACTIVITY: 'get_client_sport_activity',
  GET_CLIENT_CURRENT_SESSION_INFO: 'get_client_current_session_info',
  GET_CASINO_LOSS_LIMIT: 'get_casino_loss_limit',
  GET_CASINO_TOP_WINNERS: 'casino_get_partner_last_big_wins',
  GET_CASINO_RECENT_WINNERS: 'get_partner_last_wins',
  GET_SPORT_LOSS_LIMIT: 'get_sport_loss_limit',
  CREATE_SHOP_ORDER: 'create_shop_order',
  REQUEST_SESSION: 'request_session',
  SPORT_CAST_CREATE_BET: 'sport_cast_create_bet',
  GET: 'get',
  WHATS_UP: 'whats_up',
  UNSUBSCRIBE: 'unsubscribe',
  LOGIN: 'login',
  SOCIAL_NETWORK_LOGIN: 'social_network_login',
  GET_APP_ID: 'get_app_id',
  RESTORE_LOGIN: 'restore_login',
  LOGIN_ENCRYPTED: 'login_encrypted',
  GET_IDIN_LOGIN_URL: 'get_idin_login_url',
  GET_DATA_VIA_IDIN: 'get_data_via_idin',
  LOGOUT: 'logout',
  SECURE_LOGOUT: 'secure_logout',
  REGISTER_USER: 'register_user',
  GET_CAPTCHA_INFO: 'get_captcha_info',
  UPDATE_USER: 'update_user',
  UPDATE_USER_PASSWORD: 'update_user_password',
  UPDATE_CLIENT_INFO: 'update_client_info',
  GET_USER: 'get_user',
  ADD_USER_MESSAGE: 'add_user_message',
  USER_MESSAGES: 'user_messages',
  READ_USER_MESSAGE: 'read_user_message',
  USER_LIMITS: 'user_limits',
  USER_ACTIVITY: 'get_client_sport_activity',
  SET_USER_LIMITS: 'set_user_limits',
  SET_PREFERRED_LANGUAGE: 'set_preferred_language',
  SET_SPORT_LOSS_LIMIT: 'set_sport_loss_limit',
  SET_CASINO_LOSS_LIMIT: 'set_casino_loss_limit',
  SET_CLIENT_SPORT_BET_LIMIT: 'set_client_sport_bet_limit',
  SET_CLIENT_CASINO_BET_LIMIT: 'set_client_casino_bet_limit',
  GET_CLIENT_SPORT_BET_LIMIT: 'get_client_sport_bet_limit',
  GET_CLIENT_CASINO_BET_LIMIT: 'get_client_casino_bet_limit',
  GET_PLAYER_PAST_BONUSES: 'get_player_past_bonuses',
  GET_PLAYER_LAST_TRANSACTION: 'get_player_last_transaction',
  UPLOAD_IMAGE: 'upload_image',
  UPLOAD_IMAGES: 'upload_images',
  UPLOAD_IMAGES_V2: 'add_or_update_client_image_v2',
  GET_IMAGES: 'get_images',
  SET_CLIENT_SELF_EXCLUSION: 'set_client_self_exclusion',
  SET_CLIENT_STATUS: 'set_client_status',
  GET_CLIENT_LOGIN_HISTORY: 'get_client_login_history',
  ENABLE_TWO_FACTOR_AUTHENTICATION: 'enable_two_factor_authentication',
  APPLY_TWO_FACTOR_AUTHENTICATION_CODE: 'apply_two_factor_authentication_code',
  RESET_PASSWORD_VIA_SMS: 'reset_password_via_sms',
  SEND_USERNAME_BY_SMS: 'send_username_by_sms',
  UPDATE_CLIENT_GDPR_TERMS: 'update_client_gdpr_terms',
  DEPOSIT: 'deposit',
  DEPOSIT_V2: 'deposit_v2',
  GET_DEPOSITS: 'get_deposits',
  WITHDRAW: 'withdraw',
  GET_WITHDRAWALS: 'get_withdrawals',
  WITHDRAW_CANCEL: 'withdraw_cancel',
  PAYMENT_SERVICES: 'payment_services',
  PAYMENT_SERVICES_V2: 'payment_services_v2',
  BALANCE_HISTORY: 'balance_history_v2',
  GET_CLIENT_NET_BALANCE: 'get_client_net_deposit_withdraw_history',
  ADD_WALLET: 'add_wallet',
  GET_PREDEFINED_MULTIPLES: 'get_predefined_multiples',
  REMOVE_WALLET: 'remove_wallet',
  WALLET_TO_WALLET_TRANSFER: 'wallet_to_wallet_transfer',
  GET_DEPOSIT_CHANGE_LIMIT_REQUEST: 'get_deposit_change_limit_request',
  GET_BONUS_DETAILS: 'get_bonus_details',
  GET_FREEBETS_FOR_BETSLIP: 'get_freebets_for_betslip',
  GET_FREEBETS_FOR_BETSLIP_V3: 'get_freebets_for_betslip_v3',
  CLAIM_BONUS: 'claim_bonus',
  GET_CLIENT_CLAIMABLE_BONUSES_COUNT: 'get_client_claimable_bonuses_count',
  GET_CLIENT_CLAIMABLE_DEPOSIT_BONUSES: 'get_client_claimable_deposit_bonuses',
  CANCEL_BONUS: 'cancel_bonus',
  GET_CLIENT_WITHDRAWABLE_BALANCE: 'get_client_withdrawable_balance',
  GET_SPORT_BONUS_RULES: 'get_sport_bonus_rules',
  GET_CLIENT_BONUS_BALANCE_HISTORY: 'get_client_bonus_balance_history',
  GET_BONUS_HISTORY: 'get_bonus_history',
  GET_TOURNAMENT_LIST: 'get_tournament_list',
  GET_TOURNAMENT: 'get_tournament',
  GET_TOP_PLAYER_LIST: 'get_top_player_list',
  JOIN_TO_TOURNAMENT: 'join_to_tournament',
  CHECK_TOURNAMENT_GAME: 'check_tournament_game',
  GET_TOURNAMENT_STATS: 'get_tournament_stats',
  USER_FEEDBACK: 'user_feedback',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
  VERIFY_USER: 'verify_user',
  GET_FAVORITE_TEAM: 'get_favorite_name',
  SET_FAVORITE_TEAM: 'set_favorite_command',
  DELETE_CLIENT_FAVORITE_TEAM: 'delete_client_favorite_teams',
  GET_RESULT_GAMES: 'get_result_games',
  GET_RESULTS: 'get_results',
  ACCEPT_TERMS_CONDITIONS: 'accept_terms_conditions',
  REJECT_TERMS_CONDITIONS: 'reject_terms_conditions',
  CASHOUT: 'cashout',
  CALCULATE_CASHOUT_AMOUNT: 'calculate_cashout_amount',
  SET_BET_AUTO_CASHOUT: 'set_bet_auto_cashout',
  GET_BET_AUTO_CASHOUT: 'get_bet_auto_cashout',
  CANCEL_BET_AUTO_CASHOUT: 'cancel_bet_auto_cashout',
  GET_AVAILABLE_LANGUAGE_CODES: 'get_available_language_codes',
  CHECK_BET_STATUS: 'check_bet_status',
  SEND_SMS_TO_PHONE_NUMBER: 'send_verification_code',
  SEND_SMS_WITH_USERNAME: 'send_verification_code_with_username',
  GET_PARTNER_BANKS: 'get_partner_banks',
  NOTIFY_ME: 'notify_me',
  GET_MATCH_SCORES: 'get_match_scores',
  VIDEO_URL: 'video_url',
  VIDEO_URL_IFRAME: 'video_url_iframe',
  BOOK_BET: 'create_bet_book_v2',
  GET_EVENTS_BY_BOOKING_ID: 'get_booking_by_id_v2',
  GET_BOOSTED_SELECTIONS: 'get_boosted_selections',
  UNSUBSCRIBE_BULK: 'unsubscribe_bulk',
  GET_BALANCE: 'get_balance',
  DO_BET: 'do_bet',
  CREATE_BETS: 'create_bets',
  GET_MAX_BET: 'get_max_bet',
  BET_HISTORY: 'bet_history',
  BET_PROFIT_HISTORY: 'get_bet_pl_history',
  UPDATE_USER_ACTIVE_TIME: 'update_user_active_time',
  SET_SESSION_DURATION: 'set_session_duration',
  APPLY_PROMO_CODES: 'apply_promo_codes',
  GET_PERMISSIBLE_ODDS: 'get_permissible_odds',
  ADD_OR_UPLOAD_LEGIGAME_CLIENT_DOCS: 'add_or_upload_legigame_client_docs',
  GET_CLIENT_LEGIGAME_UPLOADED_IMAGES: 'get_client_legigame_uploaded_images',
  GET_JACKPOTS: 'getjackpots',
  JACKPOT_BY_GAME: 'jackpotbygame',
  SUBSCRIBE_JACKPOT: 'subscribe',
  GET_JACKPOT_POOL_META_DATA: 'getjackpotpoolmetadata',
  GET_CLIENT_CURRENT_SESSION_SLOT_PL: 'get_client_current_session_slot_pl',
  SEND_GIFT_BET: 'send_gift_bet',
  ADD_TRU_NARRATIVE_CLIENT_DOCS: 'add_tru_narrative_client_docs',
  GET_RECENT_PLAYED_CASINO_GAMES: 'get_recent_played_casino_games',
  GET_REMAINING_SESSION_DURATION: 'get_remaining_session_duration',
  GET_SESSION_DURATION_LIMIT: 'get_session_duration_limit',
  SET_SESSION_DURATION_LIMIT: 'set_session_duration_limit',
  GET_NET_WINNING_REPORT: 'get_net_winning_report',
  GET_CLIENT_BANK_INFO: 'get_client_bank_info',
  GET_MESSAGES: 'get',
  SUPER_BET_ANSWER: 'super_bet_answer',
  STORE_USER_IDENTIFICATION_TOKEN: 'store_user_identification_token',
  GET_CLIENT_RESTRICTIONS: 'get_client_restrictions',
  GET_ACTIVE_COMPETITIONS: 'get_active_competitions',
  GET_TRUNARRATIVE_AUTOIDENT_LINK: 'get_trunarrative_autoident_link',
  GET_CASINO_BONUSE_DETAILS: 'get_casino_bonuse_details',
  GET_LOYALTY_INFO: 'get_loyalty_info',
  BONUS_EXCHANGE_POINTS: 'bonus_exchange_points',
  GET_CLIENT_BALANCE_LIMIT: 'get_client_balance_limit',
  GET_CLIENT_BALANCE_DETAILS: 'get_client_balance_details',
  SET_BALANCE_LIMIT: 'set_balance_limit',
  GET_SHOP_ORDERS: 'get_shop_orders',
  GET_SHOP_ORDER_ITEMS: 'get_shop_order_items',
  GET_PLAYER_OPT_INS: 'get_player_opt_ins',
  CLIENT_OPT_IN: 'client_opt_in',
  CLIENT_OPT_OUT: 'client_opt_out',
  CREATE_REFERENCE_CODE: 'create_reference_code',
  GET_REFERENCE_CODE: 'get_reference_code',
  GET_REFER_FRIEND_BONUSES: 'get_refer_friend_bonuses',
  GET_MARKET_TYPE: 'get_market_type',
  GET_PARTNER_SPORT_GROUPS: 'get_partner_sport_group',
  DELETE_USER_MESSAGE: 'delete_user_message',
  GET_JWE_INFO: 'get_jwe_info',
  STORE_FCM_TOKEN: 'store_fcm_token',
  STORE_ONE_SIGNAL_TOKEN: 'store_one_signal_token',
  GET_CASINO_SUGGESTED_GAMES: 'get_casino_suggested_games',
  GET_CHILD_REGIONS: 'get_child_regions',
  SET_GAMES_NOTIFICATIONS: 'set_games_notifications',
  GET_USER_ALL_NOTIFICATIONS: 'get_user_all_game_notifications',
  UPDATE_CLIENT_NOTIFICATIONS: 'update_client_notifications',
  GET_CLIENT_PRE_CALCULATIONS: 'get_client_pre_calculation',
  GET_SALARY_OCCUPATION: 'get_client_info',
  GET_PAYMENT_WALLETS: 'get_payment_wallets',
  DELETE_PLAYER_PAYMENT_WALLET: 'delete_player_payment_wallet',
  VALIDATE_SEON_ON_ACTION: 'validate_seon_on_action',
  GET_EACH_WAY_PARTNER_TERMS: 'get_each_way_partner_terms',
  TERMS_AND_CONDITIONS: 'terms_and_conditions_version_acceptance',
  CHECK_CPF_NUMBER: 'validate_cpf',
  GET_PARTNER_BONUSES_FOR_REQUEST: 'get_partner_bonuses_for_request',
  GET_CLIENT_LIMIT_CHANGE_HISTORY: 'get_client_limit_change_history',
  CREATE_CLIENT_BONUS_REQUEST: 'create_client_bonus_request',
  GET_CLIENT_BONUS_REQUESTS: 'get_client_bonus_requests',
  REMAIN_LOGGED_IN: 'remain_logged_in',
  GET_BET_SHOPS: 'get_bet_shops',
  GET_PARTNER_DOCUMENT_TYPES: 'get_partner_document_types',
  GET_FREE_SPIN_BONUSES: 'get_free_spin_bonuses',
  GET_BONUS_DEFINITON_GAMES: 'get_bonus_definition_games',
  VALIDATE_GEO_RESTRICTION: 'validate_geo_restriction',
  GET_GEO_TOKEN: 'get_geo_token',
  GET_PINNACLE_LOGIN: 'pinnacle_login',
  VALIDATE_RECAPTCHA: 'validate_recaptcha',
  VALIDATE_TURNSTILE: 'validate_turnstile',
  GET_POPULAR_BETS: 'get_popular_bets',
  GET_SECONDARY_WALLETS: 'get_secondary_wallets',
  CREATE_WALLET_TRANSFER: 'create_wallet_transfer',
  GET_RATE_FOR_CURRENCY: 'get_rate_for_currency',
  ESTIMATE_CRYPTO_FEE: 'estimate_crypto_fee',
  GET_USER_SPORT_ORDER: 'get_user_sport_order',
  WITHDRAWAL_INFORMATION: 'withdraw_v2',
  CHECK_CPFS: 'validate_cpfs',
  VERIFY_CARD_ACCOUNT: 'get_kycaid_form_url',
  LOGIN_WITH_NEM_ID: 'login_with_nem_id',
  GET_CHILD_SESSION: 'get_child_session',
  GET_PARENT_SESSION: 'get_parent_session',
  GET_CHILDREN_BALANCES: 'get_children_balances',
  GET_CLIENT_SELF_EXCLUSION_SCHEDULES: 'get_client_self_exclusion_schedules',
  GET_SUGGESTED_BETS: 'get_suggested_bets',
  GET_SPORTS: 'get_sports',
  GET_SUM_SUB_ACCESS_TOKEN: 'get_sum_sub_access_token',
  GET_COUPONS: 'get_coupons',
  GET_CLIENT_LAST_SESSION_INFO: 'get_client_last_session_info',
  LOGIN_CLIENT_WITH_METAMASK: 'login_client_with_metamask',
  CONNECT_CRYPTO_WALLET: 'connect_crypto_wallet',
  GET_CLIENT_NONCE: 'get_client_nonce',
  VERIFY_PHONE_NUMBER: 'verify_phone_number',
  STAKE: 'stake',
  UNSTAKE: 'unstake',
  GET_STAKE_BALANCE: 'get_stake_balance',
  GET_STAKE_HISTORY: 'get_stake_history',
  GET_STAKE_REWARDS: 'get_stake_rewards',
  MARK_AS_READ_NOTIFICATION: 'mark_as_read_notification',
  GET_CLIENT_REWARD_DETAILS: 'get_client_reward_details',
  COLLECT_REWARD: 'collect_reward',
  GET_CLIENT_REWARDS: 'get_client_rewards',
  GET_RACING_MATCHES: 'get_racing_matches',
  GET_WINNER_MARKET: 'get_winner_market',
  GET_PROMO_CODES: 'get_promo_codes',
  SET_USER_DATA_PERSONALIZATION: 'set_user_data_personalization',
  GET_USER_DATA_PERSONALIZATION: 'get_user_data_personalization',
  DELETE_USER_RESOURCES_PERSONALIZATION:
    'delete_user_resources_personalization',
  GET_USER_RESOURCES_PERSONALIZATION: 'get_user_resources_personalization',
  GET_BONUS_CODES: 'get_promo_codes',
  GET_CLIENT_INFORMATION_BY_PAY_BROKER_CPF:
    'get_client_information_by_pay_broker_cpf',
  GET_RATES: 'get_rates',
  GET_REQUEST_REJECT_REASON: 'get_request_reject_reason',
  GET_CLIENT_LOYALTY_BENEFITS: 'get_client_loyalty_benefits',
  CLAIM_LOYALTY_BENEFIT: 'claim_loyalty_benefit',
  CONVERT_FREE_BET_TO_MONEY: 'convert_free_bet_to_money',
  GET_TOP_WINNING_BETS: 'get_top_winning_bets',
  GET_SELECTIONS_BY_BET_ID: 'get_selections_by_bet_id',
  ORTAK_GET_PARTNER_COLLECTION: 'ortak_get_partner_collections',
  BUY_NFT: 'buy_nft',
  GET_CLIENT_GAMING_STATE_INFO: 'get_client_gaming_state_info',
  SHOW_CLIENT_REALITY_CHECK: 'show_client_reality_check',
  CHANGE_CLIENT_PRODUCT_CATEGORY: 'change_client_product_category',
  ORTAK_GET_USER_PENDING_GIFTS: 'ortak_get_user_pending_gifts',
  GET_PROVIDER_CRASH_GAMES: 'get_provider_crash_games',
  GET_SIGNICAT_VERIFICATION_URLS: 'get_signicat_verification_urls',
  GET_SIGNICAT_BRAZIL_VERIFICATION_URLS:
    'get_signicat_brazil_verification_urls',
  GET_GEO_COMPLY_LICENSE_STRING: 'get_geo_comply_license_string',
  GEO_COMPLY_GEOLOCATION_CHECK: 'geo_comply_geolocation_check',
  GET_FINANCIAL_COMPANIES: 'get_financial_companies',
  GET_SIGNICAT_ACTION_CONFIRMATION_URL: 'get_signicat_action_confirmation_url',
  GET_SIGNICAT_ACTION_CONFIRMATION_URL_BY_EMAIL:
    'get_signicat_action_confirmation_url_by_email',
  GET_BANK_ACCOUNT: 'get_bank_account',
  SAVE_BANK_ACCOUNT: 'save_bank_account',
  DELETE_BANK_ACCOUNT: 'delete_bank_account',
  TERMS_AND_CONDITIONS_VERSION_ACCEPTANCE:
    'terms_and_conditions_version_acceptance',
  ACCEPT_BRAZIL_TERMS_AND_CONDITIONS: 'accept_brazil_terms_and_conditions',
  SEND_WRONG_LOGIN_ATTEMPTS_RESET_CODE: 'send_wrong_login_attempts_reset_code',
  VERIFY_WRONG_LOGIN_ATTEMPTS_RESET: 'verify_wrong_login_attempts_reset'
};

export const CommandSource = {
  PARTNER_CONFIG: 'partner.config',
  CURRENCY_CONFIG: 'config.currency',
  BETTING: 'betting',
  USER: 'user',
  NOTIFICATIONS: 'notifications'
};
