import { ReactElement } from 'react';

type Props = {
  condition: boolean;
  positiveWrapper: (children: ReactElement) => ReactElement;
  negativeWrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
};

export const WrapperHandler = ({
  condition,
  positiveWrapper,
  negativeWrapper,
  children
}: Props): ReactElement =>
  condition ? positiveWrapper(children) : negativeWrapper(children);
