export const HANDICAPS = [
  'Handicap',
  'AsianHandicap',
  'AsianHandicapAsian',
  'MatchHandicap',
  'TriesHandicap',
  'Handicap(NoDraw)',
  'GamesHandicap',
  'MapsHandicap',
  'MatchHandicap2',
  'MatchHandicap2Asian',
  'HandicapLegs',
  'RunLine'
];

export const OVER_UNDERS = [
  'OverUnder',
  'OverUnderAsian',
  'TotalGamesOver/Under',
  'MatchTotal',
  'TotalofSets',
  'TotalGoalsOver/Under',
  'TotalRounds',
  'Over/Under',
  'TotalPointsOver/Under',
  'GamesTotal',
  'MapsTotal',
  'MatchTotal2',
  'MatchTotal2Asian',
  'MatchPointCommonTotalOverUnder2Way',
  'TotalRunsOver/Under',
  'MatchTotalPointsOverUnder',
  'MatchPointsTotal2Way',
  'TotalLegs'
];

export const WINNER_MARKETS = [
  'P1P2',
  'W1W2',
  'P1XP2',
  'W1XW2',
  'MatchWinner',
  'MatchResult',
  'MatchWinner3Way',
  'MoneyLine'
];

// List of MatchResult/Handicaps/Totals markets types for all periods
//           'P1P2',
//           'P1XP2',
//           'MatchResult',
//           'MatchWinner',
//           '1X12X2',
//           'BothTeamsToScore',
//           'DrawNoBet',
//           'EvenOddTotal',
//           'MatchOddEvenTotal',
//           'MatchTotal',
//           'TotalGamesOver/Under',
//           'TotalofSets',
//           'Handicap',
//           'MatchTotalPointsHandicap',
//           'MatchHandicap2',
//           'PointHandicap',
//           'MatchPointHandicap',
//           'MatchTotal2',
//           'MatchTotalPointsOverUnder',
//           'Over/Under',
//           'SetOverUnder',
//           'TotalPointsOver/Under',
//           'HalfTimeResult',
//           'HalfTimeAsianHandicap',
//           'HalfTimeOverUnder',
//           'SecondHalfResult',
//           '2ndHalfAsianHandicap',
//           '2ndHalfTotalOver/Under',
//           'SetWinner',
//           'SetHandicap',
//           'SetPointsHandicap',
//           'SetOverUnder',
//           'SetTotalPointsOverUnder',
//           'QuarterWinner2',
//           'QuarterWinner3',
//           'QuarterHandicap',
//           'QuarterTotal',
//           'SetTotalOverUnder',
//           'FrameTotal',
//           'FrameWinner',
//           'FrameHandicap',
//           'PeriodWinner',
//           'PeriodTotal',
//           'PeriodHandicap',
//           'PeriodWinner2',
//           'PeriodTotal2',
//           'PeriodHandicap2',
//           'PeriodWinner3',
//           'PeriodTotal3',
//           'PeriodHandicap3'
