export const fromDasherizedToAllCapitalized = (text: string): string => {
  return text
    .replace(/[-]/g, ' ')
    .replace(/(^|\s)\S/g, l => l.toUpperCase())
    .trim();
};

export const fromAllCapitalizedToDasherized = (text: string): string => {
  return text.trim().toLowerCase().replace(/\s/g, '-').replace(/#/g, '');
};
