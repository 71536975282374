type GlobalType = {
  floatingBetslipMounted: boolean;
  betslipElementsCount: number;
  // @Todo remove me
  betslipGlobalComponentRid: string;
  openBetsGlobalComponentRid: string;
  // @Todo remove me
  freebetGlobalRid: string;
  subscriptionModalMounted: boolean;
  sportsBook: {
    redirectedFromFavorite: boolean;
  };
  casino: {
    casinoElementsCount: number;
    singleGameViewMounted: boolean;
    isCasinoCategoriesMounted: boolean;
    // TODO: remove this after replacing old casino games
    gameClickOffsetTop: null | number;
    blockCasinoGamePopupOpening: boolean;
  };
  tournament: {
    cardClickOffset: number;
  };
  mounted: Record<string, boolean>;
  app: {
    messageListenerMounted: boolean;
    genericMounted: boolean;
  };
  isTopNavigationButtonMounted: boolean;
  homeworkIframe: boolean;
  initProces: boolean;
  geolocationWindow?: { close: () => void } | null;
  oneTimeCommands: {
    getUserSportOrder: boolean;
  };
  account: {
    registration: {
      automaticSmsCode: boolean;
      isRecaptchaVerify: boolean;
    };
  };
};

const GLOBALS: GlobalType = {
  floatingBetslipMounted: false,
  betslipElementsCount: 0,
  betslipGlobalComponentRid: '',
  openBetsGlobalComponentRid: '',
  freebetGlobalRid: '',
  subscriptionModalMounted: false,
  mounted: {
    accounts: false
  },
  sportsBook: {
    redirectedFromFavorite: false
  },
  casino: {
    casinoElementsCount: 0,
    singleGameViewMounted: false,
    isCasinoCategoriesMounted: false,
    gameClickOffsetTop: null,
    blockCasinoGamePopupOpening: false
  },
  tournament: {
    cardClickOffset: 0
  },
  app: {
    messageListenerMounted: false,
    genericMounted: false
  },
  isTopNavigationButtonMounted: false,
  homeworkIframe: false,
  initProces: false,
  oneTimeCommands: {
    getUserSportOrder: false
  },
  account: {
    registration: {
      automaticSmsCode: false,
      isRecaptchaVerify: false
    }
  }
};

export default GLOBALS;
