import { useDispatch, useSelector } from 'react-redux';
import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { useOpenLoginPopup } from 'hooks/account/useOpenLoginPopup';
import { toggleFavorite } from 'store/actions';
import { getIsLoggedIn } from 'store/selectors/user-data';

export const useSportsFavoriteClickHandler = () => {
  const dispatch = useDispatch();
  const openLoginPopup = useOpenLoginPopup();
  const isLoggedIn = useSelector(getIsLoggedIn);

  return (sportId: number) => {
    if (!isLoggedIn && SpringConfigs.USER_PERSONALIZATION_ENABLED) {
      LocalStorage.setItem(
        storageKeyName('account', 'UNSIGNED_FAV_SPORT'),
        sportId.toString()
      );
      openLoginPopup();
    } else {
      dispatch(toggleFavorite({ groupKey: 'sports', entity: sportId }));
    }
  };
};
