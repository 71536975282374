import React, { LegacyRef, MouseEventHandler, ReactElement } from 'react';
import cc from 'classcat';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { ADD_COMPETITIONS_TOP_MATCHES } from 'utils/constants/tour';
import { isMobile } from 'utils/is-mobile';
import { Competition } from 'interfaces/sportsbook-data-levels';
import { WhiteTooltip } from 'components/WhiteTooltip';
import { CompetitionFavoriteMatchesCountIndicator } from 'newcomponents/Shared/sportsbook/CompetitionFavoriteMatchesCountIndicator';
import { UrlWrapper } from '../UrlWrapper';
import './index.less';

type TCompetitionNameWithTooltipProps = {
  competitionItem: Competition;
  active?: boolean;
  IconBeforeText?: ReactElement | null;
  IconAfterText?: ReactElement | null;
  clickHandler: MouseEventHandler<HTMLDivElement>;
  competitionUrl: string;
  dataTestId?: string;
};

export const CompetitionNameWithTooltip = React.forwardRef(
  (
    {
      active,
      competitionItem,
      IconBeforeText,
      IconAfterText,
      clickHandler,
      competitionUrl,
      dataTestId
    }: TCompetitionNameWithTooltipProps,
    ref: LegacyRef<HTMLDivElement> | undefined
  ): ReactElement => {
    return (
      <UrlWrapper
        url={competitionUrl}
        key={competitionItem.id}
        dataTestId={dataTestId}
      >
        <div
          data-testid="usa-competition"
          {...(SpringConfigs.USER_PERSONALIZATION_ENABLED && active
            ? { 'data-tour': ADD_COMPETITIONS_TOP_MATCHES }
            : {})}
          className={cc([
            'competitionWithTooltip',
            {
              'competitionWithTooltip--active': active,
              'competitionWithTooltip--hover': !isMobile(),
              'competitionWithTooltip-mobile': isMobile(),
              'competitionWithTooltip--competitionLayout':
                !!SpringConfigs.TOP_LEAGUES_LAYOUT
            }
          ])}
          ref={ref}
          onClick={clickHandler}
        >
          {IconBeforeText}
          <WhiteTooltip title={competitionItem.name} tooltipOnParent>
            <div
              className="competitionWithTooltip__name"
              data-testid="competition-name"
            >
              {competitionItem.name}
            </div>
          </WhiteTooltip>
          <CompetitionFavoriteMatchesCountIndicator
            competitionId={competitionItem.id}
          />
          {IconAfterText}
        </div>
      </UrlWrapper>
    );
  }
);
