import { createContext } from 'react';
import {
  ESportsbookLayoutTypes,
  TSportsbookConfigs
} from 'interfaces/sportsbook-configs';

export const defaultConfigs: TSportsbookConfigs = {
  pageType: 0,
  fit: 'cover',
  type: 'prematch',
  category: 'esport',
  spbLayoutType: ESportsbookLayoutTypes.CLASSIC,
  favoriteTeam: true
};

export const SportsbookConfigsContext =
  createContext<TSportsbookConfigs>(defaultConfigs);
