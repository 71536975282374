import { SyntheticEvent } from 'react';
import TabsProps from 'antd-mobile/lib/tabs/PropsType';
import { EBetslipPage } from 'interfaces/bet-data';
import { ResponseGeneric } from '../generic';

// TYPE MODULES EXPORTS
export * from './taxes';

export type BetslipLayoutProps = {
  tabProps: TabsProps;
  betsProps: BetsProps;
};

export type TabObject = {
  id: EBetslipPage;
  title: string;
  badge?: number | string | null;
};

export type TabProps = {
  tabs: TabObject[];
  prerenderingSiblingsNumber: number;
  renderTabBar: (props: any) => JSX.Element;
  destroyInactiveTab: boolean;
  swipeable: boolean;
  animated: boolean;
  initialPage: number;
  page: number | undefined;
  onChange: (tab: TabObject) => void;
};

export type BetsProps = {
  subscribedEvents: BetslipEvents;
  toggleBetslipView: (value: boolean) => void;
  singleReturn: number;
  currentType: EBetslipBetType;
  isBetTypeMultipleOrChain: boolean;
};

export type TSuspendReason =
  | 'gameBlocked'
  | 'gameEnded'
  | 'market'
  | 'eventBlocked'
  | 'eventRemoved'
  | 'other';

export type BetslipEvents = Record<string, TBetslipEvent>;

export type TBetslipEvent = {
  base?: number;
  price?: number;
  gameId: number;
  eventId: number;
  sport_name: string;
  team1: string;
  team2: string;
  marketType: string;
  market_name: string;
  game_name: string;
  current_game_state: number | string;
  competition_name: string;
  isLive?: boolean;
  event_name: string;
  name: string; // raw event name coming from swarm
  region_id: number;
  region_name: string;
  game_start_date: number;
  sport_id: number;
  eventBase: number | undefined;
  game_info: {
    horse_xml: {
      HorceBXml: string;
    };
  };
  sport_index: string;
  current_game_time: number | string;
  coeficient: string;
  sp?: boolean;
  sport_alias?: string;
  game_state?: string;
  suspendReasons?: TSuspendReason[] | null;
  game_time?: string;
  competitionId?: number;
  score1?: string;
  score2?: string;
  express_id?: number;
};

export type ResponseBookingSelections = Omit<ResponseGeneric, 'details'> & {
  details: SelectionsFromBetslip;
};

interface ISelectionItem {
  competition_id: number;
  market_id: number;
  match_id: number;
  selection_id: number;
  sport_id: number;
}

export interface IEventsAndGameIds {
  eventId: number;
  gameId: number;
}

export type SelectionsFromBetslip = {
  bets: {
    amount: number;
    bet_type: EBetslipBetType;
    input_method: number;
    system_min_count: null | number;
    selections: ISelectionItem[];
  }[];
  cash_desk_id: number | null;
  client_id: number | null;
  id: number;
  match_ids: number[];
  result: number;
  result_text: string | null;
};

export type OddFormat = {
  id: number;
  label: string;
  value: string;
};

export type BetslipType = {
  id: number;
  label: string;
  value: number;
};

export type OnAcceptBet = {
  label: string;
  value: string;
  key: string;
};

export enum ESportBonusType {
  STAKE_BONUS = 0,
  TOTAL_RETURN_WITHOUT_STAKE_BONUS = 1
}

export type SportBonus = {
  AmountPercent: number;
  Basis: ESportBonusType;
  BetType: number;
  ExactLosing: number;
  IgnoreLowOddSelection: boolean;
  MaximumSelections: number;
  MinBetOdds: null;
  MinBetStakes: {
    MinStakes: { id: string; Amount: number; Currency: string }[];
  };
  MinOdds: number;
  MinimumSelections: number;
  SportBonusRules: [];
  FreeBetId: null | string | boolean;
};

export type SportBonusResponce = {
  result: number;
  result_text: string | null;
  details: SportBonus[];
};

export type TPopover = {
  id: string;
  onCancel: (event?: SyntheticEvent) => void;
  onConfirm: (event?: MouseEvent) => void;
  validateRecaptcha?: boolean;
  selectedEvents?: string[];
} | null;

export type ButtonPositionType =
  | 'topLeft'
  | 'top'
  | 'topRight'
  | 'bottom'
  | 'bottomRight'
  | 'bottomLeft';

export type doBetProps = {
  bonus_id?: number;
  amount: number;
  bets: Array<{
    event_id: number;
    price: number;
    booking_id?: number;
  }>;
  mode?: number;
  odd_type?: number;
  type: number;
  sys_bet?: number;
  is_bonus_money?: boolean;
  predefined_multiple_id?: string;
  source?: string;
  each_way?: boolean;
  is_live?: boolean;
  booking_id?: number;
  location_token?: string;
};

export type TCounterOfferState = {
  value: string;
  isActive: boolean;
};

export enum EAfterSuccessfulBet {
  REMOVE_EVENTS = 0,
  KEEP_EVENTS = 1
}

export enum EBetslipBetType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  CHAIN = 'chain',
  SYSTEM = 'system'
}

export enum EBetslipType {
  BASIC = 0,
  ADVANCED = 1
}

export type TSuperBetEventType =
  | number
  | ''
  | Exclude<EBetslipBetType, EBetslipBetType.SINGLE>;

export enum EBetBalanceType {
  REAL,
  BONUS,
  FREE_BET
}
