/**
 * Backend status codes constants
 */
export const BackendStatusCodes = {
  betslip: {
    useSuperBet: 2200
  },
  casino: {
    switchToFTNWallet: 1070,
    currencyNotSupported: 1031
  }
};
export const SUCCESS_STATUS_CODE = 200;

export const StatusNumberCode = {
  ERROR_2: -2,
  ERROR_1: -1,
  SUCCESS_3: 3
};

export const WrongLoginAttemptsDetails = {
  code: 3000,
  key: 'WrongLoginAttempts'
};

export const WrongLoginAttemptsPerPeriodDetails = {
  code: 99,
  key: 'wrongLoginAttemptsPerPeriod'
};

export const WrongLoginAttemptsPerPeriodDetails2 = {
  code: 3122,
  key: 'WrongLoginAttemptsPerPeriod'
};

export const GeolocationCheckFailedDetails = {
  code: 3115,
  key: 'GeolocationCheckFail'
};
