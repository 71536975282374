import { isMobile } from 'utils/is-mobile';
import SpringConfigs from '../swarm/spring-configs';

/*eslint no-magic-numbers:*/

export const datePickerPlacmentleft = !SpringConfigs.IS_RTL
  ? 'bottomLeft'
  : 'bottomRight';

export const datePickerPlacmentRight = !SpringConfigs.IS_RTL
  ? 'bottomRight'
  : 'bottomLeft';

export const ACCOUNT_ROW_GUTTER = isMobile() ? 32 : 40;
export const ZINDEX_MODAL = 1014;
export const ZINDEX_HIGH_1 = 1013;
export const ZINDEX_HIGH_2 = 1012;
export const ZINDEX_HIGH_3 = 1011;
export const ZINDEX_HIGH_4 = 1010;
export const ZINDEX_HIGH_5 = 1009;
export const ZINDEX_HIGH_6 = 1008;
export const ZINDEX_HIGH_7 = 1007;

export const ZINDEX_TOOLTIP = 10001;

export const OFFSET_DISTANCE_RTL = 12;

export enum EContainerIconSize {
  SMALL = 20,
  MIDDLE = 24,
  LARGE = 32
}
export const ROW_GUTTER = {
  _4: 4,
  _8: 8
};

export const ICON_SIZE = {
  DESKTOP_12: 12,
  DESKTOP_16: 16,
  DESKTOP_20: 20,
  DESKTOP_24: 24,
  DESKTOP_28: 28,
  DESKTOP_32: 32,
  DESKTOP_48: 48,
  DESKTOP_68: 68,
  DESKTOP_80: 80,
  DESKTOP_143: 143,
  DESKTOP_148: 148,
  MOBILE_10: 10,
  MOBILE_16: 16,
  MOBILE_18: 18,
  MOBILE_20: 20,
  MOBILE_24: 24,
  MOBILE_40: 40,
  MOBILE_56: 56,
  MOBILE_120: 120,
  MOBILE_161: 161
};

export enum EIconSize {
  _4 = 4,
  _6 = 6,
  _8 = 8,
  _9 = 9,
  _10 = 10,
  _11 = 11,
  _12 = 12,
  _13 = 13,
  _14 = 14,
  _15 = 15,
  _16 = 16,
  _17 = 17,
  _18 = 18,
  _20 = 20,
  _22 = 22,
  _23 = 23,
  _24 = 24,
  _26 = 26,
  _28 = 28,
  _30 = 30,
  _32 = 32,
  _34 = 34,
  _36 = 36,
  _40 = 40,
  _42 = 42,
  _44 = 44,
  _46 = 46,
  _48 = 48,
  _50 = 50,
  _52 = 52,
  _64 = 64,
  _72 = 72,
  _80 = 80,
  _82 = 82,
  _85 = 85,
  _88 = 88,
  _96 = 96,
  _120 = 120,
  _132 = 132,
  _176 = 176,
  _204 = 204,
  _245 = 245
}

export const IMAGE_SIZE = {
  _120: 120,
  _144: 144,
  _154: 154,
  _176: 176
};

export const SkeletonAvatarSize = {
  SIZE_20: 20,
  SIZE_24: 24,
  SIZE_36: 36,
  SIZE_32: 32,
  SIZE_40: 40,
  SIZE_60: 60
};

export const MAX_FONT_SIZE = 36;
export const FONT_SIZE_INCREMENT_VALUE_8 = 8;
export const FONT_SIZE_INCREMENT_VALUE_4 = 4;

export const ModalPadding = {
  MOBILE_12: 12,
  DESKTOP_24: 24,
  WRAPPER_8: 8
};

export const ModalWidth = {
  MOBILE_300: 300,
  MOBILE_320: 320,
  MOBILE_350: 350,
  MOBILE_356: 356,
  DESKTOP_420: 420,
  DESKTOP_436: 436,
  DESKTOP_350: 350,
  DESKTOP_468: 468,
  DESKTOP_720: 720,
  DESKTOP_1030: 1030,
  MAX_1272: 1272
};

export const ProgressWidth = {
  MOBILE_40: 40,
  DESKTOP_32: 32
};

export const offsetBadgeValue = {
  OFFSET_TYPE_VALUE_MINUS_2: -2,
  OFFSET_TYPE_VALUE_MINUS_5: -5,
  OFFSET_TYPE_VALUE_MINUS_12: -12,
  OFFSET_TYPE_VALUE_MINUS_14: -14,
  OFFSET_TYPE_VALUE_5: 5,
  OFFSET_TYPE_VALUE_7: 7,
  OFFSET_TYPE_VALUE_18: 18
};

export const ColSizes = {
  SIZE_2: 2,
  SIZE_3: 3,
  SIZE_4: 4,
  SIZE_5: 5,
  SIZE_6: 6,
  SIZE_7: 7,
  SIZE_8: 8,
  SIZE_11: 11,
  SIZE_12: 12,
  SIZE_14: 14,
  SIZE_16: 16,
  SIZE_17: 17,
  SIZE_18: 18,
  SIZE_19: 19,
  SIZE_20: 20,
  SIZE_21: 21,
  SIZE_24: 24
};
export const TextCollapseMaxLength = {
  LENGTH_100: 100,
  LENGTH_194: 194,
  LENGTH_500: 500
};

export const fontWeight = {
  SIZE_400: 400,
  SIZE_500: 500
};
