import styled from 'styled-components';

export const Wrapper = styled.div<{
  isActive?: boolean;
  size?: 'default' | 'middle';
  $noMargin?: boolean;
  $noRadius?: boolean;
}>`
  color: var(--v3-text-color);
  margin-bottom: ${props => (props.$noMargin ? '1px' : '4px')};
  border-radius: ${props => (props.$noRadius ? 0 : '6px')};
  overflow: hidden;
  ${props =>
    props.size === 'middle' &&
    `
      ${Title} {
        font-size: 16px;
        line-height: 16px;
      }
      ${Header} {
        padding: 0px 12px;
        height: 48px;
      }
  `}
`;

export const Header = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 39px;
  line-height: 16px;
  cursor: pointer;
  padding: 0px 8px 0 8px;
  ${props =>
    props.isActive
      ? ` 
        min-height: 40px;
        background-color: var(--v3-black-2);
      `
      : `
        background-color: var(--v3-black-0);
      `}
`;

export const Title = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
`;

export const Icon = styled.div`
  margin-right: 8px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Extra = styled.div`
  font-size: 12px;
  padding: 0 8px;
  color: var(--v3-text-color-secondary);
`;
