import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cc from 'classcat';
import { EIconSize } from 'utils/constants/app/ui';
import { competitions, regions } from 'utils/constants/sportsbook/data-fields';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { getMountPath, mountPoint } from 'utils/generic/route-manipulation';
import { getLivePreMatchSportTypes } from 'utils/sportsbook/sport-data';
import RidGenerator from 'utils/swarm/rid-generator';
import { EPageTypes } from 'interfaces/sportsbook';
import {
  CompetitionWithRegionName,
  IMenuGroupWithSports,
  Region,
  SportOnly
} from 'interfaces/sportsbook-data-levels';
import { executeCommand, unsubscribeCommand } from 'services/get-data-level';
import { EventCollapse } from 'components/EventCollapse';
import { GlobalIcon } from 'components/GlobalIcon';
import { NavigationCollapse } from 'components/NavigationCollapse';
import { CompetitionNameWithTooltip } from 'components/Shared/CompetitionNameWithTooltip';
import { CompetitionsFavorite } from 'newcomponents/Shared/sportsbook/CompetitionsFavorite';
import { TopLeagueCompactLayoutSkeleton } from 'newcomponents/Shared/sportsbook/TopLeagueCompactLayout/skeleton';
import { useSbRouteMatch } from 'newhooks/generic/useSbRouteMatch';
import { setRegionCompData } from 'store/actions/new-sportsbook';
import { getRegionCompData } from 'store/selectors/new-sportsbook';

type TProps = {
  groupData: IMenuGroupWithSports;
  isCompactView: boolean;
  openFromSideBarPopup?: boolean;
};

type TSportWithCompetitions = SportOnly & {
  competitions: CompetitionWithRegionName[];
};

interface IMenuGroupWithCompetitions
  extends Omit<IMenuGroupWithSports, 'sports'> {
  sports: TSportWithCompetitions[];
}

export const GroupWithSports: FC<TProps> = ({
  groupData,
  isCompactView,
  openFromSideBarPopup
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const regionCompData = useSelector(getRegionCompData);
  const [activeSport, setActiveSport] = useState('');
  const routeParams = useSbRouteMatch(
    `${mountPoint(window.getPathname())}/:sport?/:region?/:competition?/:game?`
  );

  useEffect(() => {
    const rid = RidGenerator.gForSubscribe(`Prematch_${activeSport}`);

    const where: Record<string, any> = {
      sport: {
        alias: activeSport,
        type: {
          '@in': getLivePreMatchSportTypes(EPageTypes.prematch)
        }
      },
      game: {
        '@or': [
          { type: { '@in': [0, 2] } },
          {
            visible_in_prematch: 1
          }
        ]
      }
    };

    const relations: Record<string, any> = {
      ...regions,
      ...competitions
    };

    if (activeSport || SpringConfigs.MOCKED_DATA) {
      const callback = (data: { region: Record<string, Region> }) => {
        if (data?.region) {
          dispatch(setRegionCompData({ [activeSport]: data.region }));
        }
      };

      executeCommand(rid, relations, where, callback, true, callback);
    }

    return () => {
      unsubscribeCommand(rid);
    };
  }, [activeSport]);

  const handleRedirectToSport = useCallback(
    (competition: CompetitionWithRegionName, sport: string) => {
      const pathName = `${sport}/${competition.region}/${competition.id}`;

      if (
        openFromSideBarPopup &&
        document.querySelector('.prematch__type-page')
      ) {
        const path = `${getMountPath(
          routeParams?.path?.replace(/:sport\?.*$/, sport) || ''
        )}/${competition.region}/${competition.id}`;

        history.push(path);
      } else if (isCompactView) {
        history.push(pathName);
      } else {
        window.location.href = `${SpringConfigs.PAGE_URLS.prematch}/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}/${pathName}`;
      }
    },
    [isCompactView]
  );

  const getCompetitionsWithRegionName = useCallback(
    (sport: string) => {
      const dataBySport = regionCompData?.[sport];

      if (dataBySport) {
        return Object.values(dataBySport).reduce((competitions, region) => {
          return [
            ...competitions,
            ...Object.values(region.competition || {}).map(c => ({
              ...c,
              region: region.alias
            }))
          ];
        }, [] as CompetitionWithRegionName[]);
      }

      return [];
    },
    [regionCompData]
  );

  const groupDataWithCompetitions = useMemo<IMenuGroupWithCompetitions>(() => {
    return {
      ...groupData,
      sports: groupData.sports.map(sport => ({
        ...sport,
        competitions: getCompetitionsWithRegionName(sport.alias)
      }))
    } as IMenuGroupWithCompetitions;
  }, [groupData, getCompetitionsWithRegionName]);

  return (
    <EventCollapse
      key={`${groupData.name}${groupData.id}`}
      title={groupData.name}
      icon={
        <GlobalIcon
          lib={'sports'}
          size={EIconSize._24}
          name={groupData.icon}
          className="text-color"
        />
      }
      wrapperClassName={cc([
        {
          sportsList__sport: !isCompactView,
          'sportsList--compact': isCompactView,
          sportsList__groupWithSports: !isCompactView
        }
      ])}
      headerClassName={cc([
        {
          sportsList__sport__header: !isCompactView,
          prematch__sportsList__sport__header: isCompactView
        }
      ])}
    >
      <>
        {groupDataWithCompetitions.sports.map(sport => (
          <NavigationCollapse
            key={sport.id}
            title={sport.name}
            defaultActive={sport.alias === activeSport}
            onChange={isOpened => {
              isOpened && setActiveSport(sport.alias);
            }}
            icon={
              <GlobalIcon
                lib="sports"
                name={sport.alias || ''}
                size={EIconSize._24}
                className="text-color"
              />
            }
          >
            {!regionCompData?.[sport.alias] ? (
              <TopLeagueCompactLayoutSkeleton hideFirstIcon />
            ) : (
              sport.competitions.map(competition => (
                <CompetitionNameWithTooltip
                  key={competition.id}
                  clickHandler={() =>
                    handleRedirectToSport(competition, sport.alias)
                  }
                  competitionUrl={`${SpringConfigs.PAGE_URLS.prematch}/${competition.region}/${competition.id}`}
                  competitionItem={competition}
                  IconBeforeText={
                    <CompetitionsFavorite
                      iconSize={EIconSize._16}
                      competitionInfo={{
                        id: competition.id,
                        name: competition.name,
                        regionAlias: competition.region,
                        sportAlias: sport.alias
                      }}
                    />
                  }
                  IconAfterText={
                    <GlobalIcon
                      lib="flags"
                      name={competition.region}
                      size={EIconSize._16}
                    />
                  }
                />
              ))
            )}
          </NavigationCollapse>
        ))}
      </>
    </EventCollapse>
  );
};
