import { ENotRealSports } from 'interfaces/not-real-sports';

export const GROUP_ICONS_COUNT = 7;

export const NotRealSportsForDifFormats: string[] = [
  ENotRealSports.topMatches,
  ENotRealSports.boostedOdds
];
export const ArcheryH2HSportAlias = 'ArcheryH2H';
export const CompoundArcherySportAlias = 'CompoundArchery';
export const OlympicCompetition = 'olympicCompetition';
export const OlympicCompetitionOther = 'olympiccompetitionother';
