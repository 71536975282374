import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { sbRedirect } from 'utils/generic/route-manipulation';
import { isMobile } from 'utils/is-mobile';
import store from 'store';

export const handleRedirectToSportsBook = (
  gamesStatus: string,
  region?: string,
  competitionId?: number,
  gameId?: number,
  sportAlias?: string,
  eSport?: boolean,
  keepSearchParams?: boolean
): void => {
  const dataJson = store.getState().appData.menuJsonData as Record<
    string,
    string | number
  >[];

  const isLive = ['liveEvents', 'live'].includes(gamesStatus);

  let redirectPath = isLive
    ? SpringConfigs.PAGE_URLS.live
    : SpringConfigs.PAGE_URLS.prematch;

  if (!redirectPath && dataJson.length) {
    redirectPath = dataJson.find(el =>
      el.category === 'sport' && gamesStatus === 'liveEvents'
        ? el.status === 1
        : el.status === 0
    )?.path as string;
  }

  if (redirectPath === '/') {
    redirectPath = `${window.location.origin}/`;
  }

  if (!competitionId && !gameId) {
    sbRedirect(
      `${redirectPath}/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}${
        sportAlias ? `/${sportAlias}` : ''
      }`.replace(/([^:]\/)\/+/g, '$1'),
      false,
      true
    );
  } else {
    if (eSport && SpringConfigs.PAGE_URLS.esport) {
      sbRedirect(
        `${SpringConfigs.PAGE_URLS.esport}/${
          SpringConfigs.SPORTSBOOK_MOUNT_PATH
        }/${sportAlias}/${!isMobile() ? `${region}/${competitionId}/` : ''}${
          gameId || ''
        }?type=${gamesStatus}${
          keepSearchParams ? window.location.search : ''
        }`.replace(/([^:]\/)\/+/g, '$1'),
        false,
        true
      );
    } else {
      sbRedirect(
        `${redirectPath}/${
          SpringConfigs.SPORTSBOOK_MOUNT_PATH
        }/${sportAlias}/${region}/${competitionId}/${gameId || ''}${
          keepSearchParams ? window.location.search : ''
        }`.replace(/([^:]\/)\/+/g, '$1'),
        false,
        true
      );
    }
  }
};
