import { ALL_SPORTS_TYPES } from 'utils/constants/sportsbook/sportsbook-data-hierarchy';

export const getLivePreMatchSportTypes = (
  sportsTypes: string | undefined
): number[] => {
  let sportTypes: number[] = [];

  try {
    sportTypes = !sportsTypes ? ALL_SPORTS_TYPES : JSON.parse(sportsTypes);

    let all = false;
    sportTypes.forEach((type: number | string) => {
      if (type === 'all') {
        all = true;
      } else {
        type = Number(type);
      }
    });

    if (all) {
      sportTypes = ALL_SPORTS_TYPES;
    }
  } catch (e) {
    sportTypes = ALL_SPORTS_TYPES;
  }

  return sportTypes;
};

export enum SportType {
  ESPORT = 'esport'
}
