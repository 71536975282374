import React, { HTMLAttributes, ReactElement } from 'react';
import { EContainerIconSize } from 'utils/constants/app/ui';
import { Container } from './style';

export interface IEventActionProps extends HTMLAttributes<HTMLDivElement> {
  icon: any;
  onClick?: any;
  ghost?: boolean;
  active?: boolean;
  className?: string;
  size?: 'sm' | 'md';
  iconContainerSize?:
    | EContainerIconSize.SMALL
    | EContainerIconSize.MIDDLE
    | EContainerIconSize.LARGE
    | 0;
  margin?: 'right' | 'left' | null;
  dataTestid?: string;
}

export const EventAction = React.forwardRef<HTMLDivElement, IEventActionProps>(
  ({ icon, dataTestid, ...rest }, ref): ReactElement => {
    return (
      <Container
        {...rest}
        data-testid={dataTestid}
        className={rest.className}
        ref={ref}
      >
        {icon}
      </Container>
    );
  }
);
