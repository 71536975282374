import i18n from 'i18next';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { CommandNames } from 'utils/constants/swarm/swarm-command-names';

export const CUSTOM_CATEGORIES = {
  favorite: {
    id: 'favorite',
    name: 'favorite',
    games_count: '',
    get title() {
      return SpringConfigs.USER_PERSONALIZATION_ENABLED
        ? i18n.t('pages.myCasino')
        : i18n.t('casino.favorite');
    }
  },
  all: {
    id: 'all',
    name: 'all',
    games_count: '',
    get title() {
      return i18n.t('casino.all-games');
    }
  },
  lastPlayed: {
    id: 'last-played',
    name: 'last-played',
    games_count: '',
    get title() {
      return i18n.t('casino.last-played');
    }
  },
  suggested: {
    id: 'suggested',
    name: 'suggested',
    games_count: '',
    get title() {
      return i18n.t('casino.suggested');
    }
  },
  special: {
    id: 'special',
    name: 'special',
    games_count: '',
    get title() {
      return i18n.t('casino.special');
    }
  },
  specialLive: {
    id: 'specialLive',
    name: 'specialLive',
    games_count: '',
    get title() {
      return i18n.t('casino.specialLive');
    }
  }
};

export const CUSTOM_CATEGORIES_ARRAY = Object.values(CUSTOM_CATEGORIES);

export const CUSTOM_CATEGORY_IDS = Object.values(CUSTOM_CATEGORIES).map(
  category => category.id
);

export const CUSTOM_CATEGORY_IDS_WITHOUT_PROVIDERS = [
  CUSTOM_CATEGORIES.favorite.id,
  CUSTOM_CATEGORIES.lastPlayed.id,
  CUSTOM_CATEGORIES.suggested.id,
  CUSTOM_CATEGORIES.special.id,
  CUSTOM_CATEGORIES.specialLive.id
];

export const CUSTOM_CATEGORY_COMMAND_NAMES = {
  [CUSTOM_CATEGORIES.lastPlayed.id]:
    CommandNames.GET_RECENT_PLAYED_CASINO_GAMES,
  [CUSTOM_CATEGORIES.suggested.id]: CommandNames.GET_CASINO_SUGGESTED_GAMES
};

export const A_TO_Z_MENU_CASINO_TYPES = ['casino', 'live-casino'];
