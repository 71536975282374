import { createSelector } from 'reselect';
import { getSelectorRoot } from 'utils/selectors';
import {
  TPartnerLoyaltyGroup,
  TPartnerLoyaltyGroupItem
} from 'elements/Accounts/views/LoyaltyPointsNew/types';

const getUserData = getSelectorRoot('userData');

export const getIsLoggedIn = getUserData('isLoggedIn');
export const getRegIsFinished = getUserData('regIsFinished');
export const getUser = getUserData('user');
export const getLoginLimit = getUserData('loginLimit');
export const getBonuses = getUserData('bonuses');
export const getPendingSuperBets = getUserData('pendingSuperBets');
export const getFreeBonuses = getUserData('bonuses', 'freeBonuses');
export const getCasinoBonuses = getUserData('bonuses', 'casinoBonuses');
export const getFreeSpins = getUserData('bonuses', 'freeSpinBonuses');
export const getUnreadMessagesCount = getUserData('unreadMessagesCount');
export const getFastRegData = getUserData('fastRegData');
export const getisWithdrawalAllowed = getUserData('isWithdrawalAllowed');
export const getCasinoBonusDetails = getUserData('casinoBonusDetails');
export const getShowAutoidentVerificationModal = getUserData(
  'showAutoidentVerificationModal'
);
export const getSumSubModalOpen = getUserData('sumSubModalOpen');

export const getShowCancelRegisterPopup = getUserData(
  'showCancelRegisterPopup'
);

export const getUserOptIns = getUserData('userOptIns');
export const getShouldGetUserOptIns = getUserData('shouldGetUserOptIns');
export const getSendAsPromoCode = getUserData('sendAsPromoCode');
export const getHasFromError = getUserData('hasFromError');
export const getGpsTrackingParams = getUserData('gpsTrackingParams');
export const getUserDepositLimits = getUserData('userDepositLimits');
export const getRememberMe = getUserData('rememberMe');
export const getWithdrawableBalance = getUserData('withdrawableBalance');
export const getClientInfo = getUserData('clientInfo');
export const getPaymentDepositPromo = getUserData('paymentDepositPromo');
export const getStakeBalance = getUserData('stakeBalance');
export const getDisplayCryptoInFiat = getUserData('displayCryptoInFiat');
export const getSelectedCurrencyForDisplay = getUserData(
  'selectedCurrencyForDisplay'
);
export const getCashbackNotifications = getUserData('cashbackNotifications');
export const getPersonalisedDataLoaded = getUserData('personalisedDataLoaded');
export const getLoyaltyNotifications = getUserData('loyaltyNotifications');

export const getPartnerLoyaltyGroups = getUserData('partnerLoyaltyGroups');
export const getPartnerLoyaltyBenefits = getUserData('partnerLoyaltyBenefits');
export const getUserLoyaltyInfo = getUserData('userLoyaltyInfo');
export const getUserLoyaltyBenefits = getUserData('userLoyaltyBenefits');
export const getActiveClaimableBenefit = getUserData('activeClaimableBenefit');
export const getClientGamingStateInfo = getUserData('clientGamingStateInfo');

export const getUserCurrentLoyaltyData = createSelector(
  [getUser, getPartnerLoyaltyGroups],
  (userData, partnerLoyaltyGroups) => {
    const result: {
      userLoyaltyPoints?: number;
      userLoyaltyLevelId?: number;
      userCurrentGroupLevel?: TPartnerLoyaltyGroupItem;
      userCurrentGroup?: TPartnerLoyaltyGroup;
      nextGroupItem?: TPartnerLoyaltyGroupItem;
    } = { userLoyaltyPoints: 0 };

    if (
      (userData?.loyalty_level_id || userData?.loyalty_level_id === null) &&
      partnerLoyaltyGroups
    ) {
      if (!partnerLoyaltyGroups.length) {
        return result;
      }

      result.userLoyaltyPoints = userData.loyalty_point;
      result.userLoyaltyLevelId =
        userData.loyalty_level_id ?? partnerLoyaltyGroups[0]?.items[0]?.id;

      const currentLoyaltyGroupData = partnerLoyaltyGroups.reduce(
        (
          acc: {
            userCurrentGroup?: TPartnerLoyaltyGroup;
            userCurrentGroupLevel?: TPartnerLoyaltyGroupItem;
            nextGroupItem?: TPartnerLoyaltyGroupItem;
          },
          group
        ) => {
          const userCurrentGroupLevel = group.items.find(
            item => item.id === result.userLoyaltyLevelId
          );

          if (userCurrentGroupLevel) {
            const nextGroupItem = group.items.find(
              item => item.order === userCurrentGroupLevel.order + 1
            );

            return {
              userCurrentGroup: group,
              userCurrentGroupLevel,
              nextGroupItem
            };
          }

          if (!acc.nextGroupItem && acc.userCurrentGroupLevel?.order) {
            const nextGroupItem = group.items.find(
              item => item.order === (acc.userCurrentGroupLevel?.order || 0) + 1
            );

            if (nextGroupItem) {
              return {
                ...acc,
                nextGroupItem
              };
            }

            return acc;
          }

          return acc;
        },
        {}
      );

      if (Object.keys(currentLoyaltyGroupData).length) {
        return {
          ...result,
          ...currentLoyaltyGroupData
        };
      }

      return result;
    }

    return result;
  }
);
