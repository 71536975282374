import { Suspense, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sortByOrder } from 'utils/collection-manipulation/array-sort-by';
import { EIconSize } from 'utils/constants/app/ui';
import {
  competitions,
  regions,
  sports,
  topLeaguesWhere
} from 'utils/constants/sportsbook/data-fields';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { lazy } from 'utils/generic/lazy';
import { getMountPath, mountPoint } from 'utils/generic/route-manipulation';
import { isMobile } from 'utils/is-mobile';
import { addRegionNameToCompetition } from 'utils/sportsbook/add-region-name-to-competition';
import RidGenerator from 'utils/swarm/rid-generator';
import { ENotRealSports } from 'interfaces/not-real-sports';
import {
  EOlympicLayoutType,
  ETopLeagueLayoutType
} from 'interfaces/sportsbook-configs';
import { Sport } from 'interfaces/sportsbook-data-levels';
import { executeCommand, unsubscribeCommand } from 'services/get-data-level';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { EventCollapse } from 'components/EventCollapse';
import { GlobalIcon } from 'components/GlobalIcon';
import { UrlWrapper } from 'components/Shared/UrlWrapper';
import { RegionSkeleton } from 'components/UI/Skeletons/RegionSkeleton';
import { CompetitionsFavorite } from 'newcomponents/Shared/sportsbook/CompetitionsFavorite';
import { TSportDataWithCompetitions } from 'newcomponents/Shared/sportsbook/TopLeagueCompactLayout';
import { TopLeagueCompactLayoutSkeleton } from 'newcomponents/Shared/sportsbook/TopLeagueCompactLayout/skeleton';
import { useSbRouteMatch } from 'newhooks/generic/useSbRouteMatch';
import { useSbSportsbookRouteMatch } from 'newhooks/sportsbook/useSbSportsbookRouteMatch';
import { getBoostedOddsIds } from 'store/selectors/sport-data';
import './index.less';

const { TopLeagueCompactLayout } = lazy(
  () =>
    import(
      /* webpackChunkName: "top-league-competitions-view" */ 'newcomponents/Shared/sportsbook/TopLeagueCompactLayout'
    )
);

type TSportsListCategoriesViewProps = {
  categoryAlias:
    | ENotRealSports.topLeague
    | ENotRealSports.boostedOdds
    | ENotRealSports.olympicCompetition;
  fromSportsList: boolean;
  openFromSideBarPopup: boolean;
  competitionClickHandler: (url: string, sportAlias?: string) => void;
};

export const SportsListCategoriesView = ({
  categoryAlias,
  fromSportsList,
  openFromSideBarPopup,
  competitionClickHandler
}: TSportsListCategoriesViewProps) => {
  const generatedSbPath = useSbSportsbookRouteMatch(
    '/:sport?/:region?/:competition?/:game?'
  );

  const { path, params } = useSbRouteMatch(generatedSbPath);
  const history = useHistory();

  const boostedOddsIds = useSelector(getBoostedOddsIds);

  const [sportData, setSportData] = useState<Record<string, Sport> | null>(
    null
  );

  const compactLayout =
    (SpringConfigs.TOP_LEAGUES_LAYOUT === ETopLeagueLayoutType.compact &&
      categoryAlias === ENotRealSports.topLeague &&
      SpringConfigs.TOP_LEAGUES) ||
    (SpringConfigs.OLYMPIC_COMPETITION_LAYOUT === EOlympicLayoutType.compact &&
      categoryAlias === ENotRealSports.olympicCompetition &&
      SpringConfigs.OLYMPIC_COMPETITION);

  useLayoutEffect(() => {
    if (
      categoryAlias === ENotRealSports.boostedOdds &&
      !boostedOddsIds?.length
    ) {
      return;
    }

    const rid = RidGenerator.gForSubscribe(
      `SPORTS_LIST_${categoryAlias.toUpperCase()}`
    );

    let where: Record<string, any> = {};
    let what: Record<string, any> = {};

    if (categoryAlias === ENotRealSports.boostedOdds) {
      where = {
        game: {
          type: {
            '@in': [0, 2]
          },
          id: {
            '@in': boostedOddsIds
          }
        }
      };
      what = {
        ...sports,
        ...regions,
        ...competitions
      };
    }

    if (categoryAlias === ENotRealSports.topLeague) {
      where = topLeaguesWhere;
      what = {
        ...sports,
        ...regions,
        competition: [...competitions.competition, 'favorite_order']
      };
    }

    if (categoryAlias === ENotRealSports.olympicCompetition) {
      what = {
        ...sports,
        ...regions,
        competition: [...competitions.competition]
      };
      where = { competition: { info: { is_olympic: true } } };
    }

    const callback = (data: { sport: Record<string, Sport> }) => {
      if (data?.sport) {
        const updatedData = addRegionNameToCompetition(data, false);
        setSportData(updatedData);
      }
    };

    executeCommand(rid, what, where, callback, true, callback);

    return () => {
      unsubscribeCommand(rid);
    };
  }, [categoryAlias, boostedOddsIds?.length]);

  const onCompClick = ({
    sportAlias,
    regionAlias,
    compId
  }: {
    sportAlias: string;
    regionAlias: string;
    compId: number;
  }) => {
    if (
      params.competition === `${compId}` &&
      params.category === categoryAlias
    ) {
      return;
    }

    window.scrollTo(0, 0);

    if (openFromSideBarPopup) {
      const pathname = `${mountPoint(
        window.getPathname()
      )}/${categoryAlias}/${sportAlias}/${regionAlias}/${compId}`;

      if (!document.querySelector('.prematch__type-page')) {
        competitionClickHandler(
          `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}/${categoryAlias}/${sportAlias}/${regionAlias}/${compId}`,
          sportAlias
        );
      } else {
        history.push(pathname);
      }
    } else {
      const redirectPath = `${getMountPath(
        path
      )}/${categoryAlias}/${sportAlias}/${regionAlias}/${compId}`;

      competitionClickHandler(
        `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}${redirectPath}`,
        sportAlias
      );
    }
  };

  if (compactLayout) {
    if (!sportData) {
      return <TopLeagueCompactLayoutSkeleton />;
    }

    return (
      <ConditionalWrapper
        condition={isMobile()}
        wrapper={children => <div style={{ padding: 0 }}>{children}</div>}
      >
        <Suspense fallback={<TopLeagueCompactLayoutSkeleton />}>
          <TopLeagueCompactLayout
            sportData={sportData as TSportDataWithCompetitions}
            fromSportsList={fromSportsList}
            openFromSideBarPopup={openFromSideBarPopup}
          />
        </Suspense>
      </ConditionalWrapper>
    );
  }

  if (!sportData) {
    return <RegionSkeleton />;
  }

  return sortByOrder(Object.values(sportData)).map((sport, index) => (
    <EventCollapse
      style={{ margin: '0 4px 4px 4px' }}
      key={sport.id}
      icon={
        <GlobalIcon
          lib="sports"
          name={sport.alias}
          className="text-color"
          size={EIconSize._24}
        />
      }
      title={sport.name}
      defaultActive={
        !Number(SpringConfigs?.SPORT_COMPETITION_IS_COMPACT) && index === 0
      }
    >
      <div className="sportsListCategoryComps">
        {sport.competition &&
          sortByOrder(Object.values(sport.competition)).map(comp => (
            <UrlWrapper
              url={`${getMountPath(path)}/${
                SpringConfigs.SPORTSBOOK_MOUNT_PATH
              }/${categoryAlias}${sport.alias}/${comp.region}/${comp.id}`}
              key={comp.id}
              onClick={() =>
                onCompClick({
                  sportAlias: sport.alias,
                  regionAlias: comp.region || '',
                  compId: comp.id
                })
              }
              className="sportsListCategoryComps__compRowWrapper"
            >
              <div className="sportsListCategoryComps__competitionNameWrapper">
                <CompetitionsFavorite
                  iconSize={EIconSize._16}
                  competitionInfo={{
                    id: comp.id,
                    name: comp.name,
                    regionAlias: comp.region || '',
                    sportAlias: sport.alias
                  }}
                />
                {comp.name}
              </div>
              <GlobalIcon lib="flags" name={comp.region} size={EIconSize._18} />
            </UrlWrapper>
          ))}
      </div>
    </EventCollapse>
  ));
};
