import { JSX } from 'react';
import './index.less';

type TSportCategoriesUIProps = {
  menuItems: JSX.Element[] | null;
};

export const SportCategoriesUI = ({ menuItems }: TSportCategoriesUIProps) => {
  return menuItems ? (
    <div className="sportCategoriesWrapper">
      <div className="sportCategoriesInner">{menuItems}</div>
    </div>
  ) : null;
};
