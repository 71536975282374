import { ConstantsIcons } from 'utils/constants/icons';

export const prefetch = async (url: string) => {
  try {
    const res = await fetch(url);
    const svgString = await res.text();
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
    const svgElement = svgDoc.querySelector('svg');

    if (svgElement) {
      ConstantsIcons.ALREADY_LOADED_ICON_URLS.set(url, svgElement);
    } else {
      throw new Error('Invalid SVG');
    }
  } catch {
    console.error('Error prefetching icon: ', url);
  }
};
