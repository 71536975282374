import SpringConfigs from 'utils/constants/swarm/spring-configs';
import store from '../../store';

export const formatPrice = (
  price: number | string,
  separator = SpringConfigs.PRICE_SEPARATOR,
  hideTrailingZeros = SpringConfigs.HIDE_TRAILING_ZEROES,
  _rounding?: number
): string => {
  const partnerConfigs = store.getState().socket.partnerConfigs;
  const rounding = _rounding ?? (+partnerConfigs?.price_decimals || undefined);

  const roundingMode =
    partnerConfigs?.price_round_method !== 0 ? 'halfExpand' : 'floor';

  const formattedPrice = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: rounding,
    roundingMode
  } as Intl.NumberFormatOptions)
    .format(+price)
    .replace(/,/g, separator);

  const fractionalPart = Number(price) - parseInt(`${price}`);

  if (hideTrailingZeros && fractionalPart === 0) {
    return formattedPrice.split('.')[0];
  }

  return Number(formattedPrice) && rounding
    ? Number(formattedPrice).toFixed(rounding)
    : formattedPrice;
};

export const formatPriceNotRounded = (price?: number, rounding?: number) => {
  return price
    ? rounding
      ? (+`${Math.floor(+`${price}e${rounding}`)}e${-rounding}`).toFixed(
          rounding
        )
      : price
    : 0;
};
