import { deepMerge } from 'utils/collection-manipulation/deep-merge';
import {
  CommandNames,
  CommandSource
} from 'utils/constants/swarm/swarm-command-names';
import { WebsocketEnhanced } from 'interfaces/spring-websocket-interfaces';
import { connectingWS } from 'hooks/useSpringConnect';

export const worksSubIds: Array<string> = [];

export const executeCommand = async (
  rid: string,
  relations: Object,
  where: Object,
  setCallback: Function,
  subscribe = false,
  updateCallback: Function = () => undefined,
  errorCallBack?: Function
): Promise<void> => {
  let defaultData: any = null;
  const command = {
    command: CommandNames.GET,
    params: {
      source: CommandSource.BETTING,
      what: { ...relations },
      where: {
        ...where
      },
      subscribe: subscribe
    },
    rid: rid
  };

  const ws: WebsocketEnhanced = (await connectingWS) as WebsocketEnhanced;

  ws.sendCommand(
    command,
    rid,
    (data: any) => {
      defaultData = data;
      setCallback(data);
    },
    (data: any) => {
      const updatedData = deepMerge(defaultData, data, {
        shouldMergeInFirst: false,
        applyEmptyArraysAndObjects: false
      });

      defaultData = updatedData;
      updateCallback(updatedData);
    },
    errorCallBack ?? null
  );
};

export const unsubscribeCommand = (rid: string): void => {
  connectingWS.then((socket: WebsocketEnhanced) => socket.unsubscribe(rid));
};

export const unsubscribeAllCommand = (rids: Array<string>): void => {
  connectingWS.then((socket: WebsocketEnhanced) => {
    socket.unsubscribeAll(rids);
    worksSubIds.splice(0, worksSubIds.length);
  });
};
