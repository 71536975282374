import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { ICON_SIZE } from 'utils/constants/app/ui';
import type { Competition } from 'interfaces/sportsbook-data-levels';
import { GlobalIcon } from 'components/GlobalIcon';
import { getFavoriteMatchesCountByCompetition } from 'store/selectors/fav-data';
import './index.less';

type TCompetitionFavoriteMatchesCountIndicatorProps = {
  competitionId: Competition['id'];
};

export const CompetitionFavoriteMatchesCountIndicator: FC<TCompetitionFavoriteMatchesCountIndicatorProps> =
  memo(({ competitionId }) => {
    const favoriteMatchesCount = useSelector(
      getFavoriteMatchesCountByCompetition(competitionId)
    );

    if (!favoriteMatchesCount) {
      return null;
    }

    return (
      <div className="competitionFavoriteMatchesCountIndicator">
        +{favoriteMatchesCount}
        <GlobalIcon
          lib="generic"
          name="favorite"
          theme="default"
          size={ICON_SIZE.DESKTOP_12}
          className="competitionFavoriteMatchesCountIndicator__icon"
        />
      </div>
    );
  });
