import React, { forwardRef, ReactElement, useState } from 'react';
import cc from 'classcat';
import { EIconSize } from 'utils/constants/app/ui';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { ADD_COMPETITIONS } from 'utils/constants/tour';
import { EventAction } from 'components/EventAction';
import { GlobalIcon } from 'components/GlobalIcon';
import './index.less';

type PanelProps = {
  title: string | React.ReactNode;
  key: number | string;
  icon?: React.ReactNode;
  active?: boolean;
  defaultActive?: boolean;
  children?: React.ReactNode;
  wrapperClassName?: string;
  headerClassName?: string;
  wrapperBackColor?: string;
  onChange?: (active: boolean) => void;
  renderInDom?: boolean;
  dataTestId?: string;
  extraLeft?: React.ReactNode;
  extraRight?: React.ReactNode;
};

export const NavigationCollapse = forwardRef<HTMLDivElement, PanelProps>(
  (props: PanelProps, forwardedRef): ReactElement => {
    const [_active, setActive] = useState<boolean>();

    const active = _active ?? props.defaultActive;

    return (
      <div
        ref={forwardedRef}
        className={cc([
          props.wrapperClassName,
          'navigationCollapse__wrapper',
          { 'navigationCollapse__wrapper--active': active }
        ])}
        data-testid={props.dataTestId}
        {...(SpringConfigs.USER_PERSONALIZATION_ENABLED && active
          ? { 'data-tour': ADD_COMPETITIONS }
          : {})}
      >
        <div
          onClick={() => {
            if (props.children) {
              setActive(!active);
              props.onChange?.(!active);
            }
          }}
          className={cc([props.headerClassName, 'navigationCollapse__header'])}
        >
          {!!props.extraLeft && (
            <div className="nav__extraLeft">{props.extraLeft}</div>
          )}
          {!!props.icon && <div className="nav__icon">{props.icon}</div>}
          <div
            className="navigationCollapse__title"
            title={typeof props.title === 'string' ? props.title : ''}
          >
            {props.title}
          </div>
          {!!props.extraRight && (
            <div className="nav__extraRight">{props.extraRight}</div>
          )}
          <EventAction
            size="sm"
            ghost
            icon={
              <GlobalIcon
                lib="generic"
                name="caretDownSmall"
                theme="default"
                size={EIconSize._24}
                className={cc([
                  'navigationCollapse__caret',
                  { 'navigationCollapse__caret-expanded': active }
                ])}
              />
            }
          />
        </div>
        {props.renderInDom ? (
          <div
            className={cc([
              'navigationCollapse__body',
              { 'navigationCollapse__body--open': active }
            ])}
          >
            {props.children}
          </div>
        ) : (
          active && props.children
        )}
      </div>
    );
  }
);
