import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { generateSbPathWithParams } from 'utils/generic/route-manipulation';

export const useSbSportsbookRouteMatch = (mainRoute: string): string => {
  const location = useLocation();

  const category = location.pathname
    .split(`/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}/`)[1]
    ?.split('/')?.[0];

  return useMemo(() => generateSbPathWithParams(mainRoute), [category]);
};
