import { sortByOrder } from 'utils/collection-manipulation/array-sort-by';
import {
  Competition,
  CompetitionWithRegionName,
  Game,
  Region,
  Sport,
  TCoupon
} from 'interfaces/sportsbook-data-levels';

export const addRegionNameToCompetition = (
  data: { sport: Record<string, Sport> } | null,
  keepRegion: boolean,
  prevData?: Record<string, any>,
  routeParams?: Record<string, string>,
  setCompetition?: Function
): Record<string, Sport> | null => {
  if (!data) {
    return null;
  }

  const updatedData = { ...data };

  if (prevData && !keepRegion) {
    if (updatedData.sport) {
      Object.values(prevData).forEach((sport: Sport) => {
        if (!updatedData.sport[sport.id]) {
          delete prevData?.[sport.id];
        }
      });
      Object.values(updatedData.sport).forEach((sport: Sport) => {
        if (updatedData.sport[sport.id] && sport && !prevData?.[sport.id]) {
          const newData = addSportData(data);
          prevData = { ...prevData, ...newData };

          return;
        }

        if (sport && sport.region && prevData) {
          const regionId = Object.keys(sport.region)[0];

          if (!sport.region[regionId]) {
            prevData = removeOrAddRegion(
              prevData,
              +regionId,
              null,
              routeParams,
              setCompetition
            );
          } else {
            prevData = removeOrAddRegion(prevData, +regionId, updatedData);
          }

          sortByOrder(Object.values(sport.region)).forEach((region: Region) => {
            if (region?.competition) {
              const competitionId = +Object.keys(region?.competition)[0];

              if (
                competitionId &&
                !region?.competition[competitionId] &&
                prevData
              ) {
                prevData = removeCompetition(
                  prevData,
                  competitionId,
                  routeParams,
                  setCompetition
                );
              }
            }
          });
          delete sport.region;
        }
      });
    }

    return prevData;
  } else if (updatedData.sport) {
    return addSportData(updatedData, keepRegion);
  }

  return prevData ? prevData : updatedData.sport;
};

const removeOrAddRegion = (
  prevData: Record<string, any>,
  id: number,
  data?: { sport: Record<string, Sport> } | null,
  routeParams?: Record<string, string>,
  setCompetition?: Function
) => {
  Object.values(prevData).forEach((item: Region) => {
    if (item.competition) {
      Object.values(item.competition).forEach((competition: Competition) => {
        //add new Region
        if (data) {
          if (data.sport) {
            const sportId = Object.keys(data.sport)[0];

            if (item.id === +sportId) {
              data.sport &&
                Object.values(data.sport).forEach((sport: Sport) => {
                  const competitions: Record<
                    string,
                    CompetitionWithRegionName
                  > = {};

                  sport.region &&
                    sortByOrder(Object.values(sport.region)).forEach(
                      (region: Region) => {
                        region?.competition &&
                          Object.values(region.competition).forEach(
                            (competition: Competition) => {
                              let regionAlias = region.alias;

                              if (sport.region) {
                                const regionId = +Object.keys(sport.region)[0];

                                if (!region.alias && regionId) {
                                  const competitionData: {
                                    region: number;
                                    regionId: number;
                                  }[] = Object.values(
                                    prevData[sportId].competition
                                  );

                                  const regionData: Competition | null =
                                    competitionData.find(
                                      data =>
                                        data.regionId === regionId &&
                                        data.region
                                    ) as unknown as Competition;

                                  regionAlias =
                                    regionData && regionData.region
                                      ? regionData.region
                                      : '';
                                }

                                if (competition && competition.id) {
                                  competitions[competition.id] = {
                                    ...competition,
                                    region: regionAlias,
                                    regionId: region.id ? region.id : regionId
                                  };
                                }
                              }
                            }
                          );
                      }
                    );
                  item.competition = { ...item.competition, ...competitions };
                });
            }
          }
        } else if (
          competition.regionId === +id &&
          item.competition?.[competition.id]
        ) {
          if (routeParams && +routeParams.competition === competition.id) {
            setCompetition?.();
          }

          delete item.competition[competition.id];
        }
      });
    }
  });

  return prevData;
};

const removeCompetition = (
  prevData: Record<string, any>,
  id: number,
  routeParams?: Record<string, string>,
  setCompetition?: Function
) => {
  Object.values(prevData).forEach((item: Region) => {
    if (item.competition) {
      Object.values(item.competition).forEach((competition: Competition) => {
        if (competition && item.competition?.[id]) {
          delete item.competition[id];

          if (routeParams && +routeParams.competition === id) {
            setCompetition?.();
          }
        }
      });
    }
  });

  return prevData;
};

const addSportData = (
  data: { sport: Record<string, Sport> },
  keepRegion?: boolean
) => {
  data.sport &&
    Object.values(data.sport).forEach((sport: Sport) => {
      const competitions: Record<string, CompetitionWithRegionName> = {};

      sport.region &&
        sortByOrder(Object.values(sport.region)).forEach((region: Region) => {
          region?.competition &&
            Object.values(region.competition).forEach(
              (competition: Competition) => {
                if (!competition?.id) {
                  return;
                }

                competitions[competition.id] = {
                  ...competition,
                  region: region.alias,
                  regionId: region.id
                };
              }
            );
        });

      if (Object.values(competitions).length) {
        sport.competition = { ...competitions };
      }

      !keepRegion && delete sport.region;
    });

  return data.sport;
};

export const createCouponCompetitions = ({
  coupons,
  sports,
  params,
  callback
}: {
  coupons: Array<TCoupon>;
  sports: Record<string, Sport>;
  params: {
    game?: string;
    competition?: string;
  };
  callback: (coupons: TCoupon[], coupon: TCoupon) => void;
}): void => {
  let selected: TCoupon | null = null;

  coupons.forEach((coupon: TCoupon, index: number) => {
    coupon.Index = index;

    if (!coupon.CompetitionIds) {
      coupon.CompetitionIds = [];
    }

    Object.values(sports || {}).forEach((sport: Sport) => {
      Object.values(sport.region || {}).forEach((region: Region) => {
        Object.values(region.competition || {}).forEach(
          (competition: Competition) => {
            sortByOrder(
              Object.values(competition?.game || {}),
              'start_ts'
            ).forEach((game: Game) => {
              if (coupon.Matches.includes(game.id)) {
                coupon.CompetitionIds?.push(competition.id);
              }

              if (!coupon.firstGame && coupon.Matches.includes(game.id)) {
                coupon.firstGame = {
                  id: Number(competition.id),
                  sport: sport.alias,
                  region: region.alias,
                  competition: competition.id,
                  game: game.id
                };
              }

              if (!selected) {
                if (params?.game) {
                  if (
                    coupon.Matches?.includes(Number(params?.game)) &&
                    coupon.CompetitionIds?.includes(Number(params?.competition))
                  ) {
                    selected = coupon;
                  }
                } else {
                  if (coupon.Matches?.includes(Number(game.id))) {
                    selected = coupon;
                  }
                }
              }
            });
          }
        );
      });
    });
  });

  callback(coupons, selected || coupons[0]);
};
