import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { BoostedOddDetails } from 'interfaces/sportsbook-data-levels';
import { getBoostedGames } from 'services/sportsbook/boosted-odds';
import { setBoostedOdds, setBoostedOddsCalled } from 'store/actions';
import { EBoostType } from 'store/reducers/sport-data';
import { getBoostedOddsCalled } from 'store/selectors/sport-data';

export const useBoostedOdds = (shouldFetch = true): void => {
  const dispatch = useDispatch();
  const boostedOddsCalled = useSelector(getBoostedOddsCalled);

  useEffect(() => {
    if (
      SpringConfigs.IS_BOOSTED_ODDS_ENABLED &&
      shouldFetch &&
      !boostedOddsCalled
    ) {
      dispatch(setBoostedOddsCalled());
      getBoostedGames(storeBoostedOdds);
    }
  }, [boostedOddsCalled, shouldFetch]);

  const storeBoostedOdds = (boostedOddsData: {
    details: Record<string, Array<BoostedOddDetails>>;
  }) => {
    const boostedOddsWithEventIds: Record<
      string,
      Record<string, { type: EBoostType }>
    > = {};

    boostedOddsData.details &&
      Object.keys(boostedOddsData.details).forEach(gameId => {
        boostedOddsWithEventIds[gameId] = boostedOddsData.details[
          gameId
        ].reduce(
          (acc, { Id, BoostType: type }) => ({ ...acc, [Id]: { type } }),
          {}
        );
      });
    dispatch(setBoostedOdds(boostedOddsWithEventIds));
  };
};
