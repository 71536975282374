import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFavoriteSportIds } from 'store/selectors/fav-data';

export const useSortFavoriteSportsOrdering = <T>(
  sports?: T[],
  key?: keyof T
): T[] | typeof sports => {
  const favoriteSportIds = useSelector(getFavoriteSportIds);

  return useMemo<T[] | typeof sports>(
    () => sortFavoriteSports(favoriteSportIds, sports, key),
    [sports, favoriteSportIds, key]
  );
};

export const sortFavoriteSports = <T>(
  favoriteSportIds: number[],
  sports?: T[],
  key?: keyof T
): T[] | typeof sports => {
  if (!sports || !key) {
    return sports;
  }

  const sportsClone = [...sports];
  //for items that shouldn't be reordered. ex. es ports All E-Sports
  const ignoredSports = [];

  if (sportsClone.length && Number(sportsClone[0][key]) < 1) {
    ignoredSports.push(sportsClone[0]);
    sportsClone.splice(0, 1);
  }

  const favoriteSports = favoriteSportIds.reduce<T[]>((result, id) => {
    const index = sportsClone.findIndex(s => +s[key] === +id);

    if (index !== -1) {
      const [favSport] = sportsClone.splice(index, 1);
      result.push(favSport);
    }

    return result;
  }, []);

  return [...ignoredSports, ...favoriteSports, ...sportsClone];
};
