import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { CommandNames } from 'utils/constants/swarm/swarm-command-names';
import GLOBALS from 'utils/generic/global-variables';
import { ESportsbookLayoutTypes } from 'interfaces/sportsbook-configs';
import { Sport, TUserSportOrder } from 'interfaces/sportsbook-data-levels';
import { swarmCommand } from 'services/get-swarm-data';
import { sortFavoriteSports } from 'hooks/sportsbook/useSortFavoriteSportsOrdering';
import { useSportsbookConfigs } from 'hooks/sportsbook/useSportsbookConfigs';
import { setUserSportOrder } from 'store/actions/new-sportsbook';
import { getFavoriteSportIds } from 'store/selectors/fav-data';
import { getUserSportOrder } from 'store/selectors/new-sportsbook';
import { getIsLoggedIn, getUser } from 'store/selectors/user-data';

type TGetUserSportOrder = {
  orderSports: (sportList: Sport[] | null) => Sport[] | null;
  waitingToGetOrder: boolean;
  orderIsLoading: boolean;
  needToLoadOrderedData: boolean;
  firstSportId: string | number | null;
};

export const useGetUserSportOrder = (): TGetUserSportOrder => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getUser);
  const userSportOrder = useSelector(getUserSportOrder);

  const favoriteSportIds = useSelector(getFavoriteSportIds);

  const dispatch = useDispatch();
  const configs = useSportsbookConfigs();

  const [orderIsLoading, setOrderIsLoading] = useState(
    !SpringConfigs.MOCKED_DATA
  );

  const [firstSportId, setFirstSportId] = useState<string | number | null>(
    null
  );

  const waitingToGetOrder =
    SpringConfigs.PERSONALIZED_SPORTS &&
    configs.spbLayoutType != ESportsbookLayoutTypes.AMERICAN &&
    !isLoggedIn &&
    !!user.pending;

  const needToLoadOrderedData = () => {
    if (
      SpringConfigs.PERSONALIZED_SPORTS &&
      configs.spbLayoutType != ESportsbookLayoutTypes.AMERICAN
    ) {
      if (!isLoggedIn && user.pending) {
        return true;
      }

      return isLoggedIn;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      SpringConfigs.PERSONALIZED_SPORTS &&
      configs.spbLayoutType != ESportsbookLayoutTypes.AMERICAN
    ) {
      if (
        !userSportOrder &&
        isLoggedIn &&
        !GLOBALS.oneTimeCommands.getUserSportOrder
      ) {
        GLOBALS.oneTimeCommands.getUserSportOrder = true;
        getSportOrdering();
      }

      if (!isLoggedIn && userSportOrder) {
        GLOBALS.oneTimeCommands.getUserSportOrder = false;
        dispatch(setUserSportOrder(null));
      }
    }
  }, [userSportOrder, isLoggedIn]);

  const getSportOrdering = () => {
    swarmCommand(
      CommandNames.GET_USER_SPORT_ORDER,
      {},
      (data: { result: number; details: TUserSportOrder[] }) => {
        if (data) {
          const sports: Record<string, TUserSportOrder> = {};

          data.details.forEach((item, index) => {
            sports[index] = item;
          });

          dispatch(setUserSportOrder(sports));
        }
      }
    );
  };

  const orderSports = useCallback(
    (sportList: Sport[] | null) => {
      let sortedSport: Sport[] | null = [];

      if (sportList && userSportOrder && Object.keys(userSportOrder).length) {
        const list = new Map();
        Object.keys(userSportOrder).forEach(key => {
          let sport;
          Object.keys(sportList).forEach(listKey => {
            if (sportList[Number(listKey)].id === userSportOrder[key].SportId) {
              sport = sportList[Number(listKey)];
            }
          });

          sport && list.set(userSportOrder[key].SportId, sport);
        });

        Object.keys(sportList).forEach(listKey => {
          list.set(sportList[Number(listKey)].id, sportList[Number(listKey)]);
        });

        sortedSport = Array.from(list, item => item[1]);
        sortedSport = sortedSport.map((value, index) => {
          const valueCopy = { ...value };
          valueCopy.order = index + 1;

          return valueCopy;
        });
        setFirstSportId(sortedSport[0] ? sortedSport[0].id : null);
        setOrderIsLoading(false);
      } else {
        if (
          !needToLoadOrderedData() ||
          (userSportOrder && !Object.keys(userSportOrder || {}).length)
        ) {
          setFirstSportId(sportList?.[0] ? sportList?.[0].id : null);
          setOrderIsLoading(false);
        }

        sortedSport = sportList;
      }

      return (
        sortFavoriteSports(
          favoriteSportIds,
          Object.values(sortedSport || []),
          'id'
        ) || null
      );
    },
    [isLoggedIn, user.pending, userSportOrder, favoriteSportIds]
  );

  return useMemo(
    () => ({
      orderSports,
      waitingToGetOrder,
      orderIsLoading,
      needToLoadOrderedData: needToLoadOrderedData(),
      firstSportId
    }),
    [
      orderSports,
      waitingToGetOrder,
      orderIsLoading,
      isLoggedIn,
      user.pending,
      firstSportId
    ]
  );
};
