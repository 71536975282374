import { ReactElement } from 'react';
import cc from 'classcat';
import { EIconSize } from 'utils/constants/app/ui';
import { RegionViewSkeleton } from './RegionViewSkeleton';
import './index.less';

export const RegionSkeleton = ({
  expanded = true,
  extraClassName = '',
  regionIconSize
}: {
  expanded?: boolean;
  extraClassName?: string;
  regionIconSize?: EIconSize;
}): ReactElement => {
  return (
    <div
      className={cc([
        'sportsbook__region__skeleton',
        { [extraClassName]: !!extraClassName }
      ])}
    >
      {Array.from({ length: 18 }, (v, i) => i).map(key => (
        <RegionViewSkeleton
          key={key}
          expanded={expanded && key === 0}
          regionIconSize={regionIconSize}
        />
      ))}
    </div>
  );
};
