import { ReactElement, ReactNode } from 'react';

export const UrlWrapper = ({
  children,
  url,
  dataTestId,
  className,
  onClick
}: {
  children: ReactNode;
  url: string;
  dataTestId?: string;
  className?: string;
  onClick?: () => void;
}): ReactElement => {
  return (
    <a
      href={url}
      onClickCapture={e => {
        e.preventDefault();
      }}
      data-testid={dataTestId}
      className={className}
      onClick={onClick}
    >
      {children}
    </a>
  );
};
