import {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sliceLength } from 'utils/constants/app/magic-numbers-app';
import { EIconSize } from 'utils/constants/app/ui';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { getMountPath } from 'utils/generic/route-manipulation';
import { isMobile } from 'utils/is-mobile';
import { Competition } from 'interfaces/sportsbook-data-levels';
import { GlobalIcon } from 'components/GlobalIcon';
import { CompetitionsFavorite } from 'newcomponents/Shared/sportsbook/CompetitionsFavorite';
import { useGameScrollIntoView } from 'hooks/useGameScrollIntoView';
import { useSbRouteMatch } from 'newhooks/generic/useSbRouteMatch';
import { getScrollToCompetitionId } from 'store/selectors/sport-data';
import { CompetitionNameWithTooltip } from '../CompetitionNameWithTooltip';
import './index.less';

type Props = {
  competitions: Competition[];
  clickHandler: (e: SyntheticEvent, arg: number) => void;
  shouldShowAll: boolean;
  regionAlias: string;
  sportAlias: string;
};
export const COMPETITIONS_MIN_LENGTH = 5;

export const CompetitionsList = ({
  competitions,
  clickHandler,
  shouldShowAll,
  regionAlias,
  sportAlias
}: Props): ReactElement => {
  const { path } = useSbRouteMatch();
  const { t } = useTranslation();
  const handleScroll = useGameScrollIntoView();
  const [showAllButtonVisible, setShowAllButtonVisible] = useState(
    !shouldShowAll && competitions.length > COMPETITIONS_MIN_LENGTH
  );

  const scrollToCompetitionId = useSelector(getScrollToCompetitionId);
  const scrollToRef = useRef<HTMLDivElement | null>(null);

  const competitionsSliced = useMemo(() => {
    if (showAllButtonVisible && competitions.length > COMPETITIONS_MIN_LENGTH) {
      return competitions.slice(0, sliceLength.SLICE_END_5);
    } else {
      return competitions;
    }
  }, [competitions, showAllButtonVisible]);

  const shouldScrollIntoView = useMemo(() => {
    return (
      isMobile() &&
      scrollToCompetitionId &&
      !!competitions.find(comp => comp.id === Number(scrollToCompetitionId?.id))
    );
  }, [scrollToCompetitionId, competitions]);

  useEffect(() => {
    shouldScrollIntoView &&
      scrollToRef.current &&
      handleScroll(scrollToRef.current, undefined, scrollToCompetitionId?.top);
  }, [shouldScrollIntoView]);

  return (
    <>
      {competitionsSliced.map(comp => (
        <CompetitionNameWithTooltip
          competitionUrl={`${getMountPath(path)}/${
            SpringConfigs.SPORTSBOOK_MOUNT_PATH
          }/${sportAlias}/${regionAlias}/${comp.id}`}
          key={comp.id}
          competitionItem={comp as Competition}
          clickHandler={e => {
            clickHandler(e, comp.id);
          }}
          {...(isMobile() &&
            scrollToCompetitionId?.id === comp.id.toString() && {
              ref: scrollToRef
            })}
          IconBeforeText={
            <CompetitionsFavorite
              competitionInfo={{
                id: comp.id,
                name: comp.name,
                regionAlias,
                sportAlias
              }}
            />
          }
        />
      ))}
      {showAllButtonVisible && (
        <div
          onClick={() => setShowAllButtonVisible(false)}
          className="competitionsList__showAll"
        >
          {t('casino.showAll')}
          <GlobalIcon
            lib="generic"
            name="caretDown"
            theme="default"
            size={EIconSize._12}
          />
        </div>
      )}
    </>
  );
};
