import i18n from 'i18next';
import { sliceLength } from 'utils/constants/app/magic-numbers-app';
import { REPLACE_EDIT_BET_TRANSLATIONS } from 'utils/constants/betslip/bet-types';
import { ERROR_CODE_TIMEOUT_1800 } from 'utils/constants/dateInfo/time-numbers';
import {
  backendErrorCodes,
  backendErrorDetailsKeys,
  errorCodesToShowBackendError
} from 'utils/constants/swarm/backend-error-codes';
import {
  BackendStatusCodes,
  GeolocationCheckFailedDetails,
  WrongLoginAttemptsDetails,
  WrongLoginAttemptsPerPeriodDetails,
  WrongLoginAttemptsPerPeriodDetails2
} from 'utils/constants/swarm/backend-status-codes';
import { fireEvent } from 'utils/generic/event-bus';
import { showToastError } from 'utils/generic/show-toast-error';
import { TSwarmDataError } from 'utils/swarm/swarm-msg-checker';
import { EGeolocationFailedPopup } from 'components/Generic/__partials/GeolocationFailedPopup/types';
import { EWrongLoginAttempts } from 'components/Generic/__partials/WrongLoginAttemptsUnlockPopup/types';
import store from 'store';
import { formatPrice } from '../string-manipulation/format-price';

type TSwarmGenericResponse = {
  details?: Record<string, any> | string;
  status?: number;
  result?: any;
  result_text?: string;
};

type Data = TSwarmGenericResponse | string | TSwarmDataError;

export const showSwarmError = (
  data: Data,
  msg: string,
  context: HTMLElement | undefined | null
): void => {
  if (!data) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(msg);
    }

    return;
  }

  let errorCode =
    data &&
    typeof data === 'object' &&
    ('status' in data || 'result' in data) &&
    (data.status || data.result);

  if (data && typeof data === 'object') {
    if ('status' in data || 'result' in data) {
      errorCode = data.status || data.result;
    } else if ('HasError' in data) {
      errorCode = data.StatusCode;

      if (REPLACE_EDIT_BET_TRANSLATIONS.includes(errorCode)) {
        return;
      }
    }
  }

  if (errorCode && shouldShowErrorMessage(data)) {
    let errorMessage = '';

    if (errorCode in backendErrorCodes) {
      errorMessage = i18n.t(`${backendErrorCodes[errorCode]}`);
    } else if (
      errorCodesToShowBackendError.includes(errorCode) &&
      data &&
      typeof data === 'object'
    ) {
      if ('result_text' in data) {
        errorMessage = data.result_text || '';
      } else if ('StatusCode' in data) {
        errorMessage = data.StatusCode || '';
      }
    }

    const messageContent = customizeMessage(errorMessage, data);
    messageContent && showToastError(`${messageContent}`, context);
  } else if (
    typeof data !== 'string' &&
    'details' in data &&
    shouldShowPopupOnError(data)
  ) {
    if (checkIsWrongLoginAttempts(data)) {
      fireEvent({
        key: EWrongLoginAttempts.ShowPopup
      });

      return;
    }

    if (checkIsGeolocationFailed(data)) {
      fireEvent({
        key: EGeolocationFailedPopup.ShowPopup
      });

      return;
    }
  }
};

const stakeArr = ['min_bet', 'max_bet'];

export const customizeMessage = (
  messageContent: string,
  messageData: Data
): string => {
  const variablesRegEx = /{\w+}/gi;
  const messageVariables = [...messageContent.matchAll(variablesRegEx)];

  if (messageVariables.length) {
    const currencyVariableIndexes = messageVariables.reduce((a, e, i) => {
      if (e[0] === '{currency}') {
        a.push(i);
      }

      return a;
    }, [] as number[]);

    if (currencyVariableIndexes.length) {
      const storeState = store.getState();
      const currency =
        storeState.userData?.user?.currency ||
        storeState.socket?.partnerConfig?.currency ||
        '';

      messageContent = messageContent.replaceAll('{currency}', currency);
      currencyVariableIndexes.forEach(index => {
        messageVariables.splice(index, 1);
      });
    }

    if (
      messageVariables.length &&
      messageData &&
      typeof messageData === 'object'
    ) {
      messageVariables.forEach(variable => {
        if ('details' in messageData) {
          const detailsVars = variable[0].slice(
            1,
            sliceLength.SLICE_END_MINUS_1
          );

          messageContent = messageContent.replace(
            variable[0],
            stakeArr.includes(detailsVars)
              ? formatPrice(
                  (messageData.details as Record<string, any>)[detailsVars]
                )
              : (messageData.details as Record<string, any>)[detailsVars]
          );
        } else if ('Data' in messageData && 'ErrorData' in messageData.Data) {
          messageContent = messageContent.replace(
            variable[0],

            messageData.Data.ErrorData[
              backendErrorDetailsKeys[
                variable[0].slice(1, sliceLength.SLICE_END_MINUS_1)
              ]
            ]
          );
        }
      });
    }
  }

  return messageContent;
};

const shouldShowErrorMessage = (data: Data): boolean => {
  let isShow = true;

  if (
    typeof data !== 'string' &&
    'details' in data &&
    (data.details === 'WrongClientToken' ||
      data.result === ERROR_CODE_TIMEOUT_1800 ||
      data.result === BackendStatusCodes.betslip.useSuperBet ||
      shouldShowPopupOnError(data))
  ) {
    isShow = false;
  }

  return isShow;
};

const checkIsWrongLoginAttempts = (data: TSwarmGenericResponse): boolean => {
  return (
    !!data.details &&
    typeof data.details === 'object' &&
    ((data.status === WrongLoginAttemptsDetails.code &&
      data.details?.Key === WrongLoginAttemptsDetails.key) ||
      (data.details?.Key === WrongLoginAttemptsPerPeriodDetails.key &&
        data.status === WrongLoginAttemptsPerPeriodDetails.code) ||
      (data.details?.Key === WrongLoginAttemptsPerPeriodDetails2.key &&
        data.status === WrongLoginAttemptsPerPeriodDetails2.code))
  );
};

const checkIsGeolocationFailed = (data: TSwarmGenericResponse): boolean => {
  return (
    !!data.details &&
    typeof data.details === 'object' &&
    data.details?.Key === GeolocationCheckFailedDetails.key &&
    data.status === GeolocationCheckFailedDetails.code
  );
};

const shouldShowPopupOnError = (data: TSwarmGenericResponse): boolean => {
  return checkIsWrongLoginAttempts(data) || checkIsGeolocationFailed(data);
};
